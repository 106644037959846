import React, { Component } from 'react';
import cookie from 'cookie';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import configStore from 'common/store';
import { postExercise } from 'common/apis';
import { View, Image } from '@zhike/ti-ui';
import { history, AppRouter } from 'routers';
import styles from 'components/initial/styles';
import { redirectToLoginPage } from 'utils/action';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { detectMultiTabs } from 'ti-frontend-common'
import ErrorBoundry from './error';

/* eslint-disable */
Sentry.init({
  dsn: 'https://15a5907558d44bfc9610dd413698970c@sentry.smartstudy.com/65',
  environment: process.env.NODE_ENV,
});

// 默认设置axios发起请求时带上cookie
// axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  response => response,
  error => {
    Sentry.captureException(error, {
      extra: {
        axios: error && error.toJSON(),
      },
      tags: {
        requestUrl: _.get(error, 'config.url'),
        code: 'RequestError',
      },
    })
    throw error
  },
)

// 入口页
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      store: undefined,
    };
  }

  async componentWillMount() {
    const storeKey = await this.getStoreKey();
    const store = await configStore(storeKey);
    this.detectIsMultipleTab();

    Sentry.setContext('store', _.mapValues(store.getState(), x => JSON.stringify(x)))

    this.setState({
      store,
      isLoaded: true,
    });
  }

  async getStoreKey() {
    const pathname = global.location.pathname; // eslint-disable-line
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let match = pathname.match(/\/init\/.*?\/\d+\/(\d+)/);
    match = match || pathname.match(/\/report\/.*?\/\d+\/(\d+)/);
    match = match || pathname.match(/\/.*?\/\d+\/(\d+)/);
    // 获取用户token
    // 优先采用url的token
    let token;
    if (tokenUrl) {
      token = tokenUrl;
      document.cookie = `ss_user=${encodeURIComponent(JSON.stringify({ token }))};domain=.smartstudy.com;path=/;`
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        Sentry.setUser(user)
        token = user.token; // eslint-disable-line
      } catch (e) {
        // console.log(e);
        redirectToLoginPage();
        return;
      }
    }
    // 新建exercise
    if (!match && pathname.indexOf('/init/') !== -1) {
      try {
        const params = pathname.match(/\/init\/(.*?)\/(\d+)/);
        const mode = params[1];
        const id = parseInt(params[2], 10);
        const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
        const taskId = decodeURIComponent(search.match(new RegExp('[?#&]task_id=([^?#&]+)', 'i')) ? RegExp.$1 : '');
        const subjectIds = decodeURIComponent(search.match(new RegExp('[?#&]subjectIds=([^?#&]+)', 'i')) ? RegExp.$1 : '');
        const subjectList = (subjectIds ? subjectIds.split(',') : []).map(item => +item);

        let data;
        if (mode === 'practice') {
          data = {
            practiceId: id,
            type: 'Practice',
            source,
            taskId: taskId || null,
            token,
          };
        } else if (mode === 'mock') {
          data = {
            practicePackageId: id,
            type: 'PracticePackage',
            source,
            taskId: taskId || null,
            token,
            isMock: true,
            subjectIds: subjectList,
          };
        } else {
          data = {
            questionId: id,
            type: 'Question',
            source,
            taskId: taskId || null,
            token,
          };
        }
        const exercise = await axios({
          url: postExercise[1],
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            From: 1,
          },
          data,
          timeout: 20000,
        });
        if (exercise.data.code === 'BadGateway') redirectToLoginPage();
        history.push(`/init/${mode}/${id}/${exercise.data.data.id}/new${search}`);

        return `exercise${exercise.data.data.id}`;
      } catch (e) {
        console.error(e)
        history.push('/error/loading');
        return 'common';
      }
    }

    return match ? `exercise${match[1]}` : 'common';
  }

  detectIsMultipleTab() {
    const pathname = global.location.pathname;  // eslint-disable-line
    if (
      pathname.indexOf('/report/') !== -1 ||
      pathname.indexOf('/error/') !== -1 ||
      pathname.indexOf('/correct/') !== -1
    ) {
      return
    }
    let match = pathname.match(/\/init\/.*?\/\d+\/(\d+)/)
    match = match || pathname.match(/\/.*?\/\d+\/(\d+)/)

    if (match) {
      const exerciseId = match[1]
      const key = `ti:toefl:exercise${exerciseId}`
      const callback = () => {
        global.location.href = '/error/multiple'
      }
      detectMultiTabs(key, { callback })
    }
  }

  render() {
    const { isLoaded, store } = this.state;
    return (
      isLoaded ? (
        <Provider store={store}>
          <AppRouter />
        </Provider>
      ) : (
        <View className={styles.container}>
          <Image
            className={styles.loading}
            src={require('components/assets/loading.gif')}
          />
          {
            <View className={styles.text}>正在加载，请稍候...</View>
          }
        </View>
      )
    );
  }
}

// 渲染
ReactDOM.render(
  <ErrorBoundry>
    <App />
  </ErrorBoundry>,
  global.document.getElementById('app'),
);
