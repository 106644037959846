import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { View, Scrollbar } from '@zhike/ti-ui';
import { SearchWords } from '@zhike/ti-component';
import RenderIndex from 'components/report/common/render_index';
import Question from 'components/question';
import AnswerParsing from 'components/report/common/answer_parsing';
import VideoParsing from 'components/report/common/video_parsing';
import AnswerTable from 'components/report/common/answer_table';
import ReportArticle from 'components/report/common/article';
import Notes from 'components/report/common/notes';
import { handleIeltsAnswer } from 'utils';
import styles from './styles';

// 阅读题目
export default class ListeningReport extends Component {
  // 参数
  static propTypes = {
    practice: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userGroup: PropTypes.number,
  };

  static defaultProps = {
    userGroup: 1,
  }

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isReport: true,
    };
  }

  // 渲染
  render() {
    const { practice, match, userGroup, userGroupName } = this.props;
    const { isReport } = this.state;
    const { exerciseId } = match.params;
    const questions = get(practice, 'questions');
    const practiceId = get(practice, 'id');
    const conciseId = get(practice, 'conciseId');
    const subjectId = get(practice, 'subjectId');
    // 生成组件信息
    return (
      <View className={styles.container}>
        <View className={styles.left}>
          <View className={styles.result}>
            <RenderIndex
              questions={questions}
              match={match}
            />
          </View>
          <View className={styles.content}>
            <Scrollbar
              width="510px"
              height="100%"
              onScrollStart={() => { SearchWords.hide(); }}
            >
              {
                questions.map((question, index) => {
                const { materials, extra, type, id, subjectId } = question;
                const { answerAnalysis } = extra;
                const { videoTimeRangeId } = get(question, 'materials.0');
                const qNum = [];
                const answerArr = handleIeltsAnswer(materials, type, true);
                materials.map(material => {
                  qNum.push(material.qNum);
                  return false;
                });
                const renderProps = {
                  isReport,
                  materials,
                  extra,
                  type,
                  id,
                  subjectId,
                  params: match.params,
                };
                return (
                  <View
                    className={styles.question}
                    key={index}
                  >
                    <Question question={question} {...renderProps} />
                    <AnswerTable
                      exerciseId={exerciseId}
                      qNum={qNum}
                      answerArr={answerArr}
                      isChooseMany={type === 'ChooseMany'}
                    />
                    {
                      exerciseId > 0 &&
                      <View style={{ marginTop: '40px' }}>
                        <Notes question={question} />
                      </View>
                    }
                    {
                      answerAnalysis &&
                      answerAnalysis.paragraphs &&
                      answerAnalysis.paragraphs.length !== 0 &&
                      <AnswerParsing
                        answerAnalysis={answerAnalysis}
                        questionId={id}
                      />
                    }
                    {
                      conciseId && conciseId !== -1 && practiceId &&
                      <VideoParsing
                        practiceId={practiceId}
                        startTip={videoTimeRangeId}
                        conciseId={conciseId}
                        userGroup={userGroup}
                        userGroupName={userGroupName}
                      />
                    }
                  </View>
                  );
                })
              }
            </Scrollbar>
          </View>
        </View>
        <View className={styles.right}>
          {/* 音频材料和译文 */}
          <ReportArticle
            subjectId={subjectId}
            match={match}
            questions={questions}
          />
        </View>
      </View>
    );
  }
}
