import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: 1100,
    margin: '0 auto',
    paddingTop: 100,
    paddingBottom: 40,
    color: '#32363a',
  },

  strong: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },

  p: {
    display: 'block',
    marginTop: 14,
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
  },
});
