import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from 'routers';
import { get } from 'lodash';
import { View, Image } from '@zhike/ti-ui';
import Header from 'components/header';
import styles from './styles';

// 结束
export default class End extends Component {
  // 参数
  static propTypes = {
    params: PropTypes.object.isRequired,
    submit: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
  };

  // 模块即将加载
  componentWillMount() {
    const { submit, step } = this.props;
    const { exerciseId, mode } = this.props.params;
    const search = global.location.search; // eslint-disable-line
    Header.config({});
    if (mode !== 'mock') {
      const questions = get(step, 'practice.questions');
      const questionMaterialId = get(questions[0], 'materials.0.id');
      setTimeout(async () => {
        try {
          await submit();
        } catch (e) {
          setTimeout(() => {
            history.push(`/error/upload/${encodeURIComponent(global.location.pathname)}`);
          }, 100);
          return;
        }
        setTimeout(() => {
          history.push(`/report/${mode}/${exerciseId}/${questionMaterialId}${search}`);
        }, 3000);
      }, 100);
    }

    if (mode === 'mock') {
      setTimeout(async () => {
        try {
          await submit();
        } catch (e) {
          setTimeout(() => {
            history.push(`/error/upload/${encodeURIComponent(global.location.pathname)}`);
          }, 100);
          return;
        }
        setTimeout(() => {
          history.push(`/report/${mode}/${exerciseId}${search}`);
        }, 300);
      }, 100);
    }
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        <Image
          className={styles.loading}
          src={require('./assets/save.png')}
        />

        <View className={styles.text}>正在上传答案，请稍候...</View>
      </View>
    );
  }
}
