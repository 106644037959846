import { cloneDeep, get, filter } from 'lodash';
import { SUBJECT } from 'common/constants';
import { getSubjectName } from './step';

// 添加附加信息
export const addExtraInfo = stepList => {
  let newStepList = cloneDeep(stepList);
  newStepList = newStepList.map((step, index) => {
    const obj = {
      id: index + 1,
    };

    // 给模考中的听力练习添加步骤id
    if (step.type === 'ListeningMock') {
      const mockPractices = cloneDeep(step.mockPractices);
      // eslint-disable-next-line
      step.mockPractices = mockPractices.map(ele => ({ ...ele, id: obj.id }));
    }

    // 给模考中的阅读练习添加步骤id
    if (step.type === 'ReadingMock') {
      const mockPractices = cloneDeep(step.mockPractices);
      // eslint-disable-next-line
      step.mockPractices = mockPractices.map(ele => ({ ...ele, id: obj.id }));
    }

    // 给模考中的写作练习添加步骤id
    if (step.type === 'WritingMock') {
      const mockPractices = cloneDeep(step.mockPractices);
      // eslint-disable-next-line
      step.mockPractices = mockPractices.map(ele => ({ ...ele, id: obj.id }));
    }

    // 给模考中的口语练习添加步骤id
    if (step.type === 'SpeakingMock') {
      const mockPractices = cloneDeep(step.mockPractices);
      // eslint-disable-next-line
      step.mockPractices = mockPractices.map(ele => ({ ...ele, id: obj.id }));
    }

    return Object.assign({}, step, obj);
  });

  return newStepList;
};

const handleMockPractices = (practices, subjectPractices) => {
  let writingQNum = 1;
  let speakingQNum = 1;
  return subjectPractices.map(practiceId => {
    const practice = filter(practices, { id: practiceId })[0];
    const step = {};
    const questionMaterialIds = [];
    practice.questions.forEach(question => {
      const { materials, subjectId } = question;
      const userAnswers = [];
      let answerIndex = 0;
      step.subjectId = subjectId;
      materials.forEach(material => {
        const { qNum, id, answer, userAnswer } = material;
        const answerIndexs = [];
        Array.isArray(answer) && answer.forEach(() => {
          answerIndexs.push(answerIndex);
          answerIndex += 1;
        });
        let tempNum = qNum;
        if (question.type === 'Writing') {
          tempNum = writingQNum;
          writingQNum += 1;
        }
        if (question.type === 'Speaking') {
          tempNum = speakingQNum;
          speakingQNum += 1;
        }
        questionMaterialIds.push({
          qNum: tempNum,
          questionMaterialId: id,
          answerIndexs,
          questionId: question.id,
        });
        // 取用户的每个小题的答案
        if (userAnswer) {
          const { answer, questionMaterialId } = userAnswer;
          userAnswers.push({
            answer,
            questionMaterialId,
          });
        }
        step.questionMaterialIds = questionMaterialIds;
        step.type = `${getSubjectName(practice.subjectId)}Question`;
        step.practiceId = practiceId;
      });
    });
    return step;
  });
};

// 获取练习的做题步骤
export const getStepListForMock = mock => {
  const { subjects, practices } = mock;
  let stepList = [];
  for (let i = 0; i < subjects.length; i += 1) {
    const subjectPractices = subjects[i].practices;
    if (subjects[i].subjectId === SUBJECT.READING) {
      stepList.push({
        subject: 'Reading',
        subjectId: 6,
        type: 'ReadingDirections',
        mode: 'mock',
      });
      stepList = stepList.concat({
        subject: 'Reading',
        subjectId: 6,
        type: 'ReadingMock',
        mockPractices: handleMockPractices(practices, subjectPractices),
        mode: 'mock',
      });
      stepList.push({
        subject: 'Reading',
        subjectId: 6,
        type: 'ReadingEnding',
        mode: 'mock',
      });
    } else if (subjects[i].subjectId === SUBJECT.LISTENING) {
      stepList.push({
        subject: 'Listening',
        subjectId: 5,
        type: 'ListeningTest',
        mode: 'mock',
      });
      stepList.push({
        subject: 'Listening',
        subjectId: 5,
        type: 'ListeningDirections',
        mode: 'mock',
      });
      stepList = stepList.concat({
        subject: 'Listening',
        subjectId: 5,
        type: 'ListeningMock',
        mockPractices: handleMockPractices(practices, subjectPractices),
        mode: 'mock',
      });
      stepList.push({
        subject: 'Listening',
        subjectId: 5,
        type: 'ListeningEnding',
        mode: 'mock',
      });
    } else if (subjects[i].subjectId === SUBJECT.SPEAKING) {
      stepList.push({
        subject: 'Speaking',
        subjectId: 8,
        type: 'SpeakingTest',
        mode: 'mock',
      });
      stepList.push({
        subject: 'Speaking',
        subjectId: 8,
        type: 'SpeakingDirections',
        mode: 'mock',
      });
      stepList = stepList.concat({
        subject: 'Speaking',
        subjectId: 8,
        type: 'SpeakingMock',
        mockPractices: handleMockPractices(practices, subjectPractices),
        mode: 'mock',
      });
    } else if (subjects[i].subjectId === SUBJECT.WRITING) {
      stepList.push({
        subject: 'Writing',
        subjectId: 7,
        type: 'WritingDirections',
      });
      stepList = stepList.concat({
        subject: 'Writing',
        subjectId: 7,
        type: 'WritingMock',
        mode: 'mock',
        mockPractices: handleMockPractices(practices, subjectPractices),
      });
      stepList.push({
        subject: 'Writing',
        subjectId: 7,
        type: 'WritingEnding',
        mode: 'mock',
      });
    }
  }

  stepList.push({
    subject: '',
    type: 'GeneralEnding',
  });

  stepList.push({
    type: 'End',
  });
  return addExtraInfo(stepList);
};

// 获取听力材料
export const getAudioMaterial = step => {
  const audioMaterials = [];
  function getAudio(item) {
    const instructionalAudio = get(item, 'extra.instructionalAudio.src');
    const instructionalAudioName = get(item, 'extra.instructionalAudio.name');
    if (instructionalAudio) {
      audioMaterials.push({
        src: instructionalAudio,
        name: instructionalAudioName,
      });
    }
    const audioMaterial = get(item, 'extra.audioMaterial.src');
    const audioMaterialName = get(item, 'extra.audioMaterial.name');
    const isRepeatSrc = findIndex(audioMaterials, { name: audioMaterialName || '' });
    if (audioMaterial && isRepeatSrc === -1) {
      audioMaterials.push({
        src: audioMaterial,
        name: audioMaterialName,
      });
    }
  }
  step.practice && step.practice.questions && step.practice.questions.map(item => {
    getAudio(item);
    return false;
  });

  const newAudioMaterials = audioMaterials.map(item => {
    const newSrc = item.src.indexOf('//') === -1 ? `${cdnUrl}/${item.src}` : item.src;
    return newSrc;
  });
  return newAudioMaterials;
};
