import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { View } from '@zhike/ti-ui';
import styles from './styles';

moment.locale('zh-cn');

// 报告
export default class Header extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
    subjectId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { subjectId } = this.props;
    global.document.title = `${subjectId === '3' ? '口语批改' : '写作批改'} - 智课`;
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  generateTop = () => {
    const { machinePigaiSubmitTime } = this.props.correctData;
    const search = global.location.search; // eslint-disable-line
    const title = decodeURIComponent(search.match(new RegExp('[?#&]title=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let { machinePigaiScore } = this.props.correctData;
    machinePigaiScore = this.handleScore(machinePigaiScore);

    let style = {
      backgroundImage: `url(${require('./assets/grade_c.png')})`,
      color: '#DCDCDC',
      textShadow: '0 2px 4px #505050',
    };
    if (machinePigaiScore >= 21) {
      style = {
        backgroundImage: `url(${require('./assets/grade_a.png')})`,
        color: '#F5D5A8',
        textShadow: '0 2px 4px #9A7136',
      };
    }
    if (machinePigaiScore <= 20 && machinePigaiScore >= 11) {
      style = {
        backgroundImage: `url(${require('./assets/grade_b.png')})`,
        color: '#E3EDEC',
        textShadow: '0 2px 4px #4F6788',
      };
    }
    return (
      <View className={styles.top}>
        <View className={styles.title}>{title}</View>
        <View className={styles.time}>{moment(machinePigaiSubmitTime).format('YYYY.MM.DD')}</View>
        <View className={styles.score} style={style}>{machinePigaiScore} </View>
        <View className={styles.footer}>
          说明：本页面分数由智课AI机器人提供，仅供参考；你可选择外教批改服务，评分更准确哦~
        </View>
      </View>
    );
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateTop()}
      </View>
    );
  }
}
