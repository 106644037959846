import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Image } from '@zhike/ti-ui';
import { SearchWords } from '@zhike/ti-component';
import { get, filter, flatMap, keyBy, includes } from 'lodash';
import { formatReportDuration } from 'utils';
import { getStepListForMock } from 'utils/reportStep';
import { createPromise } from 'utils/action';
import { getSearchWord } from 'common/apis';
import MockGeneral from 'components/report/mock';
import HeaderContainer from 'components/report/common/header';
import ListeningReport from 'components/report/listening_report';
import ReadingReport from 'components/report/reading_report';
import SpeakingReport from 'components/report/speaking_report';
import WritingReport from 'components/report/writing_report';
import styles from './styles';

// 报告
export default class Report extends Component {
  // 参数
  static propTypes = {
    getReport: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    getUploadSignature: PropTypes.func.isRequired,
    postCorrectionImage: PropTypes.func.isRequired,
    postCorrection: PropTypes.func.isRequired,
    postMachinePigai: PropTypes.func.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isCompatible: true,
      isError: true,
      report: {},
    };
  }

  // 加载
  async componentWillMount() {
    // 检测兼容性
    if (
      global.navigator.userAgent.match(/msie/i) ||
      global.ActiveXObject ||
      ('ActiveXObject') in global
    ) {
      this.setState({
        isCompatible: false,
      });
    }
    const { getReport, match } = this.props;
    const { exerciseId } = match.params;
    const { mode } = match.params;
    // mode 为 question
    let report;
    if (exerciseId < 0) { // exerciseId如果小于0代表没有记录，去掉负号是对应的practiceId和questionId
      const id = Number(exerciseId.substring(1));
      report = await createPromise(getReport,
        { id, mode });
    } else {
      report = await createPromise(getReport,
        { exerciseId, mode: mode === 'mock' ? 'mock' : 'exercise', requireTag: 1 });
      // newReport = mode === 'mock' ? get(report, 'data') : get(report, 'data.questionBackup');
    }
    this.setState({
      isError: false,
      report,
    });
  }

  // 渲染
  render() {
    const { isCompatible, isError, report } = this.state;
    const { match, postCorrectionImage, getUploadSignature,
      postCorrection, postMachinePigai } = this.props;
    const { mode, exerciseId, questionMaterialId: questionId } = match.params;
    let practice = exerciseId > 0 ? get(report, 'data.questionBackup') : get(report, 'data');
    const question = exerciseId > 0 ? get(report, 'data.questionBackup.questions.0') : get(report, 'data.questions.0');
    const duration = formatReportDuration(get(report, 'data.duration'));
    const userGroup = get(report, 'data.userGroup');
    const userGroupName = get(report, 'data.userGroupName');
    const reportStepList = report && report.data ? getStepListForMock(mode, report.data) : {};
    let step = {};
    if (mode === 'question') {
      step.question = question;
    } else if (mode === 'mock') {
      if (questionId) {
        // 实际上是 materialId
        step = reportStepList.length > 0 &&
          filter(reportStepList, x => includes(x.materialIds, parseInt(questionId, 10)))[0];
      }
    } else {
      step.practice = practice || {};
      step.question = get(practice, 'questions.0', {});
    }
    if (step && step.practiceId && mode === 'mock') {
      const { practices } = report.data;
      const questions = flatMap(practices, p => p.questions);
      const questionsById = keyBy(questions, 'id');
      step.practice = practices.length > 0 && filter(practices, { id: step.practiceId })[0];
      step.question = questionsById[step.questionId];

      practice = step.practice;
    }
    const renderProps = {
      userGroup,
      userGroupName,
      match,
      step,
      practice,
      postCorrectionImage,
      getUploadSignature,
      postCorrection,
      duration,
      postMachinePigai,
      reportStepList,
      report: report.data,
      title: mode === 'question' ? question && question.name : practice && practice.name,
      // 5 为听力
      // 6 为阅读
      showButtons: practice && (practice.subjectId === 5
        || practice.subjectId === 6 || practice.subjectId === 8) ? ['correct'] : ['correct', 'foreign', 'machine'],
      subjectId: practice && practice.subjectId,
    };
    // 生成组件信息
    let RenderComponent;
    switch (practice && practice.subjectId) {
      case 5:
        RenderComponent = ListeningReport;
        break;
      case 6:
        RenderComponent = ReadingReport;
        break;
      case 7:
        RenderComponent = WritingReport;
        break;
      case 8:
        RenderComponent = SpeakingReport;
        break;
      default:
        break;
    }
    if (mode === 'mock' && !questionId) {
      RenderComponent = MockGeneral;
    }
    return (
      <View className={styles.container} >
        {
          report &&
          !isCompatible &&
          <View>
            <View className={styles.text}>暂不支持当前浏览器...</View>
            <View className={styles.text}>
              <a
                style={{ color: '#fff' }}
                href="http://www.google.cn/chrome/browser/desktop/index.html"
                target="_blank" // eslint-disable-line
              >
                请下载最新版本Chrome浏览器
              </a>
            </View>
          </View>
        }
        {
          report &&
          isError && !isCompatible &&
          <View className={styles.loadingBox}>
            <Image
              className={styles.loading}
              src={require('components/assets/loading.gif')}
            />
            <View className={styles.text}>正在加载数据，请稍候...</View>
          </View>
        }
        {
          report &&
          !isError &&
          (mode === 'practice' || mode === 'question' || (mode === 'mock' && questionId)) &&
          RenderComponent &&
          <HeaderContainer {...renderProps} />
        }
        {
          report &&
          !isError &&
          (mode === 'practice' || mode === 'question') &&
          JSON.stringify(renderProps.report) !== '{}' &&
          <RenderComponent {...renderProps} />
        }
        {
          report &&
          !isError && (mode === 'mock') &&
          JSON.stringify(renderProps.report) !== '{}' &&
          <RenderComponent {...renderProps} />
        }
        <SearchWords
          ref={searchWords => { SearchWords.instance = searchWords; }}
          getSearchWord={getSearchWord[1]}
        />
      </View>
    );
  }
}

