import { getLocalStorage, setLocalStorage } from 'utils';

export function getStorageAudioRecord(exerciseId) {
  const storageAudioData = getLocalStorage('audio_record') || {};
  return +storageAudioData[exerciseId] || 0;
}
export function setStorageAudioRecord(exerciseId, value) {
  const storageAudioData = getLocalStorage('audio_record') || {};
  storageAudioData[exerciseId] = value;
  setLocalStorage('audio_record', storageAudioData);
  return true;
}
