import React, { Component } from 'react';
import { View, Image, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import PropTypes from 'prop-types';
import { AudioPlayer } from '@zhike/ti-component';
import styles from './styles';

// 阅读指令
export default class ReadingDirections extends Component {
  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
  };
  // 模块即将加载
  componentWillMount() {
    Header.config({
      title: 'Listening',
      showButtons: ['volume', 'continue'],
    });
    Header.cleanTimer();
  }

  componentDidMount() {
    if (this.props.step.textbookId === 193) return false;
    // 播放音频
    AudioPlayer.play({
      /* eslint-disable */
      src: require('!file-loader!./assets/listening_section_directions.mp3'),
      /* eslint-enable */
      onEnd: () => Header.next(),
    });
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
  }

  // 渲染
  render() {
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={styles.title}>Listening Section Directions</View>
        <View className={styles.p}>This test measures your ability to understand conversations and lectures in English.</View>
        <View className={styles.p}>The Listening section is divided into 2 separately timed parts. In each part you will listen to 1 conversation and {this.props.step.textbookId === 193 ? '1 or 2' : '2'} lectures. You will hear each conversation or lecture only <View className={styles.strong}>one</View> time.</View>
        <View className={styles.p}>After each conversation or lecture, you will answer some questions about it. The questions typically as about the main idea and supporting details. Some questions ask about a speaker's purpose or attitude. Answer the questions based on what is stated or implied by the speakers.</View>
        <View className={styles.p}>You may take notes while you listen. You may use your notes to help you answer the questions. Your notes will not be scored.</View>
        <View className={styles.p}>If you need to change the <View className={styles.strong}>Volume</View> while you listen, click on the Volume icon at the top of the screen.</View>
        <View className={styles.p}>
          <View className={styles.earphoneText}>
            In some questions, you will see this icon:
          </View>
          <Image className={styles.earphoneImage} src={require('./assets/earphone.png')} />
          <View className={styles.earphoneText}>
            This means that you will hear, but not see. part of the question.
          </View>
        </View>
        <View className={styles.p}>Some of the questions have special directions. These directions appear in a gray box on the screen.</View>
        <View className={styles.p}>Most questions are worth 1 point. If a question is worth more than 1 point, it will have special directions that indicate how many points you can receive.</View>
        <View className={styles.p}>You must answer each question. After you answer, click on <View className={styles.strong}>Next</View>. Then click on <View className={styles.strong}>OK</View> to confirm your answer and go on to the next question. After you click on <View className={styles.strong}>OK</View>. you cannot return to previous questions.</View>
        <View className={styles.p}>Click on <View className={styles.strong}>Continue</View> to go on.</View>
        {/* eslint-enable */}
      </Scrollbar>
    );
  }
}
