import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    marginTop: '40px',
    width: '100%',
  },

  title: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  analysisIcon: {
    cursor: 'pointer',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 4,
    height: 18,
    background: 'rgba(51,153,255,1)',
    borderRadius: '100px',
    marginRight: 5,
  },
  screen: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  answerAnalysis: {
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'PingFangSC-Semibold',
    color: 'rgba(50,54,58,1)',
  },
  fullScreen: {
    color: 'rgba(51,153,255,1)',
    fontSize: 14,
    lineHeight: '20px',
    fontFamily: 'PingFangSC-Regular',
  },
  analysisContent: {
    background: 'rgba(246,248,249,1)',
    padding: '20px',
    maxHeight: 200,
    boxSizing: 'border-box',
    overflowY: 'hidden',
  },
  analysisText: {
    width: '470px',
    maxHeight: 'inherit',
    boxSizing: 'border-box',
  },
  image: {
    width: '100px',
    height: '100px',
    margin: '20px auto 10px auto',
  },
  text1: {
    fontSize: 16,
    color: '#32363A',
    lineHeight: '24px',
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContent: {
    fontSize: 16,
    color: '#32363A',
    lineHeight: '24px',
    marginBottom: 31,
  },
});
