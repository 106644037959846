/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cookie from 'cookie';
import { getAIPigai } from 'common/apis';
import { redirectToLoginPage } from 'utils/action';
import { View, Scrollbar, Image } from '@zhike/ti-ui';
import Header from './header';
import SpeakingV2 from './speaking';
import WritingV2 from './writing';
import styles from './styles';

// 报告
export default class MachineCorrectV2 extends Component {
  // 参数
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isError: true,
      isCompatible: true,
      correctData: {},
    };
  }

  // 加载
  checkBrowserCompatibility() {
    if (
      global.navigator.userAgent.match(/msie/i) ||
      global.ActiveXObject ||
      ('ActiveXObject') in global
    ) {
      this.setState({
        isCompatible: false,
      });
      return false;
    }
    return true;
  }

  getToken(tokenUrl) {
    if (tokenUrl) {
      return tokenUrl;
    }
    try {
      const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
      return user.token; // eslint-disable-line
    } catch (e) {
      console.log(e, 'token');
      redirectToLoginPage();
      return null;
    }
  }

  async fetchCorrectData(token, subject, answerId) {
    const response = await axios({
      url: `${getAIPigai[1].replace(/@subject/, subject)}?answerId=${answerId}&token=${token}&tranResult=2`,
      headers: {
        From: 1,
      },
      method: 'GET',
    });

    if (response.data.code === 'BadGateway') {
      redirectToLoginPage();
      return null;
    }
    return response;
  }

  async componentDidMount() {
    const { match } = this.props;
    const { subjectId, answerId } = match.params;
    const subject = parseInt(subjectId, 10) === 8 ? 'speaking' : 'writing';
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');

    if (!this.checkBrowserCompatibility()) {
      return;
    }

    const token = this.getToken(tokenUrl);
    if (!token) return;

    const correctData = await this.fetchCorrectData(token, subject, answerId);
    if (correctData) {
      this.setState({
        isError: false,
        correctData,
      });
    }
  }

  // 渲染
  render() {
    const { isCompatible, isError, correctData } = this.state;
    const { match } = this.props;
    const { subjectId} = match.params;

    return (
      <View className={styles.container}>
        {
          !isCompatible &&
          <View>
            <View className={styles.text}>暂不支持当前浏览器...</View>
            <View className={styles.text}>
              <a
                style={{ color: '#fff' }}
                href="http://www.google.cn/chrome/browser/desktop/index.html"
                target="_blank" // eslint-disable-line
              >
                请下载最新版本Chrome浏览器
              </a>
            </View>
          </View>
        }
        {
          isCompatible && isError &&
          <View className={styles.loadingBox}>
            <Image
              className={styles.loading}
              src={require('components/assets/loading.gif')}
            />
            <View className={styles.text}>小智正在努力生成报告...</View>
          </View>
        }
        {
          isCompatible && !isError && correctData.data.data.aiPigaiResult &&
          <Scrollbar>
            <Header subjectId={subjectId} correctData={correctData.data.data} />
            {
              subjectId === '8' && <SpeakingV2 correctData={correctData.data.data} />
            }
            {
              subjectId !== '8' && <WritingV2 correctData={correctData.data.data} />
            }
            <View className={styles.footer}>
              说明：本页面所有分数、评语和建议等数据均由智课AI机器人提供，仅供参考；你可选择外教批改服务，评分更准确哦~
            </View>
          </Scrollbar>
        }

      </View>
    );
  }
}
