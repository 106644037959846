import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from 'routers';
import { View, Scrollbar } from '@zhike/ti-ui';
import { SearchWords } from '@zhike/ti-component';
import { handleIeltsAnswer } from 'utils';
import RenderIndex from 'components/report/common/render_index';
import Question from 'components/question';
import AnswerParsing from 'components/report/common/answer_parsing';
import VideoParsing from 'components/report/common/video_parsing';
import AnswerTable from 'components/report/common/answer_table';
import ReportArticle from 'components/report/common/article';
import Notes from 'components/report/common/notes';
import styles from './styles';

// 阅读题目
export default class ReadingReport extends Component {
  // 参数
  static propTypes = {
    practice: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userGroup: PropTypes.number,

  };

  static defaultProps = {
    userGroup: 1,
  }

  newSetStepRecord(value) {
    const { mode, exerciseId } = this.props.match.params;
    const search = global.location.search; // eslint-disable-line
    history.push(`/report/${mode}/${exerciseId}/${value}${search}`);
  }

  // 渲染
  render() {
    const { practice, match, userGroup, userGroupName } = this.props;
    const { questions, conciseId, material, subjectId } = practice;
    const practiceId = practice.id;
    const { questionMaterialId, exerciseId } = match.params;
    // 生成组件信息
    return (
      <Scrollbar
        className={styles.container}
        onScrollStart={() => { SearchWords.hide(); }}
      >
        <View className={styles.questionWrapper}>
          <View className={styles.questionsTab}>
            <RenderIndex
              questions={questions}
              match={match}
            />
          </View>
          <View className={styles.questions}>
            <Scrollbar
              className={styles.questionContent}
              onScrollStart={() => { SearchWords.hide(); }}
            >
              {
                questions.map((question, index) => {
                const { materials, extra, type, id, subjectId, videoTimeRangeId } = question;
                const { answerAnalysis } = extra;
                const qNum = [];
                const answerArr = handleIeltsAnswer(materials, type, true);

                materials.map(material => {
                  qNum.push(material.qNum);
                  return false;
                });
                const renderProps = {
                  isReport: true,
                  params: match.params,
                  materials,
                  extra,
                  type,
                  id,
                  subjectId,
                  newSetStepRecord: (key, value) => this.newSetStepRecord(value),
                  newSetRecord: { selected: parseInt(questionMaterialId, 10) },
                };
                return (
                  <View
                    className={styles.question}
                    key={index}
                  >
                    <Question question={question} {...renderProps} />
                    <AnswerTable
                      exerciseId={exerciseId}
                      qNum={qNum}
                      answerArr={answerArr}
                      isChooseMany={type === 'ChooseMany'}
                    />
                    {
                      exerciseId > 0 &&
                      <View style={{ marginTop: '40px' }}>
                        <Notes question={question} />
                      </View>
                    }
                    {
                      answerAnalysis &&
                      answerAnalysis.paragraphs &&
                      answerAnalysis.paragraphs.length !== 0 &&
                      <AnswerParsing
                        answerAnalysis={answerAnalysis}
                        questionId={id}
                      />
                    }
                    {
                      conciseId && conciseId !== -1 && question && practiceId &&
                      <VideoParsing
                        practiceId={practiceId}
                        startTip={videoTimeRangeId}
                        conciseId={conciseId}
                        userGroup={userGroup}
                        userGroupName={userGroupName}
                      />
                    }
                  </View>
                  );
                })
              }
            </Scrollbar>
          </View>
        </View>
        <View className={styles.articleWrapper}>
          <View className={styles.article}>
            <ReportArticle
              subjectId={subjectId}
              material={material}
            />
          </View>
        </View>
      </Scrollbar>
    );
  }
}
