import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { View, Image, Scrollbar } from '@zhike/ti-ui';
import { Modal, SearchWords } from '@zhike/ti-component';
import ReportArticle from 'components/report/common/article';
import AnswerParsing from 'components/report/common/answer_parsing';
import VideoParsing from 'components/report/common/video_parsing';
import Collect from 'components/report/common/collect';
import Notes from 'components/report/common/notes';
import Article from 'components/article';
import styles from './styles';

// 阅读题目
export default class WritingReport extends Component {
  // 参数
  static propTypes = {
    practice: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    userGroup: PropTypes.number,
  };

  static defaultProps = {
    userGroup: 1,
  }

  constructor(props) {
    super(props);
    this.state = {
      showScope: false,
    };
  }

  // 图片预览
  onImagePreview = src => {
    Modal.show('ModalPreview', {
      src,
      hideHeader: true,
    });
  }

  toggleScope = e => {
    this.setState({
      showScope: e.type === 'mouseenter',
    });
  }

  // 渲染
  render() {
    const { practice, match, userGroup, userGroupName } = this.props;
    const { exerciseId } = match.params;
    const { showScope } = this.state;
    const { questions, material, subjectId, id, conciseId } = practice;
    const question = questions[0] || {};
    const direction = get(question, 'materials.0.direction'); // 指导语
    const imageMaterial = get(question, 'materials.0.image'); // 写作材料图片
    const stem = get(question, 'stem'); // 题目
    const questionId = get(question, 'id');
    const answerAnalysis = get(question, 'materials.0.analysis'); // 答案解析
    const videoTimeRangeId = get(question, 'videoTimeRangeId');
    return (
      <Scrollbar
        className={styles.container}
        onScrollStart={() => { SearchWords.hide(); }}
      >
        <View className={styles.questionWrapper}>
          <Scrollbar
            className={styles.questionContent}
            onScrollStart={() => { SearchWords.hide(); }}
          >
            <View className={styles.question}>
              {
                direction && JSON.stringify(direction) !== '{}' &&
                get(direction, 'paragraphs') &&
                get(direction, 'paragraphs').length !== 0 &&
                <View className={styles.material}>
                  <View className={styles.titleBox}>
                    <View className={styles.title}>Direction:</View>
                    <View style={{ marginLeft: '20px' }}>
                      <Collect question={question} exerciseId={exerciseId} />
                    </View>
                  </View>
                  <Article material={direction} />
                </View>
              }
              {
                stem &&
                <View className={styles.material}>
                  <View className={styles.titleBox}>
                    <View className={styles.title}>Question:</View>
                    {
                      (!direction || JSON.stringify(direction) === '{}' ||
                      !(get(direction, 'paragraphs') &&
                      get(direction, 'paragraphs').length !== 0)) &&
                      <View style={{ marginLeft: '20px' }}>
                        <Collect question={question} exerciseId={exerciseId} />
                      </View>
                    }
                  </View>
                  <Article material={stem} />
                </View>
              }
              {
                imageMaterial && imageMaterial.src &&
                <View
                  className={styles.imageForWriting}
                  onClick={() => { this.onImagePreview(imageMaterial.src); }}
                  onMouseEnter={this.toggleScope}
                  onMouseLeave={this.toggleScope}
                >
                  <Image
                    src={imageMaterial.src}
                    className={styles.pic}
                  />
                  {
                    showScope &&
                    <Image
                      src={require('../../assets/scope.png')}
                      className={styles.scope}
                    />
                  }
                </View>
              }
            </View>
          </Scrollbar>
        </View>
        <View className={styles.articleWrapper}>
          <Scrollbar
            className={styles.article}
            onScrollStart={() => { SearchWords.hide(); }}
          >
            <View className={styles.reportArticle}>
              <ReportArticle
                subjectId={subjectId}
                material={material}
                question={question}
              />
            </View>
            {
              exerciseId > 0 &&
              <View style={{ marginTop: '40px' }}>
                <Notes question={question} />
              </View>
            }
            {
              answerAnalysis &&
              answerAnalysis.paragraphs &&
              answerAnalysis.paragraphs.length !== 0 &&
              <AnswerParsing
                answerAnalysis={answerAnalysis}
                questionId={questionId}
              />
            }
            {
              conciseId && conciseId !== -1 && question &&
              <VideoParsing
                practiceId={id}
                startTip={videoTimeRangeId}
                conciseId={conciseId}
                userGroup={userGroup}
                userGroupName={userGroupName}
              />
            }
          </Scrollbar>
        </View>
      </Scrollbar>
    );
  }
}
