import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },

  left: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginTop: 40,
    paddingTop: 40,
    paddingLeft: 40,
    // paddingBottom: 40,
  },
  right: {
    flex: 1,
    position: 'relative',
    flexDirection: 'row',
    paddingTop: 40,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: '#F6F8F9',

    '@media (max-width: 1024px)': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  result: {
    width: '510px',
    position: 'absolute',
    top: 0,
    right: '40px',
    zIndex: '10',
  },
  question: {
    width: 510,
    overflowX: 'hidden',
    overflowY: 'hidden',
    marginTop: 40,
    marginBottom: 40,

    '@media (max-width: 1024px)': {
      width: 500,
      paddingLeft: 40,
    },

  },
  textRigth: {
    display: 'inline-block',
    height: '100%',
    width: 510,
    fontSize: 16,
    color: '#32363A',
    lineHeight: '22px',
    marginTop: 38,
    fontFamily: 'PingFangSC-Regular',
  },
  content: {
    width: 510,
    height: '100%',

    '@media (max-width: 1024px)': {
      width: 'auto',
    },
  },
});
