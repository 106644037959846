import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import styles from './styles';

// 阅读指令
export default class GeneralEnding extends Component {
    // 参数
    static propTypes = {
      step: PropTypes.object.isRequired,
      params: PropTypes.object,
    };
    static defaultProps = {
      params: {},
    };
  // 模块加载
    componentDidMount() {
      Header.config({
        title: '',
        showButtons: ['continue'],
      });
      Header.cleanTimer();
    }

    mockContent = () => (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={styles.p}>Your <View className={styles.strong}>Test </View>has finished.</View>
        <View className={styles.p}>All of your answers have been stored.</View>
        <View className={styles.p}>You can view your score report.</View>
        <View className={styles.p}>Click on Continue to go on.</View>
        {/* eslint-enable */}
      </Scrollbar>
    )

    normalContent = () => <div />

    // 渲染
    render() {
      const { mode } = this.props.params;
      const Content = mode === 'mock' ? this.mockContent() : this.normalContent();
      return Content;
    }
}
