import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Scrollbar } from '@zhike/ti-ui';
import { Modal, SearchWords } from '@zhike/ti-component';
import Article from 'components/article';
import styles from './styles';

// 阅读题目
export default class AnswerParsing extends Component {
  // 参数
  static propTypes = {
    answerAnalysis: PropTypes.object.isRequired,
    questionId: PropTypes.number.isRequired,
  };
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      realityHeight: 0,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      const Ele = global.document.getElementById(this.props.questionId);
      if (Ele) {
        const height = Ele.offsetHeight;
        const realityHeight = height >= 200 ? 200 : height;
        this.setState({ realityHeight });
      }
    }, 100);
  }
  // 渲染
  render() {
    const { answerAnalysis, questionId } = this.props;
    const { realityHeight } = this.state;
    return (
      <View className={styles.container}>
        {
          <View className={styles.analysis}>
            <View className={styles.title}>
              <View className={styles.item}>
                <View className={styles.icon} />
                <View className={styles.answerAnalysis}>答案解析</View>
              </View>
              <View
                className={[styles.item, styles.analysisIcon]}
                onClick={() => Modal.show('ModalAnalysis', {
                  title: '答案解析',
                  answerAnalysis,
                })}
              >
                <Image
                  src={require('./assets/full_screen@2x.png')}
                  className={styles.screen}
                />
                <View className={styles.fullScreen}> 展开 </View>
              </View>
            </View>
            <View
              className={styles.analysisContent}
            >
              <Scrollbar
                height={`${realityHeight}px`}
                className={styles.analysisText}
                id={questionId}
                onScrollStart={() => { SearchWords.hide(); }}
              >
                <Article
                  material={answerAnalysis}
                />
              </Scrollbar>
            </View>
          </View>
        }
      </View>
    );
  }
}
