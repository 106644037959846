import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View } from '@zhike/ti-ui';
import { css } from 'aphrodite';
import { get } from 'lodash';
import styles from './styles';

// 阅读题目
export default class AnswerTable extends Component {
  // 参数
  static propTypes = {
    qNum: PropTypes.array.isRequired,
    answerArr: PropTypes.array.isRequired,
    isChooseMany: PropTypes.bool,
    exerciseId: PropTypes.string.isRequired, // 判断是否是未作答报告页
  };
  static defaultProps = {
    isChooseMany: false,
  };

  // 渲染
  render() {
    const { qNum, answerArr, isChooseMany, exerciseId } = this.props;
    // 对填空题，存在多个正确答案的情况，| 两侧添加了空格
    return (
      <View className={styles.container}>
        <table className={css(styles.table)}>
          <thead className={css(styles.thead)}>
            <tr>
              {
                exerciseId > 0 &&
                <th className={css(styles.th)}>你的答案</th>
              }
              <th className={css(styles.th)}>正确答案</th>
            </tr>
          </thead>
          <tbody>
            {
              answerArr.map((answer, index) => (
                <tr key={index}>
                  {
                    exerciseId > 0 &&
                    <th className={css(styles.th)}>
                      {
                        qNum[index] &&
                        qNum[index].length === 1 ? qNum[index][0] :
                        qNum[index].map((item, index1) => {
                          if (index1 === qNum[index].length - 1) {
                            return item;
                          }
                          return `${item}&`;
                        })
                      }
                      .&nbsp;&nbsp;
                      {
                        answerArr[index] &&
                        answerArr[index].userAnswer &&
                        <span
                          className={css(
                            answerArr[index].isCorrect ? styles.correct : styles.error)}
                        >
                          {
                            !isChooseMany &&
                            answerArr[index].userAnswer
                          }
                          {
                            isChooseMany &&
                            answerArr[index].userAnswer.split('').map((item, index1) => {
                              const answer = get(answerArr, `${index}.answer`);
                              return (
                                <span
                                  key={index1}
                                  className={css(
                                    answer && answer.indexOf(`${item}`) !== -1 ? styles.correct : styles.error)}
                                >
                                  {item}
                                </span>
                              );
                            },
                          )
                          }
                        </span>
                      }
                    </th>
                  }
                  <th className={css(styles.th)}>
                    {
                      qNum[index] &&
                      qNum[index].length === 1 ? qNum[index][0] :
                      qNum[index].map((item, index1) => {
                        if (index1 === qNum[index].length - 1) {
                          return item;
                        }
                        return `${item}&`;
                      })
                    }
                    .&nbsp;&nbsp;
                    {
                      answerArr[index] &&
                      answerArr[index].answer &&
                      answerArr[index].answer
                    }
                  </th>
                </tr>
              ))
            }
          </tbody>
        </table>
      </View>
    );
  }
}
