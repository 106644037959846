import { take, fork } from 'redux-saga/effects';
import { request } from 'utils/action';
import * as apis from 'common/apis';
import * as actions from 'actions/report';

// 获取练习
export const watchGetReport = function* watchGetReport() {
  for (;;) {
    const { payload } = yield take(actions.getReport);
    let newApis;
    if (payload.payload.id) {
      if (payload.payload.mode === 'practice') {
        newApis = apis.getPractice.map((item, index) => {
          if (index === 1) {
            return `${item}/${payload.payload.id}`;
          }
          return item;
        });
      } else if (payload.payload.mode === 'question') {
        newApis = apis.getPracticeByQuestion.map((item, index) => {
          if (index === 1) {
            return item.replace(/@id/, payload.payload.id);
          }
          return item;
        });
      }
    } else {
      newApis = payload.payload.mode === 'mock' ? apis.getMockReport : apis.getExercise;
    }
    yield fork(
      request,
      actions.realGetReport,
      newApis,
      payload,
    );
  }
};
