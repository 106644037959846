import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },

  titleBox: {
    marginBottom: 20,
    flexDirection: 'row',
    height: 28,
    lineHeight: '24px',
    fontSize: '12px',
    color: '#878f98',
    justifyContent: 'space-between',
  },

  title: {
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItem: 'center',
  },
  tab: {
    width: 80,
    height: 28,
    lineHeight: '24px',
    fontSize: '12px',
    color: '#878f98',
    backgroundColor: '#fff',
    textAlign: 'center',
    cursor: 'pointer',
    borderBottom: 'solid 1px #878f98',
    borderTop: 'solid 1px #878f98',
    borderLeft: 'solid 1px #878f98',
    ':first-child': {
      borderTopLeftRadius: '3px',
      borderBottomLeftRadius: '3px',
    },
    ':last-child': {
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      borderRight: 'solid 1px #878f98',
    },
  },

  selectTab: {
    backgroundColor: '#32363a',
    color: '#fff',
    border: 'none',
  },

  article: {
    flex: 1,
    // paddingTop: 40,
    // paddingBottom: 86,
    width: '510px',
    height: '100%',
    overflowY: 'auto',

    '@media (max-width: 1024px)': {
      width: 470,
    },
  },

  writingArticle: {
    paddingTop: 0,
  },

  articleTitle: {
    fontSize: 24,
    marginBottom: 15,
    marginTop: 40,
    lineHeight: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  articleContent: {
    width: '510px',
    paddingBottom: 40,

    '@media (max-width: 1024px)': {
      width: 470,
    },
  },
  articleEmpty: {
    fontSize: 14,
    textAlign: 'center',
  },

  empty: {
    width: 100,
    height: 100,
    margin: '10% auto 10px auto',
  },

  listeingContainer: {
    width: '100%',
    height: '100%',
  },

  otherContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  heading: {
    position: 'relative',
    width: '510px',
    height: '42px',
    overflowY: 'hidden',
  },
  scrollBar: {
    width: '510px',
    height: '59px',
    overflowX: 'auto',
    position: 'absolute',
    top: '0px',
    left: '0px',
    zIndex: 10,
  },
  listeningTab: {
    width: '510px',
    height: '42px',
    flexDirection: 'row',
  },

  listeningTitle: {
    fontSize: '18px',
    height: '42px',
    whiteSpace: 'nowrap',
    fontFamily: 'PingFangSC-Semibold',
    color: 'rgba(41,50,58,1)',
    lineHeight: '25px',
    marginRight: '40px',
    cursor: 'pointer',
    ':last-child': {
      marginRight: '0px',
    },
  },
  currentTitle: {
    paddingBottom: '17px',
    borderBottom: '1px solid rgba(51,153,255,1)',
  },
  listeningMaterial: {
    paddingTop: 20,
    width: '510px',
    height: '100%',
    flex: 1,

    '@media (max-width: 1024px)': {
      width: 'auto',
    },
  },
  text: {
    justifyContent: 'center',
    marginLeft: '20px',
    fontSize: 14,
    fontFamily: 'PingFang-SC-Medium',
    fontWeight: 500,
    color: 'rgba(135,143,152,1)',
    lineHeight: '20px',
  },
  other: {
    justifyContent: 'space-between',
  },
});
