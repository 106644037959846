import { cloneDeep } from 'lodash';

// 获取练习名称
export const getSubjectName = subjectId => {
  const subjectToName = {
    5: 'Listening',
    6: 'Reading',
    7: 'Writing',
    8: 'Speaking',
  };

  return subjectToName[subjectId] || '';
};

// 添加附加信息
const addExtraInfo = stepList => {
  let newStepList = cloneDeep(stepList);
  newStepList = newStepList.map((step, index) => {
    const obj = {
      id: index + 1,
    };
    return Object.assign({}, step, obj);
  });

  return newStepList;
};

export const handlePractice = practice => {
  const { subjectId, questionBackup } = practice;
  const { questions } = questionBackup;
  const answers = [];
  const step = {};
  let speakingQnum = 1;
  step.subjectId = subjectId;
  const questionMaterialIds = [];
  Array.isArray(questions) && questions.forEach(question => {
    const { materials } = question;
    const userAnswers = [];
    let answerIndex = 0;
    Array.isArray(materials) && materials.forEach(material => {
      const { qNum, id, answer, userAnswer } = material;
      const answerIndexs = [];
      Array.isArray(answer) && answer.forEach(() => {
        answerIndexs.push(answerIndex);
        answerIndex += 1;
      });
      if (question.type === 'Speaking') {
        questionMaterialIds.push({
          qNum: speakingQnum,
          questionMaterialId: id,
          answerIndexs,
          questionId: question.id,
        });
        speakingQnum += 1;
      } else {
        questionMaterialIds.push({
          qNum,
          questionMaterialId: id,
          answerIndexs,
          questionId: question.id,
        });
      }
      // 取用户的每个小题的答案
      if (userAnswer) {
        const { answer, questionMaterialId } = userAnswer;
        userAnswers.push({
          answer,
          questionMaterialId,
        });
      }
    });
    // 整合原来的答案接口的结构
    if (userAnswers.length > 0) {
      answers.push({
        questionId: question.id,
        answerType: question.type,
        userAnswers,
      });
    }
  });
  step.questionMaterialIds = questionMaterialIds;
  step.type = `${getSubjectName(practice.subjectId)}Question`;
  return { step, answers };
};

// 获取练习的做题步骤
export const getStepListForPractice = practice => {
  const stepList = [];
  const { subjectId } = practice;
  if (subjectId === 5 || subjectId === 8) {
    stepList.push(
      {
        subjectId: practice.subjectId,
        type: `${getSubjectName(practice.subjectId)}Test`,
      });
  }
  stepList.push(
    {
      subjectId: practice.subjectId,
      type: `${getSubjectName(practice.subjectId)}Directions`,
    },
  );
  const { step, answers } = handlePractice(practice);
  stepList.push(step);
  stepList.push({
    subjectId: practice.subjectId,
    type: 'End',
  });
  return { stepList: addExtraInfo(stepList), answers };
};

export const getStepListForQuestion = practice => {
  const stepList = [];
  const { subjectId } = practice;
  if (subjectId === 5) {
    stepList.push(
      {
        subjectId: practice.subjectId,
        type: `${getSubjectName(practice.subjectId)}Test`,
      });
  }
  stepList.push(handlePractice(practice));
  stepList.push({
    subjectId: practice.subjectId,
    type: 'End',
  });
  return addExtraInfo(stepList);
};

