import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',

  },

  content: {
    width: 1024,
    margin: '0 auto',
    alignItems: 'center',
  },
  model: {
    width: 140,
    height: 140,
    marginTop: 40,
  },

  title: {
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  card: {
    alignItems: 'center',
    marginTop: 40,
    padding: 35,
    border: '1px solid #DCE6EC',
    borderRadius: 4,
  },

  volume: {
    marginLeft: 6,
    width: 40,
    height: 40,
  },

  button: {
    marginTop: 10,
    marginBottom: 22,
  },

  p: {
    color: '#878F98',
    fontSize: 12,
    marginTop: 3,
  },
});
