import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  container1: {
    width: 1024,
    alignItems: 'flex-start',
    margin: '40px auto 0',
  },
  strong: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },

  p: {
    display: 'block',
    marginTop: 14,
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
  },

  title: {
    fontSize: '16px',
    fontFamily: 'PingFangSC-Semibold',
    color: 'rgba(50,54,58,1)',
    lineHeight: '24px',
    paddingLeft: 40,
  },
  text: {
    fontSize: '14px',
    fontFamily: 'PingFangSC-Regular',
    color: 'rgba(50,54,58,1)',
    lineHeight: '24px',
    marginTop: '8.4px',
    paddingLeft: 40,
  },
  text1: {
    fontSize: '14px',
    fontFamily: 'PingFangSC-Regular',
    color: 'rgba(50,54,58,1)',
    lineHeight: '24px',
    marginTop: '8.4px',
    marginBottom: '20.6px',
    paddingLeft: 40,
  },
  start: {
    width: 1024,
    marginTop: '40.6px',
    alignItems: 'center',
  },
  button: {
    marginTop: '20.4px',
    width: '120px',
    height: '40px',
    background: 'rgba(51,153,255,1)',
    borderRadius: '3px',
  },
  buttonText: {
    fontSize: '16px',
    fontFamily: 'PingFangSC-Semibold',
    color: 'white',
    lineHeight: '20px',
  },

});
