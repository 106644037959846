import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
  },

  loadingBox: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },


  loading: {
    width: 100,
    height: 100,
    margin: '0 auto',
  },

  content: {
    width: 1180,
    margin: '0 auto',
  },

  text: {
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
  },

  footer: {
    margin: '0 auto',
    marginTop: 48,
    marginBottom: 24,
    width: 1200,
    height: 72,
    lineHeight: '72px',
    backgroundColor: '#f3f6fb',
    color: '#8f94a7',
    textAlign: 'center',
  },
});
