export const version = '1.0.0';
export const port = 8080;
export const cdnUrl = 'https://hq-static.smartstudy.com';
export const loginUrl = 'http://www.dev.smartstudy.com/signin?no_meiqia=1&smartRedirect=';
export const zhikeUrl = 'http://www.dev.smartstudy.com';
export const smartUrl = 'http://sp.dev.smartstudy.com';
export const baseUrl = '//ti-base.dev.smartstudy.com';
export const activationUrl = 'https://sp.smartstudy.com/student/course';
export const apiUrl = 'https://tiku.dev.smartstudy.com';
export const pigaiUrl = 'https://www.dev.smartstudy.com/api/user/pigai';
