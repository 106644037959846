import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { View, Image } from '@zhike/ti-ui';
import { smoothScroll } from 'utils/smoothScroll';
import styles from './styles';


// 做题
export default class Review extends Component {
  static instance;

  // 禁止 点击切换题目
  static forbidClick() {
    if (this.instance) {
      this.instance.forbidClick();
    }
  }
  // 恢复 点击切换
  static recoverClick() {
    if (this.instance) {
      this.instance.recoverClick();
    }
  }
  // 点击下一题
  static handleNext() {
    if (this.instance) {
      this.instance.handleNext();
    }
  }
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      forbid: false,
    };
  }

  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
  };
  componentDidMount() {
    const { newSetRecord } = this.props;
    const selectedId = newSetRecord && newSetRecord.selected;
    const anchorElement = global.document.getElementById(selectedId);
    if (anchorElement && anchorElement.tagName === 'INPUT') {
      anchorElement.focus();
    }
    setTimeout(() => {
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        smoothScroll({
          element: anchorElement,
          position: 'start',
          duration: 500,
        });
      }
    }, 300);
  }

  // review 按钮切换
  clickReview() {
    const { newSetRecord } = this.props;
    const selectedId = newSetRecord.selected;
    if (!selectedId) return false;
    const isReview = newSetRecord[selectedId] && newSetRecord[selectedId].isReview;
    this.props.newSetStepRecord('isReview', !isReview, selectedId);
  }
  // 点击切换
  clickNum(questionMaterialId) {
    if (this.state.forbid) return false;
    this.props.newSetStepRecord('selected', questionMaterialId);
    // 页面滚动到选中题号位置 找到锚点
    const anchorElement = global.document.getElementById(questionMaterialId);
    // 如果对应id的锚点存在，就跳转到锚点
    if (anchorElement) {
      setTimeout(() => {
        smoothScroll({
          element: anchorElement,
          position: 'start',
          duration: 500,
        });
        setTimeout(() => {
          if (anchorElement.tagName === 'INPUT') {
            anchorElement.focus();
          }
        }, 300);
      }, 100);
    }
  }
  // 点击切换到下一题
  handleNext() {
    const { step, newSetRecord } = this.props;
    const { questionMaterialIds } = step;
    const selectedId = newSetRecord.selected;
    const index = findIndex(questionMaterialIds, item => item.questionMaterialId === selectedId);
    if ((index + 1) < questionMaterialIds.length) {
      this.clickNum(questionMaterialIds[index + 1].questionMaterialId);
    }
  }
  // 禁止 点击切换题目
  forbidClick() {
    this.setState({
      forbid: true,
    });
  }
  // 恢复 点击切换
  recoverClick() {
    this.setState({
      forbid: false,
    });
  }
  // 渲染
  render() {
    const { step, newSetRecord } = this.props;
    const { forbid } = this.state;
    const { questionMaterialIds } = step;
    const selectedId = newSetRecord.selected;
    const isReview = newSetRecord[selectedId] && newSetRecord[selectedId].isReview;
    return (
      <View className={styles.container}>
        <View className={styles.content}>
          <View className={styles.review}>
            <View className={styles.labels}>
              <View
                className={
                  [styles.label,
                  styles.checkbox,
                  isReview && styles.checkboxChecked]}
                onClick={() => this.clickReview()}
              >
                {
                  isReview &&
                  <Image
                    className={[styles.label, styles.isReview]}
                    src={require('./assets/right.png')}
                  />
                }
              </View>
            </View>
            Review
          </View>
          <View className={styles.qNum}>
            {
              Array.isArray(questionMaterialIds) && questionMaterialIds.map((item, index1) => {
                const className = [styles.square];

                newSetRecord[item.questionMaterialId] &&
                newSetRecord[item.questionMaterialId].isReview &&
                className.push(styles.circle);

                newSetRecord[item.questionMaterialId] &&
                newSetRecord[item.questionMaterialId].isVisited &&
                className.push(styles.visited);

                selectedId === item.questionMaterialId &&
                className.push(styles.current);
                forbid && className.push(styles.forbid);
                if (/^\d+$/.test(item.qNum)) {
                  return (
                    <View
                      className={className}
                      key={index1}
                      onClick={() => this.clickNum(item.questionMaterialId)}
                    >
                      {item.qNum}
                    </View>
                  );
                }
                return Array.isArray(item.qNum) && item.qNum.map((num, index) => (
                  <View
                    className={className}
                    key={index}
                    onClick={() => this.clickNum(item.questionMaterialId)}
                  >
                    {num}
                  </View>
                ));
              })
            }
          </View>
        </View>
      </View>
    );
  }
}
