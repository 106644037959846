import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { View, Image } from '@zhike/ti-ui';
import { smoothScroll } from 'utils/smoothScroll';
import styles from './mockStyle';

const flowLayoutStep = ['WritingQuestion', 'SpeakingQuestion'];

// 做题
export default class MockReview extends Component {
  static instance;

  // 禁止 点击切换题目
  static forbidClick() {
    if (this.instance) {
      this.instance.forbidClick();
    }
  }
  // 恢复 点击切换
  static recoverClick() {
    if (this.instance) {
      this.instance.recoverClick();
    }
  }
  // 点击下一题
  static handleNext(specifyIndex) {
    if (this.instance) {
      this.instance.handleNext(specifyIndex);
    }
  }
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      forbid: false,
    };
  }

  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
    stepList: PropTypes.array,
    handleChangePractice: PropTypes.func,
    record: PropTypes.object,
    params: PropTypes.object,
  };
  static defaultProps = {
    stepList: [],
    handleChangePractice: () => 0,
    record: {},
    params: {},
  }

  componentDidMount() {
    const { newSetRecord, step } = this.props;
    const selectedId = newSetRecord && newSetRecord.selected;
    this.clickNum(selectedId, step.practiceId);
  }

  // review 按钮切换
  clickReview() {
    const { newSetRecord } = this.props;
    const selectedId = newSetRecord.selected;
    if (!selectedId) return false;
    const isReview = newSetRecord[selectedId] && newSetRecord[selectedId].isReview;
    this.props.newSetStepRecord('isReview', !isReview, selectedId);
  }
  // 点击切换
  clickNum = (questionMaterialId, practiceId) => {
    if (this.state.forbid) return false;
    this.props.handleChangePractice(practiceId, () => {
      this.props.newSetStepRecord('selected', questionMaterialId);
      // 页面滚动到选中题号位置 找到锚点
      const anchorElement = global.document.getElementById(questionMaterialId);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        setTimeout(() => {
          smoothScroll({
            element: anchorElement,
            position: 'start',
            duration: 500,
          });
          setTimeout(() => {
            if (anchorElement.tagName === 'INPUT') {
              anchorElement.focus();
            }
          }, 300);
        }, 100);
      }
    });
  }
  // 点击切换到下一题
  handleNext(specifyIndex) {
    const { step, newSetRecord } = this.props;
    const { questionMaterialIds } = step;
    const selectedId = newSetRecord.selected;
    if (typeof specifyIndex === 'number') {
      this.clickNum(questionMaterialIds[specifyIndex].questionMaterialId, step.practiceId);
      return;
    }
    const index = findIndex(questionMaterialIds, item => item.questionMaterialId === selectedId);
    if ((index + 1) < questionMaterialIds.length) {
      this.clickNum(questionMaterialIds[index + 1].questionMaterialId, step.practiceId);
    }
  }
  // 禁止 点击切换题目
  forbidClick() {
    this.setState({
      forbid: true,
    });
  }
  // 恢复 点击切换
  recoverClick() {
    this.setState({
      forbid: false,
    });
  }
  // 渲染
  render() {
    const { newSetRecord, stepList, record, params, step } = this.props;
    const { stepId } = params;
    const { forbid } = this.state;
    const selectedId = newSetRecord.selected;
    const isReview = newSetRecord[selectedId] && newSetRecord[selectedId].isReview;
    const questionMaterialArray = stepList && stepList.find(ele => ele.id === +stepId).mockPractices.map(ele => ({
      practiceId: ele.practiceId,
      questionMaterialIds: ele.questionMaterialIds,
    }));
    const ifFlow = flowLayoutStep.indexOf(step.type) !== -1;

    return (
      <View className={styles.container}>
        <View className={styles.content}>
          <View className={styles.review}>
            <View className={styles.labels}>
              <View
                className={
                  [styles.label,
                    styles.checkbox,
                    isReview && styles.checkboxChecked]}
                onClick={() => this.clickReview()}
              >
                {
                  isReview &&
                  <Image
                    className={[styles.label, styles.isReview]}
                    src={require('./assets/right.png')}
                  />
                }
              </View>
            </View>
            Review
          </View>
          <View className={styles.qNumContainer}>
            {
              questionMaterialArray.map((ele, index) => (
                <View className={ifFlow ? styles.mrAdaption : styles.qNumBox} key={index}>
                  <View className={styles.part}>Part {index + 1}</View>
                  <View className={styles.qNum}>
                    {
                      Array.isArray(ele.questionMaterialIds) && ele.questionMaterialIds.map((item, index1) => {
                        const className = [styles.square];
                        const currentPracticeRecord = record[ele.practiceId] && record[ele.practiceId].newStep[stepId];
                        if (currentPracticeRecord) {
                          const currentQuestionMaterial = currentPracticeRecord[item.questionMaterialId];
                          if (currentQuestionMaterial) {
                            const { isVisited, isReview } = currentQuestionMaterial;
                            if (isVisited) {
                              className.push(styles.visited);
                            }
                            if (isReview) {
                              className.push(styles.circle);
                            }
                          }
                        }
                        selectedId === item.questionMaterialId &&
                        className.push(styles.current);
                        forbid && className.push(styles.forbid);
                        if (/^\d+$/.test(item.qNum)) {
                          return (
                            <View
                              className={className}
                              key={index1}
                              onClick={() => this.clickNum(item.questionMaterialId, ele.practiceId)}
                            >
                              {item.qNum}
                            </View>
                          );
                        }
                        return Array.isArray(item.qNum) && item.qNum.map((num, index) => (
                          <View
                            className={className}
                            key={index}
                            onClick={() => this.clickNum(item.questionMaterialId, ele.practiceId)}
                          >
                            {num}
                          </View>
                        ));
                      }).slice(0, 14)
                    }
                  </View>
                </View>
              ))
            }
          </View>
        </View>
      </View>
    );
  }
}
