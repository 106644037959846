/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import PropTypes from 'prop-types';
import styles from './styles';
import TextSegment from './text_segment.js';
import SuggestionList from './suggestion_list';
import Sidebar from './sidebar.js';
import Practice from './practice.js';

export default class Pigai extends Component {
  static defaultProps = {
    commonAIPigaiResult: {},
    answerText: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
    };
  }

  handleClickSentence = (index) => {
    this.setState({ isSelected: this.state.isSelected === index ? null : index });
  };

  scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  handleClickSuggestion = (type, index) => {
    this.setState({
      isSelected: `${type}_${index}`
    }, () => {
      // Scroll both elements into view
      this.scrollToElement(`suggestion-${type}-${index}`);
      this.scrollToElement(`sentence-${type}-${index}`);
    });
  };

  processSegments = () => {
    const { answerText, commonAIPigaiResult } = this.props;

    if (!Array.isArray(commonAIPigaiResult) || commonAIPigaiResult.length === 0) {
      return [{ type: 'text', content: answerText }];
    }

    const segments = [];

    commonAIPigaiResult.forEach((item, index) => {
      const normalTextStartIndex = index > 0 
        ? commonAIPigaiResult[index - 1].endIndex + 1
        : 0;
        
      if (item.startIndex > normalTextStartIndex) {
        segments.push({
          type: 'text',
          content: answerText.substring(normalTextStartIndex, item.startIndex),
        });
      }

      segments.push({
        type: item.type,
        content: item.sentence,
        index: index,
      });
    });

    const lastItem = commonAIPigaiResult[commonAIPigaiResult.length - 1];

    if (lastItem.endIndex  < answerText.length) {
      segments.push({
        type: 'text',
        content: answerText.substring(lastItem.endIndex + 1),
      });
    }

    return segments;
  };

  render() {
    const { isSelected } = this.state;
    const { commonAIPigaiResult } = this.props;
    const segments = this.processSegments();

    return (
      <View>
        <View className={styles.container}>
          <View className={styles.answerText}>
            <h3>作文</h3>
            <TextSegment 
              segments={segments}
              isSelected={isSelected}
              onClickSentence={this.handleClickSentence}
            />
          </View>

          <SuggestionList 
            list={commonAIPigaiResult}
            isSelected={isSelected}
            onClickSentence={this.handleClickSuggestion}
          />
          <Sidebar list={commonAIPigaiResult} />
        </View>
        <Practice />
      </View>
    );
  }
}
