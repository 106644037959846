import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Button, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import { AudioPlayer } from '@zhike/ti-component';
import styles from './styles';

// 阅读指令
export default class SpeakingDirections extends Component {
  // 参数
  static propTypes = {
    practiceTypeId: PropTypes.number.isRequired,
    params: PropTypes.object,
  }
  static defaultProps = {
    params: {},
  }
  // 模块即将加载
  componentWillMount() {
  }

  // 模块加载完毕
  componentDidMount() {
    Header.config({
      isShowTime: false,
    });
    // 清除定时器
    Header.cleanTimer();
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
  }
  normalContent = () => {
    const { practiceTypeId } = this.props;
    return (
      <Scrollbar className={styles.container}>
        <View className={styles.container1}>
          <View className={styles.title}>IELTS Speaking</View>
          <View className={styles.text1}>Time : about
            {
              practiceTypeId === 9 && '20-30s'
            }
            {
              practiceTypeId === 23 && '1-2 minutes'
            }
            {
              practiceTypeId === 24 && '30s'
            }
          </View>
          <View className={styles.title}>INSTRUCTIONS TO CANDIDATES</View>
          <View className={styles.text}>• Answer the question.</View>
          <View className={styles.text1}>• Make sure the background is quiet when you are speaking.
          </View>
          <View className={styles.title}>INFORMATION FOR CANDIDATES</View>
          <View className={styles.text}>• There are one questions in this test</View>

          <View className={styles.start}>
            <View className={styles.title}>Click &apos;Start test&apos; when you are ready</View>
            <Button
              className={styles.button}
              text="Start test"
              textClassName={styles.buttonText}
              onClick={() => Header.next()}
            />
          </View>
        </View>
      </Scrollbar>
    );
  }

  mockContent = () => (
    <Scrollbar className={styles.container}>
      <View className={styles.container1}>
        <View className={styles.title}>IELTS Speaking</View>
        <View className={styles.text1}>Time : 14 minutes</View>
        <View className={styles.title}>INSTRUCTIONS TO CANDIDATES</View>
        <View className={styles.text}> • Answer the question.</View>
        <View className={styles.text1}> •  Make sure the background is quiet when you are speaking.</View>
        <View className={styles.title}>INFORMATION FOR CANDIDATES</View>
        <View className={styles.text}> • There are three parts to the test.</View>
        <View className={styles.start}>
          <View className={styles.title}>Click &apos;Start test&apos; when you are ready</View>
          <Button
            className={styles.button}
            text="Start test"
            textClassName={styles.buttonText}
            onClick={() => Header.next()}
          />
        </View>
      </View>
    </Scrollbar>
  )

  // 渲染
  render() {
    const { params } = this.props;
    const { mode } = params;
    const Content = mode === 'mock' ? this.mockContent() : this.normalContent();
    return Content;
  }
}
