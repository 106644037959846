import React, { Component } from 'react';
import { connect } from 'react-redux';
import Initial from 'components/initial';
import { getPractice, postExercise } from 'actions/practice';
import { getRecord, getAnswer, setRecord, setTimer, setAudioRecord } from 'actions/record';
import { setStepList } from 'actions/step';

// 初始化
class InitalContainer extends Component {
  render() {
    return (
      <Initial {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  record: state.record || {},
  stepList: state.stepList || {},
});

export default connect(mapStateToProps, {
  getPractice,
  getRecord,
  setStepList,
  postExercise,
  getAnswer,
  setRecord,
  setTimer,
  setAudioRecord,
})(InitalContainer);
