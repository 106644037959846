import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { keys, get } from 'lodash';
import { View, Image } from '@zhike/ti-ui';
import styles from './styles';

// 报告
export default class Speaking extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      tab: 'pronounce',
    };
    this.audio = null;
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  handleRotate(score) {
    let right = 225;
    let left = 225;
    if (score <= 15 && score > 0) {
      left = 225 - (180 * (score / 15));
    }
    if (score > 15) {
      left = 45;
      right = 225 - (180 * ((score - 15) / 15));
    }
    return {
      right,
      left,
    };
  }

  generateTab = () => {
    const { tab } = this.state;
    const { correctData } = this.props;
    const { machinePigaiResult } = correctData;
    const { pronunciation, grammaticalRangeAndAccuracy, lexicalResources, fluencyCoherence } = machinePigaiResult;
    const pronunciationScore = this.handleScore(pronunciation.score);
    const grammaticalRangeAndAccuracyScore = this.handleScore(grammaticalRangeAndAccuracy.score);
    const lexicalResourcesScore = this.handleScore(lexicalResources.score);
    const fluencyCoherenceScore = this.handleScore(fluencyCoherence.score);

    return (
      <View className={styles.tabs}>
        <View className={[styles.tab, tab === 'pronounce' && styles.tabSelect]} onClick={() => this.setState({ tab: 'pronounce' })}>
          <View className={styles.title}>Pronunciation</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {pronunciationScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(pronunciationScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(pronunciationScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'grammar' && styles.tabSelect]} onClick={() => this.setState({ tab: 'grammar' })}>
          <View className={styles.title}>Grammatical Range and Accuracy</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {grammaticalRangeAndAccuracyScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(grammaticalRangeAndAccuracyScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(grammaticalRangeAndAccuracyScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'lexical' && styles.tabSelect]} onClick={() => this.setState({ tab: 'lexical' })}>
          <View className={styles.title}>Lexical Resources</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {lexicalResourcesScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(lexicalResourcesScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(lexicalResourcesScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
        <View className={[styles.tab, tab === 'fluency' && styles.tabSelect]} onClick={() => this.setState({ tab: 'fluency' })}>
          <View className={styles.title}>Fluency & Coherence</View>
          <View className={styles.circleProgressWrapper}>
            <View className={styles.circle1}>
              <View className={styles.circle2}>
                {fluencyCoherenceScore}分
              </View>
            </View>
            <View className={[styles.wrapper, styles.right]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(fluencyCoherenceScore).right}deg)` }}
                className={[styles.circleProgress, styles.rightcircle]}
              />
            </View>
            <View className={[styles.wrapper, styles.left]}>
              <View
                style={{ transform: `rotate(${this.handleRotate(fluencyCoherenceScore).left}deg)` }}
                className={[styles.circleProgress, styles.leftcircle]}
              />
            </View>
          </View>
        </View>
      </View>);
  }

  generateSpeakingLanguageUse(languageUse) {
    const correctKeys = keys(languageUse.correct);
    const grammarKeys = keys(languageUse.grammar);
    let content;
    if (correctKeys.length > 0 || grammarKeys.length > 0) {
      content = (
        <View>
          {
            correctKeys.length > 0 && (
              <View>
                <View className={styles.subTitle}>根据你的回答，有些单词可替换如下</View>
                {
                  correctKeys.map((item, index) => (
                    <View key={index} className={styles.wordBox}>
                      <View className={styles.wordTitle}>
                        {item} {languageUse.correct[item] && languageUse.correct[item].text ? `[${languageUse.correct[item].text}]` : ''}
                      </View>
                      <View className={styles.wordContent}>{languageUse.correct[item].explain && languageUse.correct[item].explain.join(' ')}</View>
                    </View>))
                }
              </View>)
          }
          {
            grammarKeys.length > 0 &&
              (
                <View>
                  { languageUse.grammar['总评'] && <View className={styles.comment}>{languageUse.grammar['总评']}</View> }
                  <View className={styles.subTitle}>根据你的回答，请注意以下语法使用</View>
                  {
                    grammarKeys.map((item, index) => {
                      if (item === '总评') return null;
                      return (
                        <View key={index} className={styles.wordBox}>
                          <View className={styles.wordTitle}>{item}</View>
                          <View className={styles.wordContent}>{languageUse.grammar[item].join(' ')}</View>
                        </View>);
                    })
                  }
                </View>
              )
          }
        </View>);
    }
    return (
      <View className={styles.content}>
        <View>{`分项总评：${get(languageUse, 'comment.0', '无')}`}</View>
        {content}
      </View>);
  }

  handlePlay = (key, type) => {
    const { correctData } = this.props;
    const { machinePigaiResult } = correctData;
    const { correct } = machinePigaiResult.delivery;
    let src = correct[key][type];
    if (type === 'wrong') {
      src = `data:audio/wav;base64,${src.replace(/\s/g, '+')}`;
    }
    if (this.audio) {
      this.audio = null;
    }
    if (!src) return false;
    this.audio = new global.window.Audio(src);
    this.audio.play();
  }

  generateSpeakingDelivery(delivery) {
    const { correct } = delivery;
    const correctKeys = keys(correct);
    let content;
    if (correctKeys.length > 0) {
      content = (
        <View>
          <View className={styles.subTitle}>根据你的回答，请注意以下单词发音</View>
          <View className={styles.videoBox}>
            <View className={[styles.video, styles.videoTr]}>
              <View className={[styles.videoItem, styles.videoTh]}>单词</View>
              <View className={[styles.videoItem, styles.videoTh]}>your answer</View>
              <View className={[styles.videoItem, styles.videoTh]}>correct answer</View>
            </View>
            {
              correctKeys.map((item, index) => (
                <View className={styles.video} key={index}>
                  <View className={[styles.videoItem, styles.color3399ff]}>
                    {item} {correct[item] && correct[item].text ? `[${correct[item].text}]` : ''}
                  </View>
                  <View className={styles.videoItem}>
                    <Image
                      className={styles.videoImage}
                      src={require('./assets/wrong-play.png')}
                      onClick={() => this.handlePlay(item, 'wrong')}
                    />
                  </View>
                  <View className={styles.videoItem}>
                    <Image
                      className={styles.videoImage}
                      src={require('./assets/right-play.png')}
                      onClick={() => this.handlePlay(item, 'right')}
                    />
                  </View>
                </View>))
            }
          </View>
        </View>);
    }
    return (
      <View className={styles.content}>
        <View>{`评语：${get(delivery, 'comment.0', '无')}`}</View>
        {content}
      </View>);
  }

  generateTabContent = () => {
    const { tab } = this.state;
    const { correctData } = this.props;
    const { machinePigaiResult } = correctData;
    const { pronunciation, grammaticalRangeAndAccuracy, lexicalResources, fluencyCoherence } = machinePigaiResult;
    if (tab === 'grammar') {
      return this.generateSpeakingLanguageUse({
        ...grammaticalRangeAndAccuracy,
      });
    }
    if (tab === 'lexical') {
      return this.generateSpeakingLanguageUse({
        ...lexicalResources,
      });
    }
    if (tab === 'fluency') {
      return (
        <View className={styles.content}>
          <View>{`评语：${get(fluencyCoherence, 'comment.0', '无')}`}</View>
        </View>);
    }
    if (tab === 'pronounce') {
      return this.generateSpeakingDelivery(pronunciation);
    }
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateTab()}
        {this.generateTabContent()}
      </View>
    );
  }
}
