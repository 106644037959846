import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  questionWrapper: {
    height: '100%',
    width: 1024,
    margin: '0 auto',
  },
  question: {
    width: 1024,
    // marginTop: 40,
  },
});
