import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getReport } from 'actions/report';
import {
  getUploadSignature,
  postCorrectionImage,
  postCorrection,
  postMachinePigai,
} from 'actions/record';
import Report from 'components/report';

// 做题
class ReportContainer extends Component {
  render() {
    return (
      <Report {...this.props} />
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
  getReport,
  getUploadSignature,
  postCorrectionImage,
  postCorrection,
  postMachinePigai,
})(ReportContainer);
