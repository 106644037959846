import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, findIndex } from 'lodash';
import { View, Scrollbar } from '@zhike/ti-ui';
import { cdnUrl } from 'common/config';
import Header from 'components/header';
import Question from 'components/question';
import Article from 'components/article';
import { AudioPlayer, Modal } from '@zhike/ti-component';
import { getPracticeById } from 'utils/mock';
import { setStorageAudioRecord, getStorageAudioRecord } from './utils';
import styles from './styles';

// 单项选择题
export default class listeningQuestion extends Component {
  // 参数
  static defaultProps = {
    handleChangePractice: () => 0,
    stepList: [],
  };
  static propTypes = {
    step: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    handleChangePractice: PropTypes.func,
    stepList: PropTypes.array,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
    };
    this.nextIndex = 0;
    this.timer = null;
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
    // 如果用户听完part1的音频，直接卸载了页面。需要关闭清除计时器，否则会导致计时器时间到达之后，handleChangePractice，致使页面白屏
    clearTimeout(this.timer);
  }

  // 模块加载
  async componentDidMount() {
    const { mode } = this.props.params;
    const unavailableButtons = [];
    let showButtons = ['correct', 'pause', 'submit', 'volume'];
    let onClickNext = () => {
      Header.next();
    };
    if (mode === 'mock') {
      showButtons = ['volume', 'submit'];
      onClickNext = () => {
        AudioPlayer.pause();
        Modal.show('ModalAlert', {
          isUnhide: true,
          title: '提示',
          buttons: [
            {
              title: 'Submit',
              onClick: () => {
                AudioPlayer.unload();
                Header.next();
              },
            },
            {
              title: 'Cancel',
              class: 'gray',
              onClick: () => {
                AudioPlayer.resume();
              },
            },
          ],
          width: 400,
          component: (
            <View className={styles.modalCenter}>
              <View className={styles.p}>Do you determine to submit all your answers?</View>
              <View className={styles.p}>You WILL NOT be able to go back to this test once you click Submit button.</View>
            </View>
          ),
        });
      };
    }
    Header.config({
      showButtons,
      unavailableButtons,
      isShowTime: true,
      onClickNext,
    });
    setTimeout(() => {
      // 当head设置的参数返回之后, 播放音频
      this.playAudio();
    }, 300);
  }

  // 按照顺序，获取指导语音频以及材料音频。
  getAudioMaterial(step) {
    const audioMaterials = [];
    function getAudio(item) {
      const instructionalAudio = get(item, 'extra.instructionalAudio.src');
      const instructionalAudioName = get(item, 'extra.instructionalAudio.name');
      if (instructionalAudio) {
        audioMaterials.push({
          src: instructionalAudio,
          name: instructionalAudioName,
        });
      }
      const audioMaterial = get(item, 'extra.audioMaterial.src');
      const audioMaterialName = get(item, 'extra.audioMaterial.name');
      const isRepeatSrc = findIndex(audioMaterials, { name: audioMaterialName || '' });
      if (audioMaterial && isRepeatSrc === -1) {
        audioMaterials.push({
          src: audioMaterial,
          name: audioMaterialName,
        });
      }
    }
    const questions = (step.practice && step.practice.questions) || step.questions;
    questions.map(item => {
      getAudio(item);
      return false;
    });
    const newAudioMaterials = audioMaterials.map(item => {
      const newSrc = item.src.indexOf('//') === -1 ? `${cdnUrl}/${item.src}` : item.src;
      return newSrc;
    });
    return newAudioMaterials;
  }

  playMockAudio = audioMaterialsArr => {
    const { handleChangePractice, stepList, params } = this.props;
    const { stepId, exerciseId } = params;
    const audioLength = audioMaterialsArr.length;
    const audioRecord = getStorageAudioRecord(exerciseId);
    const audioMaterial = audioMaterialsArr.slice().splice(audioRecord, 1);
    // 所有part的音频未全部放完
    if (audioRecord < audioLength) {
      // 根据音频自动跳转到下一个part
      const currentPractice = getPracticeById(stepList, stepId, audioRecord);
      handleChangePractice(currentPractice.practiceId);
      AudioPlayer.play({
        src: audioMaterial[0],
        onEnd: () => {
          Header.pauseAudio();
          Header.config({
            unavailableButtons: ['pause'],
            inherit: true,
          });
          const nextAudioRecord = audioRecord + 1;
          // 存储下一个音频的下标(从0开始)。如果最后一个已经听完，则存储length值
          if (audioLength >= nextAudioRecord) {
            setStorageAudioRecord(exerciseId, nextAudioRecord);
          }
          // 音频只能播放至下标为(audioMaterialsArr.length-1)
          if (audioLength > nextAudioRecord) {
            this.timer = setTimeout(() => {
              this.playMockAudio(audioMaterialsArr);
            }, 30 * 1000);
          }
        },
      });
    } else {
      // 音频全部放完，当前 audioRecord 的值为 audioLength
      const currentPractice = getPracticeById(stepList, stepId, audioLength - 1);
      handleChangePractice(currentPractice.practiceId);
    }
  }

  // 播放音频
  playAudio() {
    const { step, params, practice } = this.props;
    const { mode } = params;
    const isMock = mode === 'mock';
    // 是正常练习
    if (!isMock) {
      // 获取音频， 并传给头部
      const audioMaterials = this.getAudioMaterial(step);
      AudioPlayer.unload(); // 先销毁在重新播放
      AudioPlayer.play({
        src: audioMaterials,
        onEnd: () => {
          Header.pauseAudio();
          Header.config({
            unavailableButtons: ['pause'],
            inherit: true,
          });
        },
      });
    }
    // 是模考
    if (isMock) {
      const audioMaterialArr = practice.data.practices.filter(ele => ele.subjectId === 5).map(ele => this.getAudioMaterial(ele));
      this.playMockAudio(audioMaterialArr);
    }
  }
  // 渲染
  render() {
    const { step, newSetRecord, newSetStepRecord, params } = this.props;
    const { material, questions } = step.practice || {};
    // 生成参数信息
    const renderProps = {
      newSetStepRecord,
      newSetRecord,
      step,
      params,
    };
    return (
      <Scrollbar
        className={styles.container}
      >
        <View className={styles.articleWrapper}>
          <View className={styles.article}>
            <View className={styles.articleTitle}>{material && material.title}</View>
            {
              material && <Article material={step.practice.material.origin} />
            }
          </View>
        </View>
        <View className={styles.questionWrapper}>
          <View className={styles.questions}>
            {
              Array.isArray(questions) &&
              questions.map((item, index) => (
                <View key={index} className={styles.question}>
                  <Question question={item} {...renderProps} />
                </View>))
            }
          </View>
        </View>
      </Scrollbar>
    );
  }
}

