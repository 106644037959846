import React, { Component } from 'react';
import MachineCorrectV2 from 'components/machine_correct_v2';

// 做题
export default class MachineCorrectV2Container extends Component {
  render() {
    return (
      <MachineCorrectV2 {...this.props} />
    );
  }
}
