import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: 1100,
    margin: '0 auto',
    paddingTop: 26,
    paddingBottom: 40,
    color: '#32363a',
  },

  p: {
    display: 'block',
    marginTop: 14,
    fontSize: 18,
    lineHeight: '26px',
    textAlign: 'center',
  },

  strong: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },

  time: {
    margin: '0 auto',
    marginTop: 34,
    width: 400,
  },

  timeText: {
    height: 48,
    lineHeight: '48px',
    color: '#fff',
    fontWeight: 'bold',
    backgroundColor: '#36424d',
    textAlign: 'center',
  },

  progress: {
    position: 'relative',
    width: 400,
    height: 48,
    backgroundColor: '#eaeff2',
    alignItems: 'center',
    justifyContent: 'center',
  },

  progressText: {
    position: 'relative',
    zIndex: 20,
    fontSize: 16,
  },

  progressBar: {
    position: 'absolute',
    zIndex: 10,
    height: 48,
    left: 0,
    top: 0,
    background: 'linear-gradient(right, #3399FF, #62C9FF)',
  },
});
