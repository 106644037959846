import React, { Component } from 'react';
import MachineCorrect from 'components/machine_correct';

// 做题
export default class MachineCorrectContainer extends Component {
  render() {
    return (
      <MachineCorrect {...this.props} />
    );
  }
}
