
// 机批写作 marks 去重
export const marksRemoveRepeat = marks => {
  if (!Array.isArray(marks)) return false;
  const hash = {};
  return marks.reduce((item, next) => {
    hash[next.start] !== undefined ? '' : hash[next.start] = true && item.push(next);
    return item;
  }, []);
};
