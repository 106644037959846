import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    cursor: 'pointer',
    userSelect: 'none',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wait: {
    cursor: 'wait',
  },

  text: {
    marginLeft: '4px',
    fontSize: '14px',
    lineHeight: '24px',
  },
  uncollect: {
    color: '#878F98',
  },
  collected: {
    color: '#FF8E04',
  },
});
