import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { history } from 'routers';
import { filter, get, cloneDeep } from 'lodash';
import { getStepListForPractice } from 'utils/step';
import { getStepListForMock } from 'utils/mockStep';
import { View, Image } from '@zhike/ti-ui';
import { createPromise } from 'utils/action';
import styles from './styles';

// 初始化
export default class Initial extends Component {
  // 参数
  static propTypes = {
    match: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    setStepList: PropTypes.func.isRequired,
    getPractice: PropTypes.func.isRequired,
    getAnswer: PropTypes.func.isRequired,
    setRecord: PropTypes.func.isRequired,
    setTimer: PropTypes.func.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      progressType: 'init',
      progressStepCount: 0,
    };
  }

  // 模块加载
  async componentDidMount() {
    const { getPractice, setStepList, setRecord, getAnswer, setAudioRecord } = this.props;
    const { mode, practiceId, operation } = this.props.match.params;
    let exerciseId = this.props.match.params.exerciseId; // eslint-disable-line
    let timeCost = 0;
    // 检测兼容性
    if (
      global.navigator.userAgent.match(/msie/i) ||
      global.ActiveXObject ||
      ('ActiveXObject') in global
    ) {
      history.push('/error/compatible');
      return;
    }

    // 获取做题关键信息
    try {
      // 获取套题
      this.setState({
        progressType: 'getPractice',
      });
      let stepList;
      let practice;
      let answers;
      if (mode === 'practice' || mode === 'question') {
        this.setState({
          progressType: 'getPractice',
        });
        practice = await createPromise(getPractice, { mode, exerciseId });
        const result = getStepListForPractice(practice.data);
        stepList = get(result, 'stepList');
        answers = get(result, 'answers');
        timeCost = get(practice, 'data.duration');
      } else if (mode === 'mock') {
        this.setState({
          progressType: 'getPractice',
        });
        const search = global.location.search; // eslint-disable-line
        const subjectIds = decodeURIComponent(search.match(new RegExp('[?#&]subjectIds=([^?#&]+)', 'i')) ? RegExp.$1 : '');
        const answerObj = await createPromise(getAnswer, {
          exerciseId,
        });
        answers = answerObj.data.answers; // eslint-disable-line
        timeCost = answerObj.data.exercise.duration; // eslint-disable-line
        practice = await createPromise(getPractice, { mockId: practiceId, subjectIds, exerciseId });
        stepList = getStepListForMock(practice.data);
      }
      if (operation !== 'new') {
        this.setState({
          progressType: 'getAnswer',
        });
        // 获取服务器端答案
        for (let i = 0; i < answers.length; i += 1) {
          const { questionId, answerType } = answers[i];
          let step
          if (mode !== 'mock') {
            step = filter(stepList, stepItem => {
              if (stepItem.questionMaterialIds) {
                if (filter(stepItem.questionMaterialIds, { questionId }).length > 0) {
                  return stepItem;
                }
              }
            })[0];
          }
          // 如果是模考，每一个科目的练习的结构是一个数组
          if (mode === 'mock') {
            for (let i = 0; i < stepList.length; i += 1) {
              const currentSteps = stepList[i].mockPractices;
              if (currentSteps && !step) {
                step = currentSteps.find(ele => {
                  const { questionMaterialIds } = ele;
                  return questionMaterialIds.findIndex(item => +item.questionId === +questionId) > -1;
                });
              }
            }
          }
          for (let k = 0; k < answers[i].userAnswers.length; k += 1) {
            const userAnswer = answers[i].userAnswers[k];
            let { answer } = userAnswer;
            const { questionMaterialId } = userAnswer;
            const practiceId = mode === 'mock' ? step && step.practiceId : undefined;
            if (answerType === 'Writing') {
              answer = answer && answer.text || '';
            }
            await setRecord({
              mode,
              id: exerciseId,
              keys: ['newStep', step.id, questionMaterialId, 'answer'],
              value: answer,
              practiceId,
            });
            await setRecord({
              mode,
              id: exerciseId,
              keys: ['newStep', step.id, questionMaterialId, 'isVisited'],
              value: true,
              practiceId,
            });
          }

          await setRecord({
            mode,
            id: exerciseId,
            keys: ['newStep', step.id, 'selected'],
            value: 0,
            practiceId,
          });
        }
      }

      // 设置做题步骤
      this.setState({
        progressType: 'setStepList',
      });

      await setStepList(stepList);
    } catch (e) {
      console.log('getStepListAndAnswer: 获取步骤和答案报错', e);
      Sentry.setContext('getStepListAndAnswer', e)
      Sentry.captureException(e)
      history.push('/error/loading');
      return true;
    }
    await this.handleStepAnswer(timeCost);
  }

  handleStepAnswer = async timeCost => {
    try {
      const { record, stepList, setTimer } = this.props;
      const { mode, practiceId, operation } = this.props.match.params;
      let exerciseId = this.props.match.params.exerciseId; // eslint-disable-line
      let lastStepId = 1;
      // 练习
      if (mode !== 'mock' && record && record.newStep) {
        Object.keys(record.newStep).forEach(key => {
          lastStepId = key;
        });
        const step = stepList.data[lastStepId - 1];
        await setTimer({
          mode,
          id: exerciseId,
          value: {
            subject: step.type,
            time: timeCost * 1000,
            isEnd: false,
          },
        });
      }
      // 如果是雅思模考的继续考试
      if (mode === 'mock' && record && operation !== 'new') {
        const tempRecord = cloneDeep(record);
        if (+get(tempRecord, 'lastStep.lastStep')) {
          lastStepId = +tempRecord.lastStep.lastStep;
        } else {
          delete tempRecord.lastStep
          Object.keys(tempRecord).forEach(ele => {
            const { newStep } = tempRecord[ele];
            const currentMaxStepId = Math.max(...Object.keys(newStep));
            lastStepId = Math.max(currentMaxStepId, lastStepId);
          })
        }
        const step = stepList.data[lastStepId - 1];
        await setTimer({
          mode,
          id: exerciseId,
          value: {
            subject: step.mockPractices ? step.mockPractices[0].type : step.type,
            time: timeCost * 1000,
            isCountDown: true,
            isEnd: false,
          },
        });
      }
      // 跳转
      const search = global.location.search; // eslint-disable-line
      history.push(`/${mode}/${practiceId}/${exerciseId}/${lastStepId}${search}`);
    } catch (e) {
      console.error('continueTestAndGetLastStepId: 继续考试获取最后的步骤id报错', e)
      Sentry.setContext('continueTestAndGetLastStepId', e)
      Sentry.captureException(e)
    }
  }

  // 渲染
  render() {
    const { progressType, progressStepCount } = this.state;
    let progressText = '';
    switch (progressType) {
      case 'init':
        progressText = '初始化';
        break;
      case 'getPractice':
      case 'getAnswer':
        progressText = '获取题目';
        break;
      case 'setStepList':
        progressText = '生成做题步骤';
        break;
      default:
        break;
    }

    return (
      <View className={styles.container}>
        <Image
          className={styles.loading}
          src={require('components/assets/loading.gif')}
        />
        <View className={styles.text}>正在{progressText}，请稍候...</View>
        {
          !!progressStepCount &&
          <View className={styles.progressWrapper}>
            <View
              className={styles.progress}
              style={{ width: `${progressStepCount !== 0 ? progressStep / progressStepCount * 100 : 0}%` }}
            />
          </View>
        }
      </View>
    );
  }
}
