import { cloneDeep } from 'lodash';
import { createHandleAction } from 'utils/action';
import { getPractice, realGetPractice } from 'actions/practice';
import { setStepList, realSetStepList } from 'actions/step';
import { realSetRecord, realSetTimer, realGetExerciseList, getExerciseList, realSetAudioRecord } from 'actions/record';
import { getReport, realGetReport } from 'actions/report';
import { getLogo, realGetLogo } from 'actions/logo';
import initialState from './initial_state';

export default {

  // 练习
  practice: createHandleAction(
    getPractice,
    realGetPractice,
    initialState.practice,
  ),

  // 站点图片
  logo: createHandleAction(
    getLogo,
    realGetLogo,
    initialState.logo,
  ),

  // 记录
  record: (state = initialState.record, action) => {
    if (action.type === realSetRecord.toString()) {
      const { keys, value, requestPayload } = action.payload;
      const { mode, practiceId } = requestPayload;
      const newState = cloneDeep(state) || {};
      let cntNode = newState;
      if (mode === 'mock') {
        cntNode[practiceId] = cntNode[practiceId] || {};
        let currentPractice = cntNode[practiceId];
        keys.forEach((key, index) => {
          if (index !== keys.length - 1) {
            currentPractice[key] = currentPractice[key] || {};
            currentPractice = currentPractice[key];
          } else {
            currentPractice[key] = value;
          }
        });
        return newState;
      }
      keys.forEach((key, index) => {
        if (index !== keys.length - 1) {
          cntNode[key] = cntNode[key] || {};
          cntNode = cntNode[key];
        } else {
          cntNode[key] = value;
        }
      });
      return newState;
    }

    return state;
  },

  // 计时
  timer: (state = initialState.timer, action) => {
    if (action.type === realSetTimer.toString()) {
      const { value } = action.payload;
      return value;
    }

    return state;
  },

  // 继续模考时使用，存储音频播放的index
  audioRecord: (state = initialState.audioRecord, action) => {
    if (action.type === realSetAudioRecord.toString()) {
      const { value } = action.payload;
      return value;
    }
    return state
  },

  // 设置做题步骤
  stepList: createHandleAction(
    setStepList,
    realSetStepList,
    initialState.stepList,
  ),

  // 练习
  report: createHandleAction(
    getReport,
    realGetReport,
    initialState.report,
  ),

  // 入口页 获取练习列表
  entrance: createHandleAction(
    getExerciseList,
    realGetExerciseList,
    initialState.entrance,
  ),

};
