import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, throttle } from 'lodash';
import { countWords } from 'utils';
import { View, Textarea, Image, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import Article from 'components/article';
import { Modal } from '@zhike/ti-component';
import styles from './styles';

// 阅读题目
export default class WirtingQuestion extends Component {
  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      count: 0,
      showScope: false,
      textLimit: get(props.step, 'practice.textLimit') || 500,
    };
    this.isShowLimitModal = false;
    this.throttleFunc = throttle(this.save.bind(this), 1000);
  }

  // 模块加载
  componentDidMount() {
    this.initAnswer(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { step, params } = nextProps;
    const { step: oldStep } = this.props;
    const { mode } = params;
    // 模考会切换part,切换part后需要更新textArea
    if (mode === 'mock') {
      const oldPracticeId = get(oldStep, 'practiceId');
      const newPracticeId = get(step, 'practiceId');
      if (oldPracticeId !== newPracticeId) {
        this.initAnswer(nextProps);
        this.setState({
          textLimit: get(step, 'practice.textLimit') || 500,
        })
      }
    }
  }

  onHide = () => {
    this.isShowLimitModal = false;
  }

  onShow = () => {
    this.isShowLimitModal = true;
  }

  limitPrompt = () => {
    const { textLimit } = this.state;
    Modal.show('ModalAlert', {
      title: '字数超限无法提交',
      buttons: [{ title: 'OK' }],
      width: 400,
      component: (
        <View style={{ alignItems: 'center' }}>
          <Image style={{ height: 90 }} src={require('components/assets/limit.png')} />
          <View style={{ marginTop: 20 }}>
            字数超过限制，最多{textLimit}个单词
          </View>
        </View>
      ),
    }, this.onShow, this.onHide);
  }

  // next function
  async nextFun() {
    const { count, textLimit } = this.state;
    if (count > textLimit) {
      Modal.show('ModalAlert', {
        title: '字数超限无法提交',
        buttons: [{ title: '好的' }],
        width: 400,
        component: (
          <View style={{ alignItems: 'center' }}>
            <Image style={{ height: 90 }} src={require('components/assets/default.png')} />
            <View style={{ marginTop: 20 }}>
              提交之前需要保证字数符合要求哦～
            </View>
          </View>
        ),
      });
      return false;
    }
    await this.save();
    Header.next();
    return true;
  }

  // 初始化答案
  initAnswer(props) {
    const { newSetRecord, step, params } = props;
    const { mode } = params;
    const materialId = get(step, 'practice.questions.0.materials.0.id');
    const answer = materialId && newSetRecord[materialId] && newSetRecord[materialId].answer || '';
    let showButtons = ['correct', 'submit'];
    let onClickNext = async () => {
      await this.nextFun();
    };
    if (mode === 'mock') {
      showButtons = ['submit'];
      onClickNext = () => {
        Modal.show('ModalAlert', {
          title: '提示',
          buttons: [
            {
              title: 'Submit',
              onClick: async () => {
                await this.nextFun();
              },
            },
            {
              title: 'Cancel',
              class: 'gray',
            },
          ],
          width: 400,
          component: (
            <View className={styles.modalCenter}>
              <View className={styles.p}>Do you determine to submit all your answers?</View>
              <View className={styles.p}>You WILL NOT be able to go back to this test once you click Submit button.</View>
            </View>
          ),
        });
      };
    }
    Header.config({
      showButtons,
      isShowTime: true,
      onClickNext,
    });

    this.setState({
      text: answer,
      count: countWords(answer),
    });
  }

  // 监听文本变化
  handleTextChange(e) {
    const { textLimit } = this.state;
    const count = countWords(e.target.value);
    // 限制字数弹窗已经打开
    if (this.isShowLimitModal) {
      return
    }
    // 字数超过限定字数，弹出提示弹窗，不保存
    if (count > textLimit) {
      this.limitPrompt();
      return
    }
    this.setState({
      text: e.target.value,
      count: countWords(e.target.value),
    }, this.throttleFunc);
  }

  // 保存答案
  async save() {
    const { text, textLimit } = this.state;
    const { newSetStepRecord, step } = this.props;
    const questionMaterialId = get(step, 'practice.questions.0.materials.0.id');
    const questionId = get(step, 'practice.questions.0.id');
    const questionType = get(step, 'practice.questions.0.type');
    if (countWords(text) <= textLimit) {
      await newSetStepRecord('answer', text, questionMaterialId, questionId, questionType);
    }
  }

  // 图片预览
  onImagePreview = src => {
    Modal.show('ModalPreview', {
      src,
      hideHeader: true,
    });
  }

  toggleScope = e => {
    this.setState({
      showScope: e.type === 'mouseenter',
    });
  }

  // 渲染
  render() {
    const { step } = this.props;
    const { text, count, showScope, textLimit } = this.state;
    const question = get(step, 'practice.questions.0') || {};
    const direction = get(question, 'materials.0.direction'); // 指导语
    const imageMaterial = get(question, 'materials.0.image'); // 写作材料图片
    const stem = get(question, 'stem'); // 题目
    return (
      <Scrollbar className={styles.container}>
        <View className={styles.articleWrapper}>
          <View className={styles.article}>
            {
              direction && JSON.stringify(direction) !== '{}' &&
              get(direction, 'paragraphs') &&
              get(direction, 'paragraphs').length !== 0 &&
              <View className={styles.material}>
                <View className={styles.title}>Direction:</View>
                <Article material={direction} />
              </View>
            }
            {
              stem &&
              <View className={styles.material}>
                <View className={styles.title}>Question:</View>
                <Article material={stem} />
              </View>
            }
            {
              imageMaterial && imageMaterial.src &&
              <View
                className={styles.imageForWriting}
                onClick={() => { this.onImagePreview(imageMaterial.src); }}
                onMouseEnter={this.toggleScope}
                onMouseLeave={this.toggleScope}
              >
                <Image
                  src={imageMaterial.src}
                  className={styles.pic}
                />
                {
                  showScope &&
                  <Image
                    src={require('../../assets/scope.png')}
                    className={styles.scope}
                  />
                }
              </View>
            }
          </View>
        </View>
        <View className={styles.editorWrapper}>
          <View className={styles.editorToolbar}>
            <View className={styles.editorToolbarCount}>
              Word Count: {count}
            </View>
          </View>
          <Textarea
            ref={textarea => { this.textarea = textarea; }}
            className={styles.editorTextarea}
            value={text}
            onChange={e => this.handleTextChange(e)}
            placeholder={`You can only enter a maximum of ${textLimit} words.`}
          />
        </View>
      </Scrollbar>
    );
  }
}
