/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import styles from './styles';

export default class TextSegment extends Component {

  render() {
    const { segments, isSelected, onClickSentence } = this.props;

    return (
      <View className={styles.answerTextContent}>
        <div
          style={{
            fontSize: '15.875px',
            height: '540px',
            overflow: 'auto',
          }}
        >
          {segments.map((segment, idx) => {
            if (segment.type === 'text') {
              return <span key={idx}>{segment.content}</span>;
            }

            const isExcellent = segment.type === 'good';
            const segmentType = isExcellent ? 'good' : 'bad';
            const borderColor = isExcellent ? '#15c39a' : '#ffc940';
            const bgColor = isExcellent ? '#edfaef' : '#fff5e0';
            const emoji = isExcellent ? '👍' : '💬';

            return (
              <span
                key={idx}
                id={`sentence-${segmentType}-${segment.index}`}
                onClick={() => onClickSentence(`${segmentType}_${segment.index}`)}
                style={{
                  color: 'black',
                  borderBottom: `2px solid ${borderColor}`,
                  backgroundColor: isSelected === `${segmentType}_${segment.index}` ? bgColor : 'transparent',
                  cursor: 'pointer',
                }}
              >
                {`${segment.content}${emoji}`}
              </span>
            );
          })}
        </div>
      </View>
    );
  }
}
