import React, { Component } from 'react';
import axios from 'axios';
import { createPromise } from 'utils/action';
import PropTypes from 'prop-types';
import Header from 'components/header';
import { View, Image, Button, Scrollbar } from '@zhike/ti-ui';
import { Recorder, Modal, AudioPlayer } from '@zhike/ti-component';
import styles from './styles';
import testSpeakingAudio from './assets/speaking_test.mp3';

// 口语测试
export default class SpeakingTest extends Component {
  // 参数
  static propTypes = {
    params: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
      recorderStatus: 'pending',
      recordUrl: '',
      needRecorder: true,
      time: 0,
      uploadStatus: 'default',
    };

    this.formData = undefined;
    this.signature = undefined;
    this.isSilenceUpload = false;
    this.play = null;
  }

  // 模块即将加载
  componentDidMount() {
    const localinfo = localStorage.getItem('needRecorder');
    const needRecorder = localinfo ? localinfo.split(',')[0] : this.state.needRecorder;
    const RecorderDate = localinfo ? localinfo.split(',')[1] : 0;
    const date = new Date();
    const Nowdate = date.getFullYear().toString() + date.getMonth() + date.getDate();
    Header.config({
      showButtons: ['volume', 'next'],
      isShowTime: false,
      onClickNext: () => {
        // 没有测试过  测试时间大于一天就需要测试
        if (needRecorder && ((Nowdate - RecorderDate) >= 1)) {
          Modal.show('ModalAlert', {
            title: '提示',
            buttons: [{ title: 'OK' }],
            width: 400,
            component: (
              <View className={styles.modalCenter}>
                完成录音测试后再继续哦~
              </View>
            ),
          });
        } else {
          Header.next();
        }
      },
    });
    Header.cleanTimer();
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
    Recorder.destroy();
  }

  // 开始录音
  startRecord() {
    const date = new Date();
    const Nowdate = date.getFullYear().toString() + date.getMonth() + date.getDate();
    localStorage.setItem('needRecorder', [false, Nowdate]);
    this.setState({
      recorderStatus: 'start',
      isPlaying: false,
    });
    Header.config({
      showButtons: ['volume', 'next'],
      onClickContinue: undefined,
    });

    AudioPlayer.unload();
    Recorder.start({
      callback: () => {
        this.setState({
          time: this.state.time + 1000,
        });
      },
    });
  }

  // 结束录音
  stopRecord() {
    this.setState({
      recorderStatus: 'stop',
    });

    Recorder.stop().then(data => {
      this.upload({
        duration: parseInt(this.state.time / 1000, 10),
        file: data.blob,
      }).catch(error => {
        this.upload({ error });
      });
    }).catch(err => {
      console.log('停止录音失败:', err);
    });
  }

  async upload(data) {
    console.log('data.file.Blob:', data, data.file.Blob);
    const { getUploadSignature } = this.props;
    this.setState({
      uploadStatus: 'uploading',
    });

    let audio;
    if (data.file || data.error) {
      try {
        if (data.error) {
          this.isSilenceUpload = true;
          throw data.error;
        } else if (this.formData) {
          this.signature = await createPromise(getUploadSignature, {
            business: 'exercise/speaking',
            fileName: `${data.duration}.webm`,
          });

          const audioData = await axios({
            url: this.signature.data.uploadAddress,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: this.formData,
            timeout: 30000,
          });

          audio = { src: audioData.data.data.transcodeUrl, duration: data.duration };
        } else {
          this.signature = await createPromise(getUploadSignature, {
            business: 'exercise/speaking',
            fileName: `${data.duration}.webm`,
          });

          this.formData = new FormData();
          this.formData.append('key', this.signature.data.key);
          this.formData.append('policy', this.signature.data.policy);
          this.formData.append('OSSAccessKeyId', this.signature.data.accessKeyId);
          this.formData.append('signature', this.signature.data.signature);
          this.formData.append('callback', this.signature.data.callback);
          this.formData.append('file', data.file);
          const audioData = await axios({
            url: this.signature.data.uploadAddress,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: this.formData,
            timeout: 30000,
          });

          audio = { src: audioData.data.data.transcodeUrl, duration: data.duration };
        }
      } catch (e) {
        if (this.isSilenceUpload) {
          this.setState({
            uploadStatus: 'fail',
          });
        } else {
          this.isSilenceUpload = true;
          setTimeout(() => {
            this.upload(data);
          }, 2000);
        }
        return;
      }
    }
    this.formData = undefined;
    this.signature = undefined;
    this.setState({
      uploadStatus: 'success',
      recordUrl: audio.src,
      time: audio.duration * 1000,
    });
  }


  // 播放录音
  playRecord() {
    const { recordUrl } = this.state;
    this.stopPlayAudio();
    this.setState({
      recorderStatus: 'play',
    });

    AudioPlayer.play({
      src: recordUrl,
      onEnd: () => this.cancelPlay(),
    });
  }

  // 取消播放
  cancelPlay() {
    this.setState({
      recorderStatus: 'stop',
    });

    AudioPlayer.unload();
  }

  // 播放音频
  playAudio() {
    if (this.state.recorderStatus === 'play') {
      this.cancelPlay();
    } else if (this.state.recorderStatus === 'start') {
      this.stopRecord();
    }
    this.setState({
      isPlaying: true,
    });

    AudioPlayer.play({
      src: testSpeakingAudio,
      onEnd: () => {
        this.setState({
          isPlaying: false,
        });
      },
    });
  }

  // 停止播放音频
  stopPlayAudio() {
    AudioPlayer.unload();
    this.setState({
      isPlaying: false,
    });
  }

  // 渲染
  render() {
    const { isPlaying, recorderStatus, uploadStatus } = this.state;

    return (
      <Scrollbar className={styles.container}>
        <Image className={styles.model} src={require('./assets/model.png')} />
        <View className={styles.title}>耳机和麦克风测试</View>

        <View className={styles.cards}>
          <View className={styles.card}>
            {
              !isPlaying &&
              <Image className={styles.icon} src={require('./assets/volume.png')} />
            }

            {
              isPlaying &&
              <Image className={styles.icon} src={require('./assets/volume.gif')} />
            }

            {
              !isPlaying &&
              <Button
                className={styles.button}
                text="耳机测试"
                onClick={() => this.playAudio()}
              />
            }

            {
              isPlaying &&
              <Button
                className={styles.button}
                text="取消播放"
                onClick={() => this.stopPlayAudio()}
                theme="hollow"
              />
            }

            <View className={styles.p}>点击按钮进行“耳机测试”</View>
            <View className={styles.p}>点击右上角的Volume调节音量</View>
            <View className={styles.p}>或直接调节电脑音量</View>
          </View>

          <View className={styles.card}>
            {
              (
                recorderStatus === 'pending' ||
                recorderStatus === 'stop'
              ) &&
              <Image className={styles.icon} src={require('./assets/recorder.png')} />
            }

            {
              recorderStatus === 'start' &&
              <Image className={styles.icon} src={require('./assets/recorder.gif')} />
            }

            {
              recorderStatus === 'play' &&
              <Image className={styles.icon} src={require('./assets/volume.gif')} />
            }

            {
              recorderStatus === 'pending' &&
              <Button
                className={styles.button}
                text="录音测试"
                onClick={() => this.startRecord()}
              />
            }

            {
              recorderStatus === 'start' &&
              <Button
                className={styles.button}
                text="结束录音"
                onClick={() => this.stopRecord()}
                theme="hollow"
              />
            }
            { uploadStatus === 'fail' && <View className={styles.uploadStatus}>录音上传失败</View> }

            { uploadStatus === 'uploading' && <View className={styles.uploadStatus}>录音上传中....</View> }

            {
              recorderStatus === 'stop' &&
              <View className={styles.buttons}>
                <Button
                  className={[styles.button, styles.buttonSmall]}
                  text="播放录音"
                  onClick={() => this.playRecord()}
                />
                <Button
                  className={[styles.button, styles.buttonSmall]}
                  text="重新录音"
                  onClick={() => this.startRecord()}
                  theme="hollow"
                />
              </View>
            }

            {
              recorderStatus === 'play' &&
              <View className={styles.buttons}>
                <Button
                  className={[styles.button, styles.buttonSmall]}
                  text="取消播放"
                  onClick={() => this.cancelPlay()}
                  theme="hollow"
                />
                <Button
                  className={[styles.button, styles.buttonSmall]}
                  text="重新录音"
                  onClick={() => this.startRecord()}
                  theme="hollow"
                />
              </View>
            }

            <View className={styles.p}>出现“请求授权”时</View>
            <View className={styles.p}>请点击“允许”</View>
            <View className={styles.p}>再次点击“录音测试”</View>
          </View>
        </View>
      </Scrollbar>
    );
  }
}
