import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: 1024,
    paddingBottom: 30,
    margin: '0 auto',
    alignItems: 'center',
  },

  model: {
    width: 140,
    height: 140,
    marginTop: 40,
  },

  title: {
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 'bold',
  },

  cards: {
    flexDirection: 'row',
    marginLeft: -60,
  },

  card: {
    alignItems: 'center',
    width: 200,
    height: 230,
    marginTop: 40,
    marginLeft: 60,
    paddingTop: 35,
    border: '1px solid #DCE6EC',
    borderRadius: 4,
  },

  icon: {
    marginLeft: 6,
    width: 40,
    height: 40,
  },

  buttons: {
    flexDirection: 'row',
    marginLeft: -10,
  },

  button: {
    marginTop: 10,
    marginBottom: 22,
  },

  buttonSmall: {
    width: 70,
    marginLeft: 10,
  },

  p: {
    color: '#878F98',
    fontSize: 12,
    marginTop: 3,
    textAlign: 'center',
  },

  modalCenter: {
    textAlign: 'center',
  },

  uploadStatus: {
    color: '#878F98',
    fontSize: 12,
  },
});
