import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import styles from './styles';

class Sidebar extends Component {
  render() {
    const { list } = this.props
    return (
      <View className={styles.sidebar}>
        <h3>错误类型统计</h3>
        <View className={styles.sidebarContent}>
          {Object.entries(list.reduce((acc, item) => {
            if (item.weak_type_chinese) {
              acc[item.weak_type_chinese] = (acc[item.weak_type_chinese] || 0) + 1;
            }
            return acc;
          }, {})).map(([type, count]) => (
            <div key={type}>
              <View className={styles.types}>{type}: {count}</View>
              <View className={styles.progressbar}>
                <View className={styles.activebar} style={{ width: `${(count / list.length) * 100}%` }} />
              </View>
            </div>
          ))}
        </View>
      </View>
    )
  }
}

export default Sidebar;
