import React, { Component } from 'react';
import Correct from 'components/report/correct';

// 做题
export default class CorrectContainer extends Component {
  render() {
    return (
      <Correct {...this.props} />
    );
  }
}
