import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'cookie';
import { saWrapper } from '@zhike/sa-hoc-wrapper';
import * as apis from 'common/apis';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { history } from 'routers';
import { View } from '@zhike/ti-ui';
import { createPromise, redirectToLoginPage } from 'utils/action';
import HeaderContainer from 'containers/header';
import Header from 'components/header';
import Footer from 'components/footer';
import MockFooter from 'components/footer/mockFooter';
import exerciseMap from 'utils/exerciseMap';
import { getExerciseContent, getFooter } from 'utils/mock';
import { getQueryObject } from 'utils';
import { defaultProperties } from 'utils/saProperties';
import styles from './styles';

// 做题
@saWrapper({
  eventName: 'practice',
})
export default class Exercise extends Component {
  // 参数
  static propTypes = {
    practice: PropTypes.object.isRequired,
    stepList: PropTypes.object.isRequired,
    record: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setRecord: PropTypes.func.isRequired,
    getUploadSignature: PropTypes.func.isRequired,
    postMachinePigai: PropTypes.func.isRequired,
    postAnswer: PropTypes.func.isRequired,
    getAnswer: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      currentMockPracticeId: null,
    };
  }
  // 即将加载
  componentWillMount() {
    const { practice, stepList, match } = this.props;
    const { mode, practiceId } = match.params;
    // TODO 需要复原完整的访问路径   请求接口失败
    if (
      get(practice, 'status') !== 'SUCCESS' ||
      get(stepList, 'status') !== 'SUCCESS'
    ) {
      global.location.href = `/init/${mode}/${practiceId}`;
    }
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.match.params.stepId !== nextProps.match.params.stepId) {
      this.setState({
        currentMockPracticeId: null,
      });
      await this.updateVisitedMark(nextProps)
    }
  }

  async componentDidMount() {
    await this.updateVisitedMark(this.props)
  }

  // 获取神策打点属性
  getTrackTimerProperties() {
    const { match, practice } = this.props;
    const { mode, practiceId, exerciseId } = match.params;
    const { subjectIds, from } = getQueryObject();
    const saProperties = {};
    saProperties.is_logged_in = true;
    saProperties.subject_ids = subjectIds || '';
    saProperties.practice_name = (() => {
      // 如果是模考展示练习的 name
      if (mode === 'mock') {
        return get(practice, 'data.name', '');
      }
      if (mode === 'question') {
        return get(practice, 'data.questionBackup.questions[0].name', '');
      }
      return get(practice, 'data.questionBackup.name', '');
    })();
    saProperties.practice_id = practiceId;
    saProperties.textbook_id = get(practice, 'data.textbookId', '');
    saProperties.exercise_id = exerciseId;
    saProperties.learn_type = mode;
    saProperties.practice_package_id = get(practice, 'data.practicePackageId', '');
    saProperties.from_platform = from || '智课网';
    saProperties.practice_type_id = get(practice, 'data.questionBackup.practiceTypeId', '');
    saProperties.topic_id = get(practice, 'data.questionBackup.topicId', '');
    return Object.assign(defaultProperties, saProperties);
  }

  // 如果是模考，在props中存储用户的stepId。更新访问标记
  async updateVisitedMark(props) {
    const { stepId, mode } = props.match.params;
    if (mode === 'mock') {
      await this.setRecord(['lastStep'], stepId || 1, 'lastStep');
    }
  }

  // 获取所有答案
  getAllAnswer() {
    const { record, practice, match } = this.props;
    const { stepId } = match.params;
    const answerArr = [];
    const questionArray = get(practice, 'data.questions');
    Array.isArray(questionArray) && questionArray.map(question => {
      const answerType = get(question, 'type');
      const questionId = get(question, 'id');
      Array.isArray(question.materials) && question.materials.map(material => {
        const questionMaterialId = get(material, 'id');
        // 根据题型获取对应的答案
        const userAnswer = record.newStep && record.newStep[stepId] &&
          record.newStep[stepId][questionMaterialId] &&
          record.newStep[stepId][questionMaterialId].answer;
        if (userAnswer) {
          answerArr.push({
            answerType,
            questionId,
            userAnswers: [{
              questionMaterialId,
              userAnswer,
            }],
          });
        }
        return false;
      });
      return false;
    });
    return answerArr;
  }

  async setAudioRecord(value) {
    const { setAudioRecord } = this.props;
    const { mode, exerciseId } = this.props.match.params;
    await setAudioRecord({
      exerciseId,
      value,
    })
  }

  // 设置做题记录
  async setRecord(keys, value, practiceId) {
    const { setRecord } = this.props;
    const { mode, exerciseId } = this.props.match.params;
    await setRecord({
      mode,
      practiceId,
      id: exerciseId,
      keys,
      value,
    });
  }

  // 设置当前步骤的做题记录和保存答案
  async newSetStepRecord(key, value, questionMaterialId, questionId, questionType,
    step, isPost = true, practiceId, mode) {
    try {
      if (key === 'selected') {
        await this.setRecord(['newStep', step.id, key], value, practiceId);
      } else if (key === 'isReview') {
        await this.setRecord(['newStep', step.id, questionMaterialId, key], value, practiceId);
      } else {
        await this.setRecord(['newStep', step.id, questionMaterialId, key], value, practiceId);
      }

      if (key === 'answer' && isPost) {
        // 当做题类型不是口语和写作的时候记录答案的状态
        if (['Speaking', 'Writing'].indexOf(questionType) === -1) {
          let isVisited = false;
          Array.isArray(value) && value.forEach(item => {
            if (item || item === 0) {
              isVisited = true;
            }
          });
          await this.setRecord(['newStep', step.id, questionMaterialId, 'isVisited'], isVisited, practiceId);
        } else if (['Speaking'].indexOf(questionType) !== -1) {
          let isVisited = false;
          if (value && value.src) {
            isVisited = true;
          }
          await this.setRecord(['newStep', step.id, questionMaterialId, 'isVisited'], isVisited, practiceId);
        }
        if (mode === 'mock') {
          if (['Writing'].indexOf(questionType) !== -1) {
            const isVisited = !!value;
            await this.setRecord(['newStep', step.id, questionMaterialId, 'isVisited'], isVisited, practiceId);
          }
        }
        await this.setRecord(['newStep', step.id, 'selected'], questionMaterialId, practiceId);
        const { postAnswer } = this.props;
        const { exerciseId } = this.props.match.params;
        const answer = await createPromise(postAnswer, {
          exerciseId: parseInt(exerciseId, 10),
          practiceId: step.practice.id,
          duration: parseInt(Header.getTime() / 1000, 10),
          subjectId: step.practice.subjectId,
          answers: [{
            questionId,
            answerType: questionType,
            userAnswers: [{ questionMaterialId, userAnswer: value }],
          }],
          time: Date.now(), // 用户保存的时间,用来确定后台保存的是用户最后的答案
        });
        await this.setRecord(['newStep', step.id, questionMaterialId, 'answerId'], answer.data.records[0].answerId, practiceId);
      }
    } catch (e) {
      console.log(e);
      setTimeout(() => {
        history.push(`/error/save/${encodeURIComponent(global.location.pathname)}`);
      }, 100);
    }
  }

  // 雅思模考切换练习
  handleChangePractice = (practiceId, cb = () => 0) => {
    this.setState({
      currentMockPracticeId: practiceId,
    }, cb);
  }

  // 提交测试
  async submit(step) {
    const { exerciseId, mode } = this.props.match.params;
    const { postAnswer } = this.props;
    const search = global.location.search; // eslint-disable-line
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const taskId = decodeURIComponent(search.match(new RegExp('[?#&]task_id=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    // 获取用户token
    // 优先采用url的token
    let token;
    if (tokenUrl) {
      token = tokenUrl;
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        token = user.token; // eslint-disable-line
      } catch (e) {
        console.log(e, 'token');
        redirectToLoginPage();
        return;
      }
    }
    if (mode !== 'mock') {
      // 提交答案时，将答案进行整合再进行提交
      if (step.practice.subjectId === 5 || step.practice.subjectId === 6) {
        const answers = this.getAllAnswer();
        await createPromise(postAnswer, {
          exerciseId: parseInt(exerciseId, 10),
          practiceId: step.practice.id,
          duration: parseInt(Header.getTime() / 1000, 10),
          subjectId: step.practice.subjectId,
          answers,
        });
      }
    }
    await axios({
      url: apis.postComplete[1],
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      data: {
        duration: parseInt(Header.getTime() / 1000, 10),
        exerciseId: parseInt(exerciseId, 10),
        token,
        source,
        taskId: taskId || null,
      },
    });
  }

  // 渲染
  render() {
    const { currentMockPracticeId } = this.state;
    const { practice, stepList, getUploadSignature, postMachinePigai, record } = this.props;
    const { operation, stepId, mode } = this.props.match.params;
    const { step, name, newSetRecord } = getExerciseContent({
      practice,
      stepList,
      record,
      stepId: +stepId,
      mode,
      currentMockPracticeId,
    });
    const RenderComponent = exerciseMap[step.type];
    const RenderFooter = mode === 'mock' ? MockFooter : Footer;
    const isShowFooter = getFooter(mode, step.type);
    // 生成参数信息
    const renderProps = {
      params: this.props.match.params,
      stepList: stepList.data,
      setRecord: (keys, value) => this.setRecord(keys, value),
      setAudioRecord: value => this.setAudioRecord(value),
      newSetStepRecord: (key, value, questionMaterialId, questionId, questionType, isPost) =>
        this.newSetStepRecord(key, value, questionMaterialId, questionId, questionType, step, isPost, step.practiceId, mode),
      newSetRecord,
      step,
      record,
      getUploadSignature,
      postMachinePigai,
      practice,
      name,
      submit: () => { this.submit(step); },
      practiceTypeId: practice.data.practiceTypeId,
      handleChangePractice: (practiceId, cb) => this.handleChangePractice(practiceId, cb),
    };
    // 渲染
    return (
      <View className={styles.container}>
        {
          RenderComponent &&
          <HeaderContainer {...renderProps} />
        }
        <View className={styles.children}>
          <div ref={anchor => { this.anchor = anchor; }} />
          {
            !operation && RenderComponent &&
            <RenderComponent {...renderProps} />
          }
        </View>
        {
          isShowFooter &&
          <RenderFooter
            {...renderProps}
            ref={footer => { Footer.instance = footer; }}
          />
        }
      </View>
    );
  }
}
