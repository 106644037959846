import { take, fork } from 'redux-saga/effects';
import { request } from 'utils/action';
import * as apis from 'common/apis';
import * as actions from 'actions/logo';

// 发起请求获取获取站点logo
export const watchGetLogo = function* watchGetLogo() {
  while (true) {
    const { payload } = yield take(actions.getLogo);
    yield fork(
      request,
      actions.realGetLogo,
      apis.getLogo,
      payload,
    );
  }
};
