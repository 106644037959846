/**
 * exercise 组件映射Map
 */
import ListeningTest from 'components/listening/listening_test';
import ListeningDirections from 'components/listening/listening_directions';
import ListeningSectionDirections from 'components/listening/listening_section_directions';
import ListeningQuestion from 'components/listening/listening_question';
import ListeningPlayer from 'components/listening/listening_player';
import ListeningEnding from 'components/listening/listening_ending';
import ReadingDirections from 'components/reading/reading_directions';
import ReadingQuestion from 'components/reading/reading_question';
import ReadingEnding from 'components/reading/reading_ending';
import SpeakingTest from 'components/speaking/speaking_test';
import SpeakingDirections from 'components/speaking/speaking_directions';
import SpeakingQuestion from 'components/speaking/speaking_question';
import WritingDirections from 'components/writing/writing_directions';
import WritingQuestion from 'components/writing/writing_question';
import WritingEnding from 'components/writing/writing_ending';
import GeneralDirections from 'components/mock/general_directions';
import GeneralEnding from 'components/mock/general_ending';
import Rest from 'components/mock/rest';
import End from 'components/end';

export default {
  ListeningTest,
  ListeningDirections,
  ListeningQuestion,
  ListeningPlayer,
  ListeningEnding,
  ReadingDirections,
  ReadingQuestion,
  ReadingEnding,
  SpeakingTest,
  SpeakingDirections,
  SpeakingQuestion,
  WritingDirections,
  WritingQuestion,
  WritingEnding,
  ListeningSectionDirections,
  GeneralEnding,
  GeneralDirections,
  Rest,
  End,
};
