import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  scanContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: 'black',
    zIndex: 99,
  },

  scanLine: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: 'rgba(0, 150, 255, 0.6)',
    boxShadow: '0 0 4px rgba(0, 150, 255, 0.8)',
    transition: 'top 16ms linear',
    zIndex: 999,
  },

});
