import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { Modal } from '@zhike/ti-component';
import { View } from '@zhike/ti-ui';
import Header from 'components/header';
import Question from 'components/question';
import styles from './styles';

// 口语题目
export default class SpeakingQuestion extends Component {
  // 参数
  static propTypes = {
    params: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    getUploadSignature: PropTypes.func.isRequired,
  };
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      questionIndex: 0,
    };
  }

  // 模块加载
  componentDidMount() {
    this.setHeader(this.props);
  }

  // 模块接受参数
  componentWillReceiveProps(nextProps) {
    this.setHeader(nextProps);
  }

  // 设置头部
  setHeader(props) {
    const { step, newSetRecord, newSetStepRecord, params } = props;
    const { mode } = params;
    const isMock = mode === 'mock';
    const selectedId = newSetRecord.selected;
    const index = findIndex(step.questionMaterialIds, item => item.questionMaterialId === selectedId);
    if (!isMock) {
      if (selectedId) {
        const showButtons = ['correct'];
        if ((index + 1) === step.questionMaterialIds.length) {
          showButtons.push('submit');
        } else {
          showButtons.push('questionNext');
        }
        const unavailableButtons = [];
        this.setState({ questionIndex: index });
        Header.config({
          showButtons,
          unavailableButtons,
          isShowTime: true,
        });
      } else {
        const questionMaterialId = step.questionMaterialIds[0] && step.questionMaterialIds[0].questionMaterialId || 1;
        newSetStepRecord('selected', questionMaterialId);
      }
    }
    if (isMock) {
      if (selectedId) {
        const showButtons = ['submit'];
        const onClickNext = () => {
          Modal.show('ModalAlert', {
            title: '提示',
            buttons: [
              {
                title: 'Submit',
                onClick: () => {
                  Header.next();
                },
              },
              {
                title: 'Cancel',
                class: 'gray',
              },
            ],
            width: 400,
            component: (
              <View className={styles.modalCenter}>
                <View className={styles.p}>Do you determine to submit all your answers?</View>
                <View className={styles.p}>You WILL NOT be able to go back to this test once you click Submit button.</View>
              </View>
            ),
          });
        };
        Header.config({
          showButtons,
          isShowTime: true,
          isCountDown: true,
          onClickNext,
        });
        this.setState({ questionIndex: index });
      } else {
        const questionMaterialId = step.questionMaterialIds[0] && step.questionMaterialIds[0].questionMaterialId || 1;
        newSetStepRecord('selected', questionMaterialId);
      }
    }
  }
  // 渲染
  render() {
    const { step, newSetRecord, newSetStepRecord, getUploadSignature, params } = this.props;
    const { questions } = step.practice || {};
    const renderProps = {
      newSetStepRecord,
      newSetRecord,
      step,
      getUploadSignature,
      params,
    };
    return (
      <View className={styles.questionWrapper}>
        {
          Array.isArray(questions) && questions[this.state.questionIndex] &&
          <Question question={questions[this.state.questionIndex]} questionIndex={0} {...renderProps} />
        }
      </View>);
  }
}
