import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: 1100,
    margin: '0 auto',
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 40,
    color: '#32363a',
  },

  p: {
    display: 'block',
    marginTop: 8,
    fontSize: 14,
    lineHeight: '24px',
  },

  strong: {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 'bold',
  },

  textAlign: {
    marginTop: 40,
    textAlign: 'center',
  },

  button: {
    margin: '0 auto',
    marginTop: 20,
    width: 120,
    height: 40,
    lineHeight: '40px',
  },

  text: {
    fontSize: 14,
  },

  mt10: {
    marginTop: '10px',
  },

  mt8: {
    marginTop: '8px',
  },
});
