import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#32363A',
  },
  head: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '18px',
  },
  bar: {
    width: '4px',
    height: '18px',
    background: '#3399FF',
    marginRight: '5px',
    borderRadius: '100px',
  },
  title: {
    fontWeight: 'bold',
  },

  main: {
    background: '#F6F8F9',
    padding: '20px',
  },
  loading: {
    color: '#3399FF',
  },
  noteItem: {
    marginBottom: '20px',
    ':last-child': {
      marginBottom: '0',
    },
  },
  modalCenter: {
    textAlign: 'center',
    margin: '20px 0',
  },

  viewNote: {
    borderBottom: '1px solid #DCE6EC',
  },
  content: {
    fontSize: '16px',
    lineHeight: '22px',
    marginBottom: '12px',
    whiteSpace: 'pre-wrap',
  },
  note: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  time: {
    color: '#878F98',
  },
  handleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  handle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '20px',
  },
  handleText: {
    color: '#878F98',
    marginLeft: '4px',
  },

  editNote: {
    position: 'relative',
  },
  textarea: {
    width: '100%',
    height: '150px',
    border: '1px solid #DCE6EC',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#32363A',
    padding: '12px',
    paddingBottom: '32px',
    marginBottom: '20px',
    '::-webkit-input-placeholder': {
      color: '#878F98',
    },
  },
  counter: {
    position: 'absolute',
    top: 118,
    right: 20,
    color: '#C3CCD1',
  },
  red: {
    color: '#FD5454',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cancel: {
    padding: '0 28px',
    color: '#C3CCD1',
  },
  save: {
    width: '80px',
  },

  addNote: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  noNote: {
    flex: 1,
  },
  addIcon: {
    height: '20px',
    width: '20px',
    marginRight: '5px',
  },
  addText: {
    color: '#3399FF',
  },
});
