import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactEcharts from 'echarts-for-react';
import { View } from '@zhike/ti-ui';
import styles from './styles';

moment.locale('zh-cn');

// 报告
export default class Header extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
    subjectId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { subjectId } = this.props;
    global.document.title = `${subjectId === '8' ? '口语批改' : '写作批改'} - 智课`;
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  generateTop = () => {
    const { machinePigaiSubmitTime } = this.props.correctData;
    const search = global.location.search; // eslint-disable-line
    const title = decodeURIComponent(search.match(new RegExp('[?#&]title=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let { machinePigaiScore } = this.props.correctData;
    machinePigaiScore = this.handleScore(machinePigaiScore);

    let style = {
      backgroundImage: `url(${require('./assets/grade_c.png')})`,
      color: '#DCDCDC',
      textShadow: '0 2px 4px #505050',
    };
    if (machinePigaiScore >= 21) {
      style = {
        backgroundImage: `url(${require('./assets/grade_a.png')})`,
        color: '#F5D5A8',
        textShadow: '0 2px 4px #9A7136',
      };
    }
    if (machinePigaiScore <= 20 && machinePigaiScore >= 11) {
      style = {
        backgroundImage: `url(${require('./assets/grade_b.png')})`,
        color: '#E3EDEC',
        textShadow: '0 2px 4px #4F6788',
      };
    }
    return (
      <View className={styles.top}>
        <View className={styles.title}>{title}</View>
        <View className={styles.time}>{moment(machinePigaiSubmitTime).format('YYYY.MM.DD')}</View>
        <View className={styles.score} style={style}>{machinePigaiScore} </View>
      </View>
    );
  }

  generateComment(title, content, color) {
    return (
      <View className={styles.comment}>
        <View className={styles.commentTitle} style={{ backgroundColor: color }}>{title}</View>
        <View>{content}</View>
      </View>
    );
  }

  generateContent = () => {
    const { subjectId, correctData } = this.props;
    const { machinePigaiResult } = correctData;
    if (subjectId === '7') {
      const { comments, radar } = machinePigaiResult;
      const { grammaticalRangeAndAccuracy, lexicalResources, coherenceCohesion, taskResponse } = radar;
      const option = {
        radar: {
          shape: 'circle',
          silent: true,
          splitNumber: 10,
          splitArea: {
            areaStyle: { color: ['#fff'] },
          },
          startAngle: 45,
          name: {
            color: '#505050',
          },
          indicator: [
            { name: `Grammatical Range \n and Accuracy ${this.handleScore(grammaticalRangeAndAccuracy, 9)}`, max: 9 },
            { name: `Lexical Resources ${this.handleScore(lexicalResources, 9)}`, max: 9 },
            { name: `Cohrence & \n Cohesion ${this.handleScore(coherenceCohesion, 9)}`, max: 9 },
            { name: `Task Response ${this.handleScore(taskResponse, 9)}`, max: 9 },
          ],
        },
        series: [{
          type: 'radar',
          data: [
            {
              value: [
                this.handleScore(grammaticalRangeAndAccuracy, 9),
                this.handleScore(lexicalResources, 9),
                this.handleScore(coherenceCohesion, 9),
                this.handleScore(taskResponse, 9),
              ],
              name: '预算分配（Allocated Budget）',
              areaStyle: {
                normal: {
                  opacity: 0.5,
                  color: '#3399ff',
                },
              },
              symbol: 'none',
              lineStyle: {
                normal: {
                  opacity: 0.5,
                  color: '#3399ff',
                },
              },
            },
          ],
        }],
      };
      return (
        <View className={styles.commentBox}>
          {this.generateComment('综合评价', comments.map((item, index) => <View className={styles.p} key={index}>• {item}</View>), '#FC6238')}
          {this.generateComment('雷达图', <ReactEcharts option={option} style={{ height: '344px', width: '472px', margin: '0 auto' }} />, '#3399ff')}
        </View>);
    }
    if (subjectId === '8') {
      const { all } = machinePigaiResult;
      return (
        <View className={styles.commentBox}>
          {this.generateComment('综合评价', all.comment.comment.map((item, index) => <View className={styles.p} key={index}>• {item}</View>), '#FC6238')}
          {this.generateComment('提升建议', all.comment.advice.map((item, index) => <View className={styles.p} key={index}>• {item}</View>), '#86CE2F')}
        </View>);
    }
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateTop()}
        {this.generateContent()}
      </View>
    );
  }
}
