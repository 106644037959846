import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cookie from 'cookie';
import axios from 'axios';
import { View, Image, Textarea, Button } from '@zhike/ti-ui';
import { Modal } from '@zhike/ti-component';
import { addNote, editNote, delNote, getNote } from 'common/apis';
import { redirectToLoginPage } from 'utils/action';
import styles from './styles';

// 阅读题目
export default class Notes extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.noteCacheDict = {}; // 按questionId缓存笔记，切换的时候不用每次都加载
    this.maxLength = 500; // 笔记的最大字数
    this.state = {
      noteList: [],
      loading: false,
      uploading: false,
      editingNote: undefined,
      textareaValue: '',
    };
  }

  componentDidMount() {
    this.updateNote();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      this.noteCacheDict[prevProps.question.id] = this.state.noteList;
      this.updateNote();
    }
  }

  getToken = () => {
    const pathname = global.location.pathname; // eslint-disable-line
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let token;

    if (tokenUrl) {
      token = tokenUrl;
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        token = user.token; // eslint-disable-line
      } catch (e) {
        console.log(e);
        redirectToLoginPage();
        return;
      }
    }

    return token;
  }

  timeFormat = time => {
    const date = new Date(time).toLocaleDateString()
      .replace(/\//g, '.');
    // toLocalString的时间显示并不是很合适，所以干脆自己取了
    const hour = new Date(time).getHours();
    const min = new Date(time).getMinutes();
    const comp = num => (num >= 10 ? num : `0${num}`);

    return `${date} ${comp(hour)}:${comp(min)}`;
  }

  // 更新笔记列表
  updateNote = async () => {
    const noteCache = this.noteCacheDict[this.props.question.id];
    if (noteCache) {
      this.setState({ noteList: noteCache });
      return;
    }

    try {
      await this.setState({ noteList: [], loading: true });
      const { id: refId, subjectId, examId } = this.props.question;
      const token = this.getToken();

      const result = await axios({
        url: getNote[1],
        method: getNote[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        params: {
          token,
          refId,
          examId,
          subjectId,
          type: 'Question',
        },
      });

      if (result.data.code === 0) {
        const noteList = result.data.data.sort((a, b) => a.id - b.id);

        await this.setState({
          noteList,
          loading: false,
        });
      } else {
        await this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  }

  // 添加笔记时根据笔记的结构构造新的笔记，方便在交互上与编辑笔记统一
  startEdit = note => {
    if (note) { // 编辑笔记
      this.setState(prevState => ({
        editingNote: note,
        textareaValue: note.content,
        // 之前正在新建笔记时，要先把列表中的项目删掉
        noteList: prevState.editingNote && !prevState.editingNote.id
          ? prevState.noteList.slice(0, -1)
          : prevState.noteList,
      }));
    } else { // 添加笔记
      const { id: refId, subjectId, examId } = this.props.question;
      const note = {
        refId,
        examId,
        subjectId,
        content: '',
        type: 'Question',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      };

      this.setState(prevState => ({
        noteList: prevState.noteList.concat([note]),
        editingNote: note,
        textareaValue: '',
      }));
    }
  }

  confirmDelete = note => {
    Modal.show('ModalAlert', {
      title: '提示',
      buttons: [
        {
          title: '取消',
          class: 'gray',
        },
        {
          title: '确定',
          onClick: () => this.delNote(note),
        },
      ],
      width: 400,
      component: (
        <View className={styles.modalCenter}>确定删除此条笔记吗?</View>
      ),
    });
  }

  handleInput = e => {
    this.setState({
      textareaValue: e.target.value,
    });
  }

  cancelEdit = () => {
    if (this.state.editingNote.id) { // 编辑模式
      this.setState({
        textareaValue: '',
        editingNote: undefined,
      });
    } else { // 新建模式
      this.setState(prevState => ({
        textareaValue: '',
        editingNote: undefined,
        noteList: prevState.noteList.slice(0, -1),
      }));
    }
  }

  saveNote = () => {
    const { editingNote, textareaValue } = this.state;

    if (!textareaValue) { // 这里就不用trim()了，用户爱保存啥保存啥
      return;
    }

    if (editingNote.id) {
      this.editNote();
    } else {
      this.addNote();
    }
  }

  addNote = async () => {
    try {
      await this.setState({ uploading: true });
      const { editingNote, textareaValue } = this.state;
      const { refId, type } = editingNote;
      const token = this.getToken();

      const result = await axios({
        url: addNote[1],
        method: addNote[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        data: {
          token,
          refId,
          type,
          content: textareaValue,
        },
      });

      if (result.data.code === 0) {
        const note = {
          ...editingNote,
          id: result.data.data.id,
          content: textareaValue,
          updated_at: new Date().toISOString(),
        };
        await this.setState(prevState => ({
          uploading: false,
          textareaValue: '',
          editingNote: undefined,
          noteList: prevState.noteList.slice(0, -1).concat([note]),
        }));
      } else {
        await this.setState({ uploading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ uploading: false });
    }
  }

  editNote = async () => {
    try {
      await this.setState({ uploading: true });
      const { editingNote, textareaValue } = this.state;
      const token = this.getToken();

      const result = await axios({
        url: editNote[1],
        method: editNote[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        data: {
          token,
          id: editingNote.id,
          content: textareaValue,
        },
      });

      if (result.data.code === 0) {
        editingNote.content = textareaValue;
        editingNote.updated_at = new Date().toISOString();

        await this.setState({
          uploading: false,
          textareaValue: '',
          editingNote: undefined,
        });
      } else {
        await this.setState({ uploading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ uploading: false });
    }
  }

  delNote = async note => {
    try {
      await this.setState({ uploading: true });
      const token = this.getToken();

      const result = await axios({
        url: delNote[1],
        method: delNote[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        data: {
          token,
          id: note.id,
        },
      });

      if (result.data.code === 0) {
        await this.setState(prevState => ({
          uploading: false,
          noteList: prevState.noteList.filter(item => item.id !== note.id),
        }));
      } else {
        await this.setState({ uploading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ uploading: false });
    }
  }

  render() {
    const { loading, uploading, noteList, editingNote, textareaValue } = this.state;

    return (
      <View className={styles.container}>
        <View className={styles.head}>
          <View className={styles.bar} />
          <View className={styles.title}>我的笔记</View>
        </View>

        <View className={styles.main}>
          {
            loading ? (
              <View className={styles.loading}>笔记正在加载中...</View>
            ) : (
              <View>
                {
                  noteList.map(item => (
                    editingNote === item ? (
                      <View key={item.updated_at} className={[styles.noteItem, styles.editNote]}>
                        <View
                          className={[
                            styles.counter,
                            textareaValue.length >= this.maxLength && styles.red,
                          ]}
                        >
                          {textareaValue.length}/{this.maxLength}
                        </View>
                        <Textarea
                          autoFocus
                          defaultValue={item.content}
                          disabled={uploading}
                          className={styles.textarea}
                          placeholder="Type here"
                          maxLength={this.maxLength}
                          onChange={this.handleInput}
                        />
                        <View className={styles.buttons}>
                          <View className={[styles.cancel, styles.pointer]} onClick={() => !uploading && this.cancelEdit()}>取消</View>
                          <Button className={styles.save} text="保存" onClick={this.saveNote} loading={uploading} isAvailable={!!textareaValue} />
                        </View>
                      </View>
                    ) : (
                      <View key={item.updated_at} className={[styles.noteItem, styles.viewNote]}>
                        <View className={styles.content}>{item.content}</View>
                        <View className={styles.note}>
                          <View className={styles.time}>{this.timeFormat(item.updated_at)}</View>
                          <View className={styles.handleContainer}>
                            <View className={[styles.handle, styles.pointer]} onClick={() => !uploading && this.startEdit(item)}>
                              <Image width={20} height={20} src={require('components/report/assets/edit.png')} />
                              <View className={styles.handleText}>编辑</View>
                            </View>
                            <View className={[styles.handle, styles.pointer]} onClick={() => !uploading && this.confirmDelete(item)}>
                              <Image width={20} height={20} src={require('components/report/assets/del.png')} />
                              <View className={styles.handleText}>删除</View>
                            </View>
                          </View>
                        </View>
                      </View>
                    )
                  ))
                }
                {
                  noteList.length < 10 && !editingNote &&
                  <View className={styles.addNote}>
                    <View className={styles.noNote}>{noteList.length === 0 && '你尚未添加笔记哦~'}</View>
                    <View className={[styles.addNote, styles.pointer]} onClick={() => !uploading && this.startEdit()}>
                      <Image className={styles.addIcon} src={require('components/report/assets/add_note.png')} />
                      <View className={styles.addText}>添加笔记</View>
                    </View>
                  </View>
                }
              </View>
            )
          }
        </View>
      </View>
    );
  }
}
