import { keyBy, merge, map, get, sumBy, flatMap, meanBy } from 'lodash';

function getQuestionCorrectNums(materials) {
  return materials.reduce((acc, material) => {
    const { qNum, userAnswer = {}, answer } = material;
    const isMulti = get(qNum, 'length') > 1;
    const correctNum = isMulti ? qNum.map((x, index) => {
      return get(userAnswer, ['answer', index]) === get(answer, index);
    }).filter(Boolean).length : Number(userAnswer.correct || 0);
    return acc + correctNum;
  }, 0);
}

function getQuestionNums(materials) {
  return flatMap(materials, 'qNum').length;
}

// 根据正确题的数量计算雅思的总分
function getScoreByCorrectCount(count) {
  const map = new Map([
    [40, 9],
    [38, 8.5],
    [36, 8],
    [34, 7.5],
    [32, 7.0],
    [29, 6.5],
    [26, 6],
    [22, 5.5],
    [19, 5],
    [15, 4.5],
    [12, 4],
    [9, 3.5],
    [5, 3],
    [3, 2.5],
    [2, 2],
    [1, 1],
    [0, 0],
  ]);
  let result;
  for (const score of map.keys()) {
    if (count <= score) {
      result = map.get(score);
    }
  }
  return result;
}

// 处理模考报告数据
export const handleMockReport = report => {
  const stepList = [];
  const { subjects, practices } = report;
  const questions = flatMap(practices, p => p.questions);
  const questionsById = keyBy(questions, 'id');
  let practiceNum = 0;
  for (let i = 0; i < subjects.length; i += 1) {
    subjects[i].practices = subjects[i].practicesInfo;
    const { questionTags } = subjects[i];
    const questionTagsByName = keyBy(questionTags, 'questionTag');
    // 清空服务器端的错误数据
    if (subjects[i].subjectId === 5 || subjects[i].subjectId === 6) {
      for (const tag of questionTags) {
        tag.correctNum = 0;
        tag.questionNum = 0;
      }
    }
    const subjectPractices = subjects[i].practicesInfo;
    for (let k = 0; k < subjectPractices.length; k += 1) {
      // const practice = filter(practices, { id: subjectPractices[k] })[0];
      const practice = subjectPractices[k];
      practiceNum += 1;
      practice.questions = practice.questions || practice.questionCheckout;
      const { questions } = practice;
      for (let j = 0; j < questions.length; j += 1) {
        const question = questions[j];
        const targetQuestion = questionsById[question.id];
        merge(question, targetQuestion);
        question.materials = question.materials || question.materialsCheckout;
        question.correctNum = getQuestionCorrectNums(question.materials);
        question.questionNum = getQuestionNums(question.materials);
        const materialId = question.materials[0].id;
        const materialIds = map(question.materials, x => x.id);
        if (practice.subjectId === 7 || practice.subjectId === 8) {
          const { userAnswer } = question.materials[0];
          const score = get(userAnswer, 'machinePigaiResult.all.score', (userAnswer && userAnswer.machinePigaiScore) || 0);
          // if (userAnswer && userAnswer.machinePigaiStatus !== 'Marked') {
          //   score = NaN
          // }
          question.machinePigaiScore = score;
          question.aiPigaiScore = get(userAnswer, 'aiPigaiResult.all.score', 0);
          stepList.push({
            subjectId: practice.subjectId,
            section: k + 1,
            practiceNum,
            questionId: question.id,
            practiceId: practice.id,
            materialId,
            materialIds,
            answerId: userAnswer && userAnswer.id,
            machinePigaiResult: userAnswer && userAnswer.machinePigaiResult,
            machinePigaiStatus: userAnswer && userAnswer.machinePigaiStatus,
            machinePigaiScore: score,
            manualPigaiStatus: userAnswer && userAnswer.manualPigaiStatus,
            aiPigaiStatus: userAnswer && userAnswer.aiPigaiStatus,
            aiPigaiScore: question.aiPigaiScore,
            aiPigaiResult: userAnswer && userAnswer.aiPigaiResult,
          });
        } else {
          if (question.questionTag) {
            questionTagsByName[question.questionTag].correctNum += question.correctNum;
            questionTagsByName[question.questionTag].questionNum += question.questionNum;
          }
          stepList.push({
            subjectId: practice.subjectId,
            section: k + 1,
            materialId,
            materialIds,
            practiceNum,
            questionId: question.id,
            practiceId: practice.id,
          });
        }
      }
      practice.machinePigaiScore = Math.round(meanBy(questions, 'machinePigaiScore') * 2) / 2;
      practice.aiPigaiScore = Math.round(meanBy(questions, 'aiPigaiScore') * 2) / 2;
    }
    if (subjects[i].subjectId === 5 || subjects[i].subjectId === 6) {
      // 全部答题数 40
      // 根据正确答题数目给分
      subjects[i].score = getScoreByCorrectCount(sumBy(subjectPractices, 'correctNum'));
    } else {
      // 已被废弃掉
      subjects[i].machinePigaiScore = Math.round(meanBy(subjectPractices, 'machinePigaiScore') * 2) / 2;
    }
  }
  return stepList;
};

// 处理练习报告数据
export const handleExerciseReport = report => {
  const stepList = [];
  const practice = report;
  practice.questions.forEach(question => {
    if (question.subjectId === 3 || question.subjectId === 4) {
      const { userAnswer } = question.materials[0];
      stepList.push({
        subjectId: practice.subjectId,
        section: 1,
        questionId: question.id,
        practiceId: practice.id,
        manualPigaiStatus: userAnswer && userAnswer.manualPigaiStatus,
      });
    } else {
      stepList.push({
        subjectId: practice.subjectId,
        section: 1,
        questionId: question.id,
        practiceId: practice.id,
      });
    }
  });

  return stepList;
};

// 处理报告数据
export const getStepListForMock = (mode, report) => {
  let stepList = [];
  if (mode === 'mock') {
    stepList = handleMockReport(report);
  } else {
    // stepList = handleExerciseReport(report);
  }
  return stepList;
};
