import { StyleSheet } from 'aphrodite';

// loading动画
const rotating = {
  '0%': {
    transform: 'rotate(0)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
};

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  left: {
    flex: 1,
    height: '100%',
    backgroundColor: 'rgba(246,248,249,1)',
  },
  questionsTab: {
    alignItems: 'flex-end',
  },
  leftContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  rigth: {
    flex: 1,
    height: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
    paddingRight: 20,
  },
  title: {
    flexDirection: 'row',
    height: 18,
    lineHeight: '18px',
    fontSize: 14,
    fontWeight: 600,
    '::before': {
      content: '""',
      marginRight: 5,
      width: 4,
      height: 18,
      borderRadius: 100,
      backgroundColor: '#3399ff',
    },
  },
  titleBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  audioContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '40px 0 20px',
  },
  audioButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  audioButton1: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    backgroundImage: `url(${require('./assets/up_blue.png')})`,
    backgroundSize: '90% 90%',
  },
  audioButton2: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    backgroundImage: `url(${require('./assets/down_blue.png')})`,
    backgroundSize: '90% 90%',
    backgroundPosition: '50% 50%',
  },
  pigaiText: {
    fontSize: '14px',
    fontFamily: 'PingFangSC-Regular',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#3399FF',
  },
  text: {
    fontSize: '16px',
    fontFamily: 'PingFangSC-Semibold',
    color: 'rgba(50,54,58,1)',
    lineHeight: '22px',
    marginTop: '40px',
  },
  article: {
    width: 510,
    height: '100%',
    marginRight: '40px',
    fontSize: '16px',
    fontFamily: 'PingFangSC-Regular',
    color: 'rgba(50,54,58,1)',
    lineHeight: '24px',
    paddingBottom: '40px',

    '@media (max-width: 1024px)': {
      width: 430,
    },

    '@media (max-width: 1366px)': {
      paddingLeft: 20,
    },
  },
  content: {
    width: 550,
    paddingRight: 0,
    paddingBottom: '40px',
    '@media (max-width: 1024px)': {
      width: 490,
    },
  },
  practice: {
    position: 'absolute',
    zIndex: 20,
    top: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    padding: 16,
    width: 100,
    boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.14)',
    borderRadius: 3,
    background: 'rgba(255,255,255,1)',
    display: 'flex',
    alignItems: 'center',
    '::before': {
      position: 'absolute',
      top: -9,
      left: 40,
      zIndex: 20,
      content: '""',
      width: 0,
      height: 0,
      borderBottom: '9px solid rgba(255,255,255,1)',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
    },
  },
  practiceText: {
    fontSize: 12,
    fontFamily: 'PingFang-SC-Medium',
    fontWeight: 500,
    color: 'rgba(135,143,152,1)',
    lineHeight: '17px',
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      color: 'rgba(51,153,255,1)',
      cursor: 'pointer',
    },
    paddingBottom: 0,
    paddingTop: 12,
    ':first-child': {
      paddingTop: 0,
    },
  },
  practiceText1: {
    display: 'inline-block',
    borderBottom: '1px solid rgba(220,230,236,1)',
    paddingBottom: 12,
    paddingTop: 0,
  },
  practiceImg: {
    display: 'none',
    marginLeft: '4px',
    width: '12px',
    height: '12px',
  },
  practiceImgShow: {
    display: 'inline-block',
    marginLeft: '4px',
    width: '12px',
    height: '12px',
  },
  practiceBodyWrapper: {
    position: 'fixed',
    zIndex: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  buttonWhite: {
    marginLeft: 10,
    color: '#32363A',
    backgroundColor: '#fff',
    border: 'solid 1px #878f98',
    ':hover': {
      backgroundColor: '#f4f6f7',
    },
  },
  modalAlert: {
    alignItems: 'center',
  },

  modalAlertImage: {
    width: 90,
    height: 90,
    marginTop: 20,
  },

  modalAlertText: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 40,
  },
  pigaiImage: {
    animationName: [rotating],
    animationDuration: '1.2s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
  },
  title1: {
    margin: '40px 0 20px',
  },
});
