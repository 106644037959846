/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import { Audio } from '@zhike/ti-component';
import SuggestionList from '../pigai/suggestion_list';
import TextSegment from '../pigai/text_segment';
import styles from './styles';

export default class Suggestion extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
    };
  }

  handleClickSentence = (index) => {
    this.setState({ isSelected: this.state.isSelected === index ? null : index });
  };

  scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  handleClickSuggestion = (type, index) => {
    this.setState({
      isSelected: `${type}_${index}`
    }, () => {
      // Scroll both elements into view
      this.scrollToElement(`suggestion-${type}-${index}`);
      this.scrollToElement(`sentence-${type}-${index}`);
    });
  };

  processSegments = () => {
    const { aiPigaiSTT, aiPigaiCompositonAnalysis } = this.props;

    if (!Array.isArray(aiPigaiCompositonAnalysis) || aiPigaiCompositonAnalysis.length === 0) {
      return [{ type: 'text', content: aiPigaiSTT }];
    }

    const segments = [];

    aiPigaiCompositonAnalysis.forEach((item, index) => {
      const normalTextStartIndex = index > 0 
        ? aiPigaiCompositonAnalysis[index - 1].endIndex + 1
        : 0;
        
      if (item.startIndex > normalTextStartIndex) {
        segments.push({
          type: 'text',
          content: aiPigaiSTT.substring(normalTextStartIndex, item.startIndex),
        });
      }

      segments.push({
        type: item.type,
        content: item.sentence,
        index: index,
      });
    });

    const lastItem = aiPigaiCompositonAnalysis[aiPigaiCompositonAnalysis.length - 1];

    if (lastItem.endIndex  < aiPigaiSTT.length) {
      segments.push({
        type: 'text',
        content: aiPigaiSTT.substring(lastItem.endIndex + 1),
      });
    }

    return segments;
  };

  render() {
    const { answerAudio, aiPigaiSTT, aiPigaiCompositonAnalysis } = this.props;
    const segments = this.processSegments();
    const { isSelected } = this.state;

    return (
      <View className={styles.pigaiContainer}>
        <View className={styles.audioBox}>
          <h3>原始音频</h3>
          <View className={styles.audioBoxHeader}>
            <Audio src={answerAudio.src} />
          </View>
          <View className={styles.audioText}>
            <TextSegment
              segments={segments}
              isSelected={isSelected}
              onClickSentence={this.handleClickSentence}
            />
          </View>
        </View>
        <View className={styles.suggestionContainer}>
          <SuggestionList
            isSelected={isSelected}
            list={aiPigaiCompositonAnalysis}
            onClickSentence={this.handleClickSuggestion}
          />
        </View>
      </View>
    );
  }
}
