import { StyleSheet } from 'aphrodite/no-important';
import { getBodyWidth } from 'utils';

const blueLine = {
  width: 20,
  height: 3,
  backgroundImage: 'linear-gradient(-269deg, #385DAE 0%, #4A7DED 99%)',
  boxShadow: '0 2px 12px 0 rgba(73,123,233,0.50)',
  borderRadius: '1.5px',
};

const tipRight = getBodyWidth() - 1400 < 20 ? 10 : (getBodyWidth() - 1400) / 2;

export default StyleSheet.create({
  container: {
    backgroundColor: '#f6f8f9',
  },

  whiteBox: {
    paddingTop: 40,
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 10px 30px 0 rgba(56,93,174,0.06)',
  },

  colorFF8E04: {
    color: '#FF8E04',
  },

  color385DAE: {
    color: '#385DAE',
  },

  opacity5: {
    opacity: '.5',
  },

  paddingBottom0: {
    paddingBottom: 0,
  },
  // 大标题公共样式
  bigTitle: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  smallCircle: {
    marginLeft: 6,
    marginRight: 6,
    width: 9,
    height: 9,
    backgroundColor: '#385DAE',
    borderRadius: '50%',
  },

  bigCircle: {
    width: 16,
    height: 16,
    backgroundColor: '#385DAE',
    borderRadius: '50%',
  },

  bigTitleText: {
    marginLeft: 10,
    marginRight: 10,
    fontSize: 24,
    lineHeight: '32px',
  },
  // 头部
  header: {
    position: 'relative',
    paddingTop: 70,
    height: 400,
    backgroundImage: `url(${require('./assets/headerBg.jpg')})`,
    backgroundSize: '1920px 400px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    textAlign: 'center',
  },

  titleLogo: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: 56,
    height: 26,
  },
  hyLogo: {
    position: 'absolute',
    top: 20,
    left: 20,
    height: 56,
  },

  headerText: {
    marginTop: 5,
    color: '#FFFFFF',
    lineHeight: '22px',
    opacity: '.5',
    ':first-child': {
      marginTop: 0,
      fontSize: 40,
      fontWeight: 600,
      lineHeight: '56px',
      opacity: '1',
    },
  },

  content: {
    marginTop: -240,
    width: 1000,
    alignSelf: 'center',
  },
  // 分数
  score: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
    marginTop: 72,
    paddingTop: 0,
    width: 1000,
    height: 300,
  },

  scoreLeft: {
    marginLeft: 111,
    marginRight: 119,
    width: 194,
    height: 300,
  },

  scoreTrophy: {
    width: 194,
    height: 194,
  },

  scoreNum: {
    marginTop: -50,
    fontSize: 40,
    lineHeight: '40px',
    color: '#45404C',
    fontWeight: '600',
    textAlign: 'center',
    '::after': {
      content: '""',
      margin: '0 auto',
      marginTop: 16,
      ...blueLine,
    },
  },

  scoreText: {
    marginTop: 11,
    fontSize: 14,
    color: '#818C96',
    lineHeight: '20px',
    textAlign: 'center',
  },

  scoreLine: {
    marginTop: 40,
    width: 1,
    height: 220,
    backgroundColor: '#E7EBED',
  },

  scoreRight: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: 300,
  },

  scoreItem: {
    flexDirection: 'row',
    marginLeft: 118,
    marginTop: 40,
    paddingTop: 20,
    width: 130,
    height: 70,
    fontSize: 14,
    color: '#818C96',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: '26px',
  },

  scoreText1: {
    position: 'relative',
    width: '60px',
    '::after': {
      content: '""',
      position: 'absolute',
      top: 24,
      right: 0,
      ...blueLine,
    },
  },

  scoreNum1: {
    marginLeft: 18,
    fontSize: 32,
    lineHeight: '32px',
    color: '#45404C',
    fontWeight: '600',
  },

  scoreNum2: {
    fontSize: 20,
    lineHeight: '20px',
  },
  // tip
  questionTip: {
    position: 'absolute',
    top: 24,
    right: 24,
    width: 20,
    height: 20,
    backgroundImage: `url(${require('./assets/question_gray.png')})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
  },

  questionTipSmall: {
    top: 6,
    right: 'none',
    left: 108,
    width: 12,
    height: 12,
  },

  tipContent: {
    position: 'absolute',
    top: 32,
    left: -94,
    display: 'none',
    padding: '12px 16px',
    fontSize: 12,
    color: '#29323A',
    lineHeight: '22px',
    width: 210,
    backgroundColor: '#fff',
    boxShadow: '0 1px 10px 0 rgba(195,204,209,0.50)',
    borderRadius: '4px',
    textAlign: 'left',
  },

  tipContentSmall: {
    top: -6,
    left: 24,
  },

  tipTitle: {
    marginBottom: 8,
    fontSize: 14,
    textAlign: 'center',
  },

  triangleUp: {
    position: 'absolute',
    top: -8,
    left: 100,
    width: 0,
    height: 0,
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderBottom: '8px solid #fff',
  },

  triangleLeft: {
    position: 'absolute',
    top: 8,
    left: -8,
    width: 0,
    height: 0,
    borderTop: '4px solid transparent',
    borderRight: '8px solid #fff',
    borderBottom: '4px solid transparent',
  },

  block: {
    display: 'block',
  },
  // tab
  tab: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 40,
    marginBottom: 40,
    padding: '0 80px',
    height: 56,
  },

  tabItem: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 40,
    width: 180,
    height: 56,
    lineHeight: '56px',
    backgroundColor: '#fff',
    borderRadius: '28.5px',
    textAlign: 'center',
    cursor: 'pointer',
    ':first-child': {
      marginLeft: 0,
    },
  },

  tabItemS: {
    color: '#fff',
    backgroundImage: 'linear-gradient(90deg, #385DAE 1%, #4A7DED 100%)',
  },

  tabImage: {
    marginTop: 16,
    marginLeft: 38,
    marginRight: 10,
    width: 24,
    height: 24,
  },

  scrollTabBox: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 80,
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px 0 rgba(0,0,0,0.07)',
    zIndex: 2,
  },

  scrollTab: {
    flex: 1,
    flexDirection: 'row',
    margin: '0 auto',
    padding: '0 80px',
    width: 1000,
    alignItems: 'center',
  },
  // 数据统计
  data: {
    // height: 530,
  },

  dataContent: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 26,
    padding: '0 0 20px 60px',
  },

  dataLine: {
    marginBottom: 78,
    width: 1,
    backgroundColor: '#E7EBED',
  },

  dataTitle: {
    position: 'relative',
    fontSize: 18,
    color: '#32363A',
    '::after': {
      content: '""',
      position: 'absolute',
      top: 34,
      left: 0,
      ...blueLine,
      width: 33,
      height: 6,
      borderRadius: '3px',
    },
  },

  dataLeft: {
    width: 440,
  },

  dataLeftContent: {
    marginTop: 70,
    height: 'calc(100% - 94px)',
  },

  marginLeft80: {
    marginLeft: 80,
  },

  cylindricityContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 'calc(100% - 64px)',
  },

  nameImage: {
    marginTop: 4,
    marginBottom: 20,
    width: 440,
    height: 40,
  },

  dataRight: {
    paddingLeft: 68,
    width: 440,
  },

  dataRightContent: {
    flexDirection: 'row',
    marginTop: 70,
    fontSize: 12,
  },

  marginTop90: {
    marginTop: 90,
  },

  dataRightText: {
    marginTop: 20,
    height: 16,
    ':first-child': {
      marginTop: 0,
    },
  },

  dataRightblocks: {
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 18,
    marginRight: 10,
    height: 16,
    lineHeight: '16px',
    alignItems: 'center',
    ':first-child': {
      marginTop: 0,
    },
    ':last-child': {
      marginBottom: 40,
      marginTop: 14,
    },
  },

  blockItem: {
    marginLeft: 4,
    width: 40,
    height: 10,
    backgroundColor: '#E7EAF1',
    ':first-child': {
      marginLeft: 0,
    },
  },

  dataRightTip: {
    marginLeft: 4,
    width: 40,
    color: '#878F98',
    textAlign: 'center',
    ':first-child': {
      marginLeft: 0,
    },
  },

  color1: {
    opacity: 0.2,
    backgroundColor: '#FF8E04',
  },

  color2: {
    opacity: 0.4,
    backgroundColor: '#FF8E04',
  },

  color3: {
    opacity: 0.6,
    backgroundColor: '#FF8E04',
  },

  color4: {
    opacity: 0.8,
    backgroundColor: '#FF8E04',
  },

  color5: {
    opacity: 1,
    backgroundColor: '#FF8E04',
  },

  cylindricityBox: {
    marginLeft: 20,
    width: 50,
    fontSize: 12,
    textAlign: 'center',
    ':first-child': {
      marginLeft: 0,
    },
  },

  cylindricity: {
    position: 'relative',
    margin: '0 auto',
    marginTop: 10,
    marginBottom: 10,
    minHeight: 200,
    width: 16,
    height: 'calc(100% - 30px)',
    borderRadius: 8,
    backgroundColor: '#EEF1F4',
  },

  cylindricityGreen: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: 16,
    borderRadius: 8,
    backgroundColor: '#00D2D3',
  },

  cylindricityText: {
    marginTop: 12,
    marginBottom: 20,
    wordWrap: 'break-word',
  },

  materialsCorrect: {
    display: 'flex',
    flexDirection: 'row',
  },
  tag: {
    color: 'rgba(135,143,152,1)',
    fontSize: '14px',
    padding: '0 5px',
    lineHeight: '24px',
    marginLeft: '3px',
    marginRight: '3px',
    border: '1px solid #C3CCD1',
    display: 'inline-block',
    flexShrink: 1,
  },
  // 作答详情
  answerDetail: {
    marginTop: 40,
    padding: 40,
  },
  // questionTage 搜索
  searchBox: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 40,
    width: '100%',
    height: 58,
    borderBottom: '1px solid #DCE6EC',
    flexWrap: 'wrap',
  },

  searchContent: {
    flexDirection: 'row',
    width: 820,
    overflowY: 'auto',
  },

  searchItem: {
    marginLeft: 24,
    fontSize: 12,
    color: '#878F98',
    cursor: 'pointer',
    ':first-child': {
      marginLeft: 0,
    },
  },

  stripBox: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  strip: {
    position: 'relative',
    marginRight: 8,
    marginTop: 2,
    width: 28,
    height: 14,
    borderRadius: '40px',
    backgroundColor: '#c3ccd1',
    cursor: 'pointer',
  },

  stripActive: {
    backgroundColor: '#3399ff',
  },

  round: {
    position: 'absolute',
    top: 1,
    left: 1,
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },

  roundActive: {
    left: 'none',
    right: 1,
  },

  stripText: {
    fontSize: 12,
  },

  title: {
    flex: 1,
    flexDirection: 'row',
    position: 'relative',
    marginTop: 40,
    height: 25,
    lineHeight: '25px',
    fontSize: 18,
    fontWeight: 600,
  },

  topic: {
    marginLeft: 12,
    padding: '0 7px',
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: '#878F98',
    border: '1px solid #C3CCD1',
    borderRadius: '2px',
  },

  questionBox: {
    position: 'relative',
    flexDirection: 'row',
    marginTop: 20,
    paddingRight: 20,
    border: '1px solid #DCE6EC ',
    borderRadius: 3,
    ':hover': {
      backgroundColor: '#fafbfc',
    },
  },

  questionPigaiNum: {
    marginLeft: 'auto',
    marginTop: '1rem',
    marginBottom: '-.5em',
    flex: 1,
    flexDirection: 'row',
    top: 0,
    right: 0,
    fontSize: 16,
    fontWeight: 400,
    color: '#878F98',
  },

  questionNum: {
    flex: 1,
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 16,
    fontWeight: 400,
    color: '#878F98',
  },

  titleMark: {
    marginTop: 11,
    marginBottom: 20,
    width: 33,
    height: 6,
    backgroundImage: 'linear-gradient(-269deg, #385DAE 0%, #4A7DED 99%)',
    boxShadow: '0 2px 12px 0 rgba(73,123,233,0.50)',
    borderRadius: '3px',
  },

  greatBox: {
    width: '100%',
    alignItems: 'center',
    fontSize: 12,
    color: '#878F98',
  },

  greatImg: {
    marginTop: 24,
    marginBottom: 14,
    width: 64,
    height: 64,
  },

  correctQuestionScore: {
    marginLeft: 4,
    color: '#32363A',
    fontWeight: 600,
    width: '4rem',
    textAlign: 'right',
  },

  scoreIcon: {
    marginTop: 6,
    marginRight: 2,
    width: 16,
    height: 16,
  },

  questionContent: {
    display: 'block',
    position: 'relative',
    padding: '20px 0 20px 69px',
    width: 800,
    flex: 1,
  },

  questionContentReading: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    padding: '20px',
    width: 800,
    flex: 1,
  },

  questionContent1: {
    width: 614,
    paddingBottom: 0,
  },

  question: {
    paddingRight: 80,
  },

  answerBox: {
    // flex: 1,
    flexDirection: 'column',
    // marginTop: 16,
    fontSize: 14,
  },

  answer: {
    flexDirection: 'row',
    // marginLeft: 20,
    // marginRight: 20,
    flexWrap: '',
    color: '#878f98',
    marginLeft: 0,
    marginRight: 0,
  },

  answerDefault: {
    paddingLeft: 10,
    fontWeight: 600,
    color: '#32363a',
  },

  negative: {
    paddingLeft: 19,
    borderLeft: '1px solid #dce6ec',
  },

  color49cf51: {
    color: '#49cf51',
  },

  colorfd5454: {
    color: '#fd5454',
  },

  numContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 10px',
    ':not(:last-child)': {
      borderRight: '1px dashed #aaa',
    },
  },

  num: {
    width: '28px',
    height: '28px',
    lineHeight: '28px',
    borderRadius: 3,
    border: 'solid 1px #878f98',
    fontSize: 14,
    textAlign: 'center',
    display: 'flex',
    margin: '0 4px',
    cursor: 'pointer',
  },

  questionIcon: {
    position: 'absolute',
    top: 20,
    left: 20,
    width: 28,
    height: 28,
    backgroundRepeat: 'no-repeat',
    border: '1px solid rgb(135, 143, 152)',

    justifyContent: 'center',
    alignItems: 'center',
    color: '#888',
  },

  button: {
    marginLeft: 10,
    alignSelf: 'center',
  },

  correctBtn: {
    backgroundColor: '#FF8E04',
    ':hover': {
      backgroundColor: '#FF8E04',
      opacity: 0.8,
    },
  },

  modalAlert: {
    alignItems: 'center',
  },

  modalAlertImage: {
    width: 90,
    height: 90,
    marginTop: 20,
  },

  modalAlertText: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 40,
  },

  close: {
    marginRight: 30,
    fontSize: 14,
    width: 120,
    height: 40,
    lineHeight: '40px',
    borderRadius: '3px',
    border: 'solid 1px #878f98',
    textAlign: 'center',
    cursor: 'pointer',
  },

  pay: {
    width: 120,
    height: 40,
    fontSize: 14,
    lineHeight: '40px',
    borderRadius: '3px',
    backgroundColor: '#3399ff',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer',
  },

  footer: {
    padding: '40px 0',
    fontSize: 12,
    lineHeight: '17px',
    color: '#878F98',
    textAlign: 'center',
  },

  // 右侧浮窗
  flootRight: {
    position: 'fixed',
    top: '30%',
    right: tipRight,
    width: 130,
    cursor: 'pointer',
  },

  flootText: {
    position: 'relative',
    width: 130,
    height: 44,
    color: '#3399FF',
    lineHeight: '42px',
    backgroundColor: '#fff',
    borderRadius: 4,
    textAlign: 'center',
  },

  flootTriangle: {
    position: 'absolute',
    left: 60,
    bottom: -7,
    height: 0,
    width: 0,
    borderTop: '7px solid #fff',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
  },

  flootImg: {
    margin: '0 auto',
    marginTop: 10,
    width: 80,
  },
});
