import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },

  questionWrapper: {
    flex: 1,
    backgroundColor: '#F6F8F9',
  },

  questionContent: {
    alignItems: 'flex-end',
  },

  question: {
    flex: 1,
    paddingTop: 40,
    paddingRight: 40,
    paddingBottom: 40,
    paddingLeft: 40,
    width: 550,

    '@media (max-width: 1024px)': {
      width: 500,
    },
  },

  material: {
    marginTop: 40,
    ':first-child': {
      marginTop: 0,
    },
  },

  imageForWriting: {
    position: 'relative',
    lineHeight: 0,
    marginTop: 12,
    alignSelf: 'start',
  },

  pic: {
    width: 500,

    '@media (max-width: 1024px)': {
      width: 430,
    },
  },

  scope: {
    position: 'absolute',
    width: '32px',
    bottom: 0,
    left: 468,
    cursor: 'pointer',
  },

  titleBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  title: {
    marginBottom: 6,
    fontWeight: 'bold',
    lineHeight: '24px',
  },

  articleWrapper: {
    flex: 1,
  },

  article: {
    flex: 1,
    paddingLeft: 40,
    paddingTop: 40,
    paddingBottom: 40,
    paddingRight: 40,
    // width: 550,
  },

  reportArticle: {
  },

});
