import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'row',
  },

  articleWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#F6F8F9',
  },

  article: {
    width: '100%',
    padding: '40px 20px 0 20px',
    overflowY: 'auto',

    // '@media (max-width: 1024px)': {
    //   width: 500,
    // },
  },

  articleContent: {
    width: '550px',
    paddingRight: 40,
    paddingBottom: 40,

    '@media (max-width: 1024px)': {
      width: 500,
    },
  },

  articleTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 15,
    fontWeight: 'bold',
  },

  questionWrapper: {
    flex: 1,
  },

  questions: {
    flex: 1,
    padding: '40px 20px 0 20px',
  },

  question: {
    width: '100%',
    marginTop: 40,
    borderBottom: '1px solid rgba(195,204,209,1)',
    paddingBottom: '40px',
    ':last-child': {
      borderBottom: 'none',
    },
    ':first-child': {
      marginTop: 0,
    },
  },
});
