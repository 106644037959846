export default {
  practice: {},
  record: {},
  timer: {},
  stepList: {},
  report: {},
  entrance: {},
  logo: { data: {} },
  audioRecord: 0,
};
