import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cookie from 'cookie';
import { get } from 'lodash';
import * as apis from 'common/apis';
import { activationUrl } from 'common/config';
import { redirectToLoginPage } from 'utils/action';
import { View, Image } from '@zhike/ti-ui';
import { Modal } from '@zhike/ti-component';
import Header from 'components/report/common/header';
import styles from './styles';

const advantageImges = [
  { src: require('./assets/img1.png'), name: '优秀的教学专家与外教' },
  { src: require('./assets/img2.png'), name: '纯手工批改润色' },
  { src: require('./assets/img3.png'), name: '最快12小时极速批改' },
  { src: require('./assets/img4.png'), name: '真正1对1英文诊断与指导' },
  { src: require('./assets/img5.png'), name: '依据官方标准' },
  { src: require('./assets/img6.png'), name: '独创音频批改' },
];

// 报告
export default class Correct extends Component {
  // 参数
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isError: true,
      btnStatus: '',
      pigaiUrl: '',
      isCompatible: true,
      title: '',
    };
    this.btnLoad = false;
  }

  // 加载
  async componentDidMount() {
    const { subjectId } = this.props.match.params;
    const { search } = this.props.location;
    const answerId = decodeURIComponent(search.match(new RegExp('[?#&]answerId=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const title = decodeURIComponent(search.match(new RegExp('[?#&]title=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    // 检测兼容性
    if (
      global.navigator.userAgent.match(/msie/i) ||
      global.ActiveXObject ||
      ('ActiveXObject') in global
    ) {
      this.setState({
        isCompatible: false,
      });
      return;
    }
    const result = await axios({
      url: apis.getAnswerDetail[1],
      method: apis.getAnswerDetail[0],
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      params: {
        ids: answerId,
      },
    });
    const { manualPigaiStatus, manualPigaiId, examId } = get(result, 'data.data.answers.0.userAnswers.0');
    this.setState({
      btnStatus: manualPigaiStatus === 'Marked' ? 0 :
        (manualPigaiStatus !== 'Uncommitted' ? 'ReportUnFinished' : 'Uncommitted'),
      pigaiUrl: `https://pigai.smartstudy.com/${subjectId === '8' ? 'oral' : 'essay'}/${examId}/report/${manualPigaiId}?nohead=1`,
      isError: false,
      title,
    });
  }

  getToken = () => {
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let token;

    if (tokenUrl) {
      token = tokenUrl;
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        token = user.token; // eslint-disable-line
      } catch (e) {
        console.log(e, 'token');
        redirectToLoginPage();
      }
    }

    return token;
  }

  checkPigai = async () => {
    const { btnStatus, pigaiUrl } = this.state;
    const { subjectId } = this.props.match.params;
    const token = this.getToken();

    if (btnStatus === 0) {
      global.window.location.href = pigaiUrl;
    } else if (btnStatus === 'Uncommitted' && !this.btnLoad) {
      this.btnLoad = true;

      const correctData = await axios({
        url: apis.getPigai[1],
        method: apis.getPigai[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        params: {
          token,
          page: 1,
          examinationId: 2,
          subjectId,
        },
      });

      this.btnLoad = false;

      if (correctData.data.code === 'BadGateway') {
        redirectToLoginPage();
        return;
      } else if (correctData.data.code === 0) {
        // 添加去激活的弹窗 inactiveTimes 未激活的次数
        const { statusTotalInfo } = correctData.data.data;
        const { availbaleTimes, inactiveTimes } = statusTotalInfo;
        if (availbaleTimes > 0) {
          Modal.show('ModalAlert', {
            title: '提示',
            width: 400,
            buttons: [
              { title: '取消', style: styles.close, onClick: undefined, class: 'gray' },
              { title: '继续', style: styles.pay, onClick: this.handlePigai, class: 'alertTip' },
            ],
            component: (
              /* eslint-disable */
              <View className={styles.modalAlert}>
                <View className={styles.modalAlertText}>
                  <View className={styles.modalAlertTextMain}>
                    您将使用<span style={{ color: '#FF534F' }}> 1 </span>次雅思外教批改服务，是否继续？
                  </View>
                  <View className={styles.modalAlertTextTips}>
                    当前可用批改次数:<span style={{ color: '#FF534F' }}> {availbaleTimes} </span>
                  </View>
                </View>
              </View>
              /* eslint-enable */
            ),
            isReport: true,
          });
          return;
        } else if (inactiveTimes > 0 && availbaleTimes === 0) {
          Modal.show('ModalAlert', {
            title: '提示',
            width: 400,
            buttons: [
              { title: '取消', style: styles.close, onClick: undefined, class: 'gray' },
              { title: '去激活', style: styles.pay, onClick: () => global.window.open(activationUrl), class: 'alertTip' },
            ],
            component: (
              /* eslint-disable */
              <View className={styles.modalAlert}>
                <View className={styles.modalAlertText}>
                  <View className={styles.modalAlertTextMain}>
                    你的专家课程尚未开始，开始后批改服务方可使用。
                  </View>
                </View>
              </View>
              /* eslint-enable */
            ),
            isReport: true,
          });
          return;
        }
      }
      this.pigaiShortageAlert()
    }
  }

  handlePigai = async () => {
    const { search } = this.props.location;
    const answerId = decodeURIComponent(search.match(new RegExp('[?#&]answerId=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const token = this.getToken();

    this.btnLoad = true;
    const correctData = await axios({
      url: apis.postManualPigai[1],
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        From: 1,
      },
      data: {
        answerId: answerId * 1,
        token,
      },
    });
    this.btnLoad = false;

    if (correctData.data.code === 'BadGateway') {
      redirectToLoginPage();
    } else if (correctData.data.code === 0) {
      Modal.show('ModalAlert', {
        title: '提交成功',
        width: 400,
        buttons: [
          { title: 'OK', style: styles.pay, onClick: undefined },
        ],
        component: (
          /* eslint-disable */
          <View className={styles.modalAlert}>
            <Image
              className={styles.modalAlertImage}
              src={require('./assets/success.png')}
            />
            <View className={styles.modalAlertText}>
              预计3天后可在学习中心-我的批改中查看结果哦~
            </View>
          </View>
          /* eslint-enable */
        ),
        isReport: true,
      });

      this.setState({
        btnStatus: 'ReportUnFinished',
      });
    } else {
      this.pigaiShortageAlert()
    }
  }

  pigaiShortageAlert = () => {
    const { search } = this.props.location;
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let prompt = '你尚未购买对应批改服务或可用次数已耗尽，请购买后使用'
    let buttons = [
      { title: '取消', onClick: undefined, style: styles.close, class: 'gray' },
      { title: '立即购买', style: styles.pay, onClick: () => global.window.open('https://www.smartstudy.com/toefl/course/1971.html'), class: 'alertTip' },
    ]
    if (source === 'smartInside') {
      prompt = '外教批改次数已用尽'
      buttons = [{ title: '确定', onClick: undefined, style: styles.close }]
    }
    Modal.show('ModalAlert', {
      title: '无法提交批改',
      width: 400,
      buttons,
      component: (
        /* eslint-disable */
        <View className={styles.modalAlert}>
          <Image
            className={styles.modalAlertImage}
            src={require('./assets/deny.png')}
          />
          <View className={styles.modalAlertText}>
            {prompt}
          </View>
        </View>
        /* eslint-enable */
      ),
      isReport: true,
    });
  }

  // 渲染
  render() {
    const { isCompatible, isError, title, btnStatus } = this.state;
    const { subjectId } = this.props.match.params;
    if (subjectId === '7') {
      advantageImges[5] = { src: require('./assets/img7.png'), name: '基于数据挖掘的个性指导' };
      advantageImges[2] = { src: require('./assets/img3.png'), name: '最快24小时极速批改' };
    }
    const renderProps = {
      match: this.props.match,
      title,
      showButtons: [],
      unavailableButtons: [],
    };
    let buttonName = '立即批改';
    switch (btnStatus) {
      case 'Uncommitted':
        buttonName = '立即批改';
        break;
      case 'ReportUnFinished':
        buttonName = '批改中';
        break;
      case 0:
        buttonName = '查看批改报告';
        break;
      default:
        break;
    }
    return (
      <View className={styles.container}>
        {
          !isCompatible &&
          <View>
            <View className={styles.text}>暂不支持当前浏览器...</View>
            <View className={styles.text}>
              <a
                style={{ color: '#fff' }}
                href="http://www.google.cn/chrome/browser/desktop/index.html"
                target="_blank" // eslint-disable-line
              >
                请下载最新版本Chrome浏览器
              </a>
            </View>
          </View>
        }
        {
          isCompatible && isError &&
          <View className={styles.loadingBox}>
            <Image
              className={styles.loading}
              src={require('components/assets/loading.gif')}
            />
            <View className={styles.text}>正在加载数据，请稍候...</View>
          </View>
        }
        {
          isCompatible && !isError &&
          <View>
            <Header {...renderProps} />
            <View className={styles.content}>
              <View className={styles.top}>
                {
                  subjectId === '7' ?
                    <View className={styles.subTitle}>
                    外教1V1逐句纠错  逐段点评<br />
                    改后总评  纠正写作思路<br />
                    教你地道表达
                    </View> :
                    <View className={styles.subTitle}>
                    外教1V1语音批改  根据官方标准准确打分<br />
                    改后点评  提供细致提升方案
                    </View>
                }
                <View
                  className={styles.button}
                  onClick={this.checkPigai}
                >{buttonName}
                </View>
              </View>
              <View>
                <View className={styles.title}>报告样例</View>
                <View className={styles.tip} />
                <Image
                  className={styles.image}
                  src={subjectId === '7' ? require('./assets/writing.jpg') : require('./assets/speaking.jpg')}
                />
              </View>
              <View className={styles.advantage}>
                <View className={styles.title}>我们的优势</View>
                <View className={styles.tip} />
                <View className={styles.advantageImges}>
                  {
                    advantageImges.map((item, index) => (
                      <View className={styles.advantageBox} key={index}>
                        <Image className={styles.advantageImge} src={item.src} />
                        <View className={styles.advantageText}>{item.name}</View>
                      </View>
                    ))
                  }
                </View>
                <View
                  className={styles.button}
                  onClick={this.checkPigai}
                >{buttonName}
                </View>
              </View>
            </View>
            <Modal ref={modal => { Modal.instance = modal; }} />
          </View>
        }
      </View>
    );
  }
}
