import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash'
import { history } from 'routers';
import { View, Image } from '@zhike/ti-ui';
import { smoothScroll } from 'utils/smoothScroll';
import styles from './styles';

// 生成题目索引 组件
export default class RenderIndex extends Component {
  // 参数
  static propTypes = {
    match: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    backgroundType: PropTypes.number,
  };
  // 参数
  static defaultProps = {
    backgroundType: 1, // 背景的类型
  }

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isShowArrows: false, // 是否显示左右箭头
      questionArray: [], // 将题目分组存放
      currentPage: 1, // 当前页面索引
      countPages: 1, // 所有页面数
      isFirst: true, // 首次加载不显示选中
      questionStep: [], // 题号信息
    };
  }


  componentWillMount() {
    // 每次刷新， 需要重新保存一下当前页面
    const { questions } = this.props;
    const questionStep = this.handleQuestionStep(questions);
    let { questionMaterialId } = this.props.match.params;
    if (!questionMaterialId) {
      questionMaterialId = questionStep[0] && questionStep[0].questionMaterialId;
    }
    const question = questionStep && questionStep.filter(item =>
      item.questionMaterialId === parseInt(questionMaterialId, 10))[0];
    const currentPage = question && Math.ceil(question.rank / 12);
    const count = questionStep.length;
    const questionArray = [];
    // 当 题目数量超过14个时，两侧按钮显示，
    if (count > 14) {
      // 将题目进行分组，分多页显示
      const countPages = Math.ceil(count / 12);
      for (let i = 1; i <= countPages; ++i) {  // eslint-disable-line
        const item = questionStep.slice((i - 1) * 12, i * 12);
        questionArray.push(item);
      }
      this.setState({ questionArray, countPages, currentPage });
      const isShowArrows = true;
      this.setState({ isShowArrows });
      return false;
    }
    questionArray.push(questionStep);
    const isShowArrows = false;
    this.setState({ isShowArrows, questionArray, questionStep });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params &&
      this.props.match.params.questionMaterialId !== nextProps.match.params.questionMaterialId) {
      const { questionMaterialId } = nextProps.match.params;
      this.click(questionMaterialId);
    }
  }

  // 处理题号
  handleQuestionStep = questions => {
    const questionMaterialIds = [];
    let rank = 1;
    Array.isArray(questions) && questions.forEach(question => {
      const { materials } = question;
      Array.isArray(materials) && materials.forEach(material => {
        const { qNum, id, userAnswer = {}, answer } = material;
        let isCorrect = material.userAnswer && material.userAnswer.correct;
        if (qNum === null) {
          questionMaterialIds.push({
            qNum: question.rank,
            questionMaterialId: id,
            isCorrect,
            rank,
            type: question.type,
          });
          rank += 1;
        } else {
          const isMulti = qNum.length > 1
          qNum.forEach((item, index) => {
            if (isMulti) {
              const uAnswer = get(userAnswer, ['answer', index])
              isCorrect = (answer || []).includes(uAnswer)
            }
            questionMaterialIds.push({
              qNum: item,
              questionMaterialId: id,
              isCorrect,
              rank,
              type: question.type,
            });
            rank += 1;
          });
        }
      });
    });
    return questionMaterialIds;
  };

  // 点击滑动到 指定题目
  click(questionMaterialId) {
    this.setState({ isFirst: false });
    const anchorElement = global.document.getElementById(questionMaterialId);
    // 如果对应id的锚点存在，就跳转到锚点
    setTimeout(() => {
      if (anchorElement) {
        smoothScroll({
          element: anchorElement,
          position: 'start',
          duration: 500,
        });
      }
    }, 300);
  }

  // 生成题目索引 的函数
  renderIndex = page => {
    const { match } = this.props;
    const { questionArray, isFirst } = this.state;
    const { exerciseId, mode, rankId } = match.params;
    let { questionMaterialId } = match.params;
    if (!questionMaterialId) {
      questionMaterialId = questionArray[0] && questionArray[0].questionMaterialId;
    }
    const search = global.location.search; // eslint-disable-line
    return questionArray && questionArray[page].map((item, index) => {
      console.log(item);
      const className = [styles.num];
      const { isCorrect, type } = item;
      if (isFirst && type === 'Speaking') {
        className.push(styles.numBlack);
      } else if (parseInt(questionMaterialId, 10) === item.questionMaterialId && type === 'Speaking') {
        className.push(styles.numBlackActive);
      } else if (parseInt(questionMaterialId, 10) !== item.questionMaterialId && type === 'Speaking') {
        className.push(styles.numBlack);
      } else if (isFirst && !isCorrect) {
        className.push(styles.numRed);
      } else if (isFirst && isCorrect) {
        className.push(styles.numGreen);
      } else if (parseInt(questionMaterialId, 10) === item.questionMaterialId && parseInt(rankId, 10) === item.rank && !isCorrect) {
        className.push(styles.numRedActive);
      } else if (parseInt(questionMaterialId, 10) === item.questionMaterialId && parseInt(rankId, 10) === item.rank && isCorrect) {
        className.push(styles.numGreenActive);
      } else if (parseInt(rankId, 10) !== item.rank && !isCorrect) {
        className.push(styles.numRed);
      } else if (parseInt(rankId, 10) !== item.rank && isCorrect) {
        className.push(styles.numGreen);
      }
      return (
        <View
          key={index}
          className={className}
          onClick={() => {
            history.push(`/report/${mode}/${exerciseId}/${item.questionMaterialId}/${item.rank}${search}`);
            this.click(item.questionMaterialId);
          }}
        >
          { !isFirst &&
            parseInt(questionMaterialId, 10) === item.questionMaterialId &&
            parseInt(rankId, 10) === item.rank &&
            <View className={styles.triangle} />}
          {item.qNum}
        </View>);
    });
  }
  render() {
    const { isShowArrows, currentPage, countPages, questionStep } = this.state;
    const { match, backgroundType } = this.props;
    const { exerciseId, mode } = match.params;
    const search = global.location.search; // eslint-disable-line
    return (
      <View style={backgroundType !== 2 ? {} : { width: 510, marginTop: 40, marginRight: 40 }}>
        <View
          className={styles.indexBox}
          style={backgroundType !== 2 ? { backgroundColor: 'white' } : { backgroundColor: 'rgba(246,248,249,1)', justifyContent: 'flex-end' }}
        >
          <View className={styles.left}>
            {
              isShowArrows &&
              <View
                className={[styles.num, currentPage === 1 ? styles.disabled : styles.button]}
                onClick={() => {
                  if (currentPage === 1) { return; }
                  let currentPage1 = currentPage - 1; // eslint-disable-line
                  this.setState({ currentPage: currentPage1 });
                  // 当前页面的第一题
                  const index = (currentPage1 - 1) * 12;
                  const item = questionStep && questionStep[index];
                  history.push(`/report/${mode}/${exerciseId}/${item.questionMaterialId}${search}`);
                }}
              >
                <Image src={require('./assets/arrows_left@2x.png')} className={styles.image} />
              </View>
            }
            {this.renderIndex(currentPage - 1)}
          </View>
          {
            isShowArrows &&
              <View
                className={[styles.num, styles.right,
                  currentPage === countPages ? styles.disabled : styles.button]}
                onClick={() => {
                  if (currentPage === countPages) { return; }
                  let currentPage2 = currentPage + 1; // eslint-disable-line
                  this.setState({ currentPage: currentPage2 });
                  // 当前页面的第一题
                  const index = (currentPage2 - 1) * 12;
                  const item = questionStep && questionStep[index];
                  item && history.push(`/report/${mode}/${exerciseId}/${item.questionMaterialId}${search}`);
                }}
              >
                <Image src={require('./assets/arrows_right@2x.png')} className={styles.image} />
              </View>
          }
        </View>
      </View>
    );
  }
}
