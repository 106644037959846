import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findKey, filter, remove } from 'lodash';
import { View } from '@zhike/ti-ui';
import { marksRemoveRepeat } from '../util';
import styles from './styles';

const greenMarks = ['近义词', '恰当的搭配'];
// 报告
export default class Speaking extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 'Grammar',
      markSelected: '',
      markIndex: '',
      showList: [],
    };
  }

  componentDidMount() {
    global.document.body.addEventListener('click', this.documentClick);
  }

  componentWillUnmount() {
    global.document.body.removeEventListener('click', this.documentClick);
  }

  documentClick = event => {
    let el = event.target;
    let close = true;

    while (el !== global.document.body) {
      close = el.getAttribute('type') === 'card';
      if (!close) break;

      el = el.parentNode;
    }
    if (!close) this.setState({ markIndex: '' });
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  handleMarks(marks) {
    const tabs = [];
    for (let i = 0; i < marks.length; i += 1) {
      const tab = filter(tabs, { name: marks[i].type });
      if (tab.length) {
        tab[0].index.push(i);
      } else {
        tabs.push({
          name: marks[i].type,
          typeExplains: marks[i].typeExplains,
          index: [i],
        });
      }
    }
    return tabs;
  }

  // 批改报告左边
  generateReportLeft = (markList, markSelected) => (
    <View className={styles.reportLeft}>
      {
        markList.map((item, index) => {
          const markClass = [styles.mark];
          const markFloat = [styles.markFloat];
          const floatItem = [styles.floatItem];
          if (markSelected === item.name) {
            markClass.push(styles.markSelected);
          }
          if (greenMarks.indexOf(item.name) !== -1) {
            markClass.push(styles.greenBackGroundColor);
            markFloat.push(styles.greenMarkFloat);
            floatItem.push(styles.greenFloatItem);
          }
          return (
            <View key={index} className={styles.relative}>
              <View
                className={markClass}
                onClick={() => this.setState({ markSelected: item.name })}
              >
                <View>{item.name}</View>
                <View className={styles.markNum}>{item.index.length}</View>
              </View>
              {
                markSelected === item.name &&
                <View className={markFloat}>
                  <View className={floatItem}>
                    序号
                  </View>
                  {
                    item.index.map((item, index) => (
                      <View
                        key={index}
                        className={floatItem}
                        onClick={() => this.setState({ markIndex: item, markSelected: '' })}
                      >{item + 1}
                      </View>))
                  }
                </View>
              }
            </View>);
        })
      }
    </View>);

  // 生成解析
  generateMarkCard(mark, id) {
    const { offsetLeft } = global.document.getElementById(`num${id}`).offsetParent;
    let left = '24px';
    if (offsetLeft > 960) {
      left = '-230px';
    }
    const markCardFloat = [styles.markCardFloat];
    const markCardTitle = [styles.markCardTitle];
    if (greenMarks.indexOf(mark.type) !== -1) {
      markCardFloat.push(styles.borderGreen);
      markCardTitle.push(styles.backgroundColorGreen);
    }
    return (
      <View style={{ left }} className={markCardFloat} type="card">
        <View className={markCardTitle}>{mark.type}</View>
        {
          mark.typeExplains.length > 0 &&
            <View className={styles.markCardContent}>
              <View className={styles.markCardP}>批改解释：</View>
              {
                mark.typeExplains.map((item, index) => (
                  <View key={index}>{item}</View>))
              }
            </View>
        }
        {
          mark.explain.length > 0 &&
            <View className={styles.markCardContent}>
              <View className={styles.markCardP}>批改建议：</View>
              {
                mark.explain.map((item, index) => (
                  <View key={index}>{item}</View>))
              }
            </View>
        }
      </View>);
  }

  // 批改报告右边
  generateReportRight = (marks, answer, markIndex) => {
    const { text } = answer;
    const textList = text.split(/\n/g);
    marks = marksRemoveRepeat(marks); // eslint-disable-line
    let num = 0;
    let key = 0;
    return (
      <View className={styles.reportRight}>
        {
          textList.map((item, index) => {
            const itemEnd = item.length;
            let itemStart = 0; // 字符串切分的坐标 0
            const markHtml = [];
            const len = marks.length;
            for (let i = 0; i < marks.length; i += 1) {
              const start = marks[i].start - num;
              const end = marks[i].end - num;
              const paragraphNum = [styles.paragraphNum];
              const paragraphText = [styles.paragraphText];
              if (greenMarks.indexOf(marks[i].type) !== -1) {
                paragraphNum.push(styles.backgroundColorGreen);
                paragraphText.push(styles.colorGreen);
              }
              // 如果mark的start大于字符串的长度,就取itemStart到itemEnd,然后就跳出循环
              if (start > itemEnd) {
                markHtml.push(
                  <View key={key} className={styles.paragraphItem}>
                    {item.substring(itemStart, itemEnd)}
                  </View>);
                key += 1;
                break;
              }
              // 如果mark的start小于字符串的长度 并且大于字符串切分的坐标
              if (start < itemEnd && start >= itemStart) {
                markHtml.push(
                  <View key={key} className={styles.paragraphItem}>
                    {item.substring(itemStart, start)}
                  </View>);
                markHtml.push(
                  <View key={key + 1} className={[styles.paragraphItem, styles.relative]}>
                    <View
                      id={`num${i + 1}`}
                      className={paragraphNum}
                      onClick={() => this.setState({ markIndex: i })}
                    >{i + 1}
                    </View>
                    <View className={paragraphText}>{item.substring(start, end)}</View>
                    {
                      markIndex === i && this.generateMarkCard(marks[i], i + 1)
                    }
                  </View>);
                key += 2;
                itemStart = end; // 更新字符串切分的坐标
                if (i === len - 1) {
                  markHtml.push(
                    <View key={key} className={styles.paragraphItem}>
                      {item.substring(itemStart, itemEnd)}
                    </View>);
                }
              }
              // 如果mark的start和end相等，并且start和字符串的总长度相等
              if (start === end && start === itemEnd) {
                markHtml.push(
                  <View key={key} className={[styles.paragraphItem, styles.relative]}>
                    <View
                      id={`num${i + 1}`}
                      className={paragraphNum}
                      onClick={() => this.setState({ markIndex: i })}
                    >{i + 1}
                    </View>
                    <View className={paragraphText}>{item.substring(start, end)}</View>
                    {
                      markIndex === i && this.generateMarkCard(marks[i], i + 1)
                    }
                  </View>);
                key += 1;
              }
            }
            num += itemEnd + 1;
            return <View className={styles.paragraph} key={index}>{markHtml}</View>;
          })
        }
      </View>);
  }
  // 批改报告
  generateReport = () => {
    const { markSelected, markIndex } = this.state;
    const { machinePigaiResult, answer } = this.props.correctData;
    const { marks } = machinePigaiResult;
    if (marks.length === 0) return;
    const markList = this.handleMarks(marks);
    return (
      <View>
        <View className={styles.reportTitle}>
          <View className={styles.reportName}>批改报告</View>
        </View>
        <View className={styles.reportBox}>
          {this.generateReportLeft(markList, markSelected)}
          {this.generateReportRight(marks, answer, markIndex)}
        </View>
      </View>);
  }

  // 收起，展开click事件
  handleShow = name => {
    let { showList } = this.state;
    if (showList.indexOf(name) === -1) {
      showList.push(name);
    } else {
      showList = remove(showList, item => item !== name);
    }
    this.setState({ showList });
  }

  // 批改详情
  generateReportDetail = () => {
    const { showList } = this.state;
    const { machinePigaiResult } = this.props.correctData;
    const { marks } = machinePigaiResult;
    if (marks.length === 0) return;
    const markList = this.handleMarks(marks);
    return (
      <View>
        <View className={styles.reportTitle}>
          <View className={styles.reportName}>批改详情</View>
        </View>
        {
          markList.map((item, index) => {
            let isColor86ce2f;
            if (greenMarks.indexOf(item.name) !== -1) isColor86ce2f = 1;
            return (
              <View className={styles.detailBox} key={index} >
                <View className={styles.detail}>
                  <View className={styles.detailLeft}>
                    <View className={styles.detailName}>
                      <View
                        className={[styles.colorfd9827, isColor86ce2f && styles.color86ce2f]}
                      >●
                      </View>
                      {item.name}
                      <View
                        className={[styles.colorfd9827, isColor86ce2f && styles.color86ce2f]}
                      >{item.index.length}
                      </View>
                    </View>
                    <View>{item.typeExplains}</View>
                  </View>
                  <View
                    className={styles.detailRight}
                    onClick={() => this.handleShow(item.name)}
                  >
                    <View className={styles.detailRightName}>{showList.indexOf(item.name) === -1 ? '展开' : '收起'}</View>
                    <View
                      className={showList.indexOf(item.name) === -1 ?
                        styles.triangleBottom : styles.triangleTop}
                    />
                  </View>
                </View>
                {
                  showList.indexOf(item.name) !== -1 &&
                  <View className={styles.detailContent}>
                    <View className={styles.thBox}>
                      <View className={styles.th}>错误编号</View>
                      <View className={styles.th}>错误名称</View>
                      <View className={styles.th}>批改建议</View>
                    </View>
                    {
                      item.index.map((item, index) => (
                        <View className={styles.tdBox} key={index}>
                          <View className={styles.td}>{item + 1}</View>
                          <View className={styles.td}>{marks[item].text}</View>
                          <View className={styles.td}>{marks[item].explain.join(',')}</View>
                        </View>))
                    }
                  </View>
                }
              </View>);
          })
        }
      </View>);
  }

  // 分项tab标签
  generateTab = () => {
    const { tabSelected } = this.state;
    const { machinePigaiResult } = this.props.correctData;
    const { radar } = machinePigaiResult;
    const { grammaticalRangeAndAccuracy, lexicalResources, coherenceCohesion, taskResponse } = radar;
    const tabs = [
      { name: 'Grammatical Range and Accuracy', score: this.handleScore(grammaticalRangeAndAccuracy, 9) },
      { name: 'Lexical Resources', score: this.handleScore(lexicalResources, 9) },
      { name: 'Coherence Cohesion', score: this.handleScore(coherenceCohesion, 9) },
      { name: 'Task Response', score: this.handleScore(taskResponse, 9) },
    ];
    return (
      <View className={styles.tabs}>
        {
          tabs.map((item, index) => (
            <View
              className={[styles.tab, item.name === tabSelected && styles.tabSelected]}
              onClick={() => this.setState({ tabSelected: item.name })}
              key={index}
            >
              {`${item.name} ${item.score}`}
            </View>))
        }
      </View>);
  }

  // 分项内容
  generateTabContent = () => {
    const { tabSelected } = this.state;
    const { correctData } = this.props;
    const { machinePigaiResult } = correctData;
    const { subComments } = machinePigaiResult;
    const key = findKey(subComments, item =>
      (tabSelected === item.name) ||
      (tabSelected === 'Lexical Complexity' && item.section === '2') ||
      ((tabSelected === 'Organization' || tabSelected === 'Development') && item.section === '5'));
    if (key) {
      return (
        <View>
          {
            subComments[key].subItems.map((item, index) => {
              if (tabSelected === 'Organization' && item.section !== '5.1' && item.name !== 'Organization') return false;
              if (tabSelected === 'Development' && item.section !== '5.2' && item.name !== 'Development') return false;
              if (tabSelected === 'Lexical Complexity' && item.section !== '2.6' && item.name !== '词汇复杂度') return false;
              if (tabSelected === 'Usage' && item.section === '2.6' && item.name === '词汇复杂度') return false;
              return (
                <View key={index}>
                  <View className={styles.wordName}>{item.name}</View>
                  {item.comments &&
                    <View className={styles.wordDetail}>• {item.comments}</View>}
                  {item.examples &&
                    <View className={styles.wordDetail}>• {item.examples}</View>}
                  {item.suggestions &&
                    <View className={styles.wordDetail}>• {item.suggestions}</View>}
                </View>
              );
            })
          }
        </View>);
    }
    return <View className={[styles.textCenter, styles.marginTop24]}>暂无数据哦~</View>;
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateReport()}
        {this.generateReportDetail()}
        {/* {this.generateTab()}
        {this.generateTabContent()} */}
      </View>
    );
  }
}
