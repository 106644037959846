import React, { Component } from 'react';
import { View, Button, Scrollbar } from '@zhike/ti-ui';
import PropTypes from 'prop-types';
import Header from 'components/header';
import styles from './styles';

// 阅读指令
export default class ReadingDirections extends Component {
  static propTypes = {
    params: PropTypes.object,
  }
  static defaultProps = {
    params: {},
  }
  // 模块加载
  componentDidMount() {
    Header.config({
      isShowTime: false,
    });
    Header.cleanTimer();
  }

  // 渲染
  render() {
    const { mode } = this.props.params;
    const isMock = mode === 'mock';
    const time = isMock ? 60 : 20;
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={[styles.p, styles.strong]}>IELTS ACADEMIC READING</View>
        <View className={styles.p}>Time : {time} minutes</View>
        <View className={[styles.p, styles.strong]}>INSTRUCTIONS TO CANDIDATES</View>
        {!isMock && <View className={styles.p}> • Answer all the questions.<br /> • You can change your answers at any time during the test.</View>}
        {isMock && <View className={styles.p}> • Make sure that you understand the questions and follow instructions carefully.<br /> • Answer all the questions.<br /> • Spend approximately 20 minutes on each parts.</View>}
        <View className={[styles.p, styles.strong]}>INFORMATION FOR CANDIDATES</View>
        {!isMock && <View className={styles.p}> • There are 13-14 questions in this test.<br /> • The test clock will show you how long you have taken.</View>}
        {isMock && <View className={styles.p}> • There are three parts to the test.<br /> • There are 40 questions.<br /> • Each question carries one mark.<br /> • Pay attention to timing; do not spend too long on one passage or question.<br /> • Check your spelling.</View>}
        <View className={[styles.p, styles.strong, styles.textAlign]}>Click 'Start test' when you are ready</View>
        <Button
          className={styles.button}
          text="Start test"
          textClassName={styles.text}
          onClick={() => Header.next()}
        />
        {/* eslint-enable */}
      </Scrollbar>
    );
  }
}
