import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    marginTop: '40px',
    width: '100%',
  },
  table: {
    width: '100%',
    boxSizing: 'border-box',
    textAlign: 'left',
    fontSize: '16px',
    fontFamily: 'PingFangSC-Regular',
    color: 'rgba(50,54,58,1)',
    lineHeight: '24px',
    borderSpacing: '0px',
    borderPadding: '0px',
    borderCollapse: 'collapse',
  },
  thead: {
    backgroundColor: 'rgba(246,248,249,1)',
  },
  th: {
    width: '50%',
    boxSizing: 'border-box',
    border: '1px solid rgba(195,204,209,1)',
    padding: '10px',
    fontWeight: 'normal',
  },
  correct: {
    color: 'rgba(73,207,81,1)',
  },
  error: {
    color: 'rgba(253,84,84,1)',
  },
});
