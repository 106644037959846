import { cloneDeep, get } from 'lodash';
import * as Sentry from '@sentry/browser';

const mockTest = ['ListeningMock', 'ReadingMock', 'WritingMock', 'SpeakingMock'];
// 非模考模式下显示footer的练习
const normalShowFooter = ['ReadingQuestion', 'ListeningQuestion', 'SpeakingQuestion'];
// 模考模式下显示footer的练习
const mockShowFooter = ['ReadingQuestion', 'ListeningQuestion', 'SpeakingQuestion', 'WritingQuestion'];
/**
 * 雅思模考和普通测试获取考试步骤
 * @param {*} data
 */
export function getExerciseContent(data) {
  const { stepList, mode, practice, record, currentMockPracticeId } = data;
  let { stepId } = data;
  let name;
  let newSetRecord;
  if (!stepId) {
    stepId = 1;
  }

  // stepId超出总步骤长度，默认显示最后一个步骤
  if (stepId > stepList && stepList.data && stepList.data.length) {
    stepId = stepList.data.length - 1;
  }
  try {
    let step = stepList.data[stepId - 1];
    const isMock = mode === 'mock';
    const isMockStep = mockTest.includes(step.type);
    if (isMock) {
      // 雅思模考一个步骤包含多个练习，步骤从mockPractice中获取
      if (isMockStep) {
        const currentMockStep = cloneDeep(stepList.data[stepId - 1]);
        if (currentMockStep.mockPractices && currentMockPracticeId) {
          step = currentMockStep.mockPractices.find(ele => ele.practiceId === currentMockPracticeId);
        } else {
          step = currentMockStep.mockPractices[0];
        }
        if (step) {
          step.practice = get(practice, 'data.practices', []).find(ele => ele.id === step.practiceId) || {};
          step.question = get(step, 'practice.questions', []).find(ele => ele.practiceId === +currentMockPracticeId) || {};
          newSetRecord = get(record, `[${step.practiceId}].newStep.[${step.id}]`, {});
        }
      }
      name = get(practice, 'data.name', '');
    }
    if (!isMock) {
      step.practice = practice.data.questionBackup;
      if (mode === 'question') {
        name = step.practice.questions[0].name;
      } else {
        name = step.practice.name;
      }
      if (step.questionIndex !== undefined) {
        step.question = step.practice.questions[step.questionIndex];
      }
      newSetRecord = record.newStep && record.newStep[step.id] || {};
    }
    return { step, name, newSetRecord };
  } catch (e) {
    console.error('getExerciseContent: 获取模考步骤报错', e);
    Sentry.setContext('getExerciseContent', e);
    Sentry.captureException(e);
  }
}

/**
 * 判断是否显示footer
 * @param {String} mode
 * @param {String} type
 */
export function getFooter(mode, type) {
  const footerData = mode === 'mock' ? mockShowFooter : normalShowFooter;
  const condition = footerData.indexOf(type) !== -1;
  if (condition) {
    return true;
  }
  return false;
}

/**
 * 根据id获取某一个步骤下的子练习
 * @param {Array} stepList 所有的步骤练习
 * @param {string} stepId 当前的步骤id
 * @param {string} index 所要获取练习的下标
 */
export function getPracticeById(stepList, stepId, index) {
  const currentMockStep = stepList[+stepId - 1];
  const { mockPractices } = currentMockStep;
  return mockPractices[index];
}
