import React from 'react'
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    Sentry.setContext('errorInfo', errorInfo)
    Sentry.captureException(error)
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Normally, just render children
      return null
    }
    return this.props.children;
  }
}

export default ErrorBoundary
