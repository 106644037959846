import React, { Component } from 'react';
import { View, Image, Button, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import { AudioPlayer } from '@zhike/ti-component';
import styles from './styles';
import listenAudioTest from './assets/listening_test.mp3';

// 阅读指令
export default class ListeningDirections extends Component {
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: false,
    };
  }

  // 模块即将加载
  componentWillMount() {
    Header.config({
      showButtons: ['volume', 'next'],
      isShowTime: false,
    });
    Header.cleanTimer();
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
  }

  // 播放音频
  playAudio() {
    this.setState({
      isPlaying: true,
    });

    AudioPlayer.play({
      src: listenAudioTest,
      onEnd: () => {
        this.setState({
          isPlaying: false,
        });
      },
    });
  }

  // 停止播放音频
  stopPlayAudio() {
    AudioPlayer.unload();
    this.setState({
      isPlaying: false,
    });
  }

  // 渲染
  render() {
    const { isPlaying } = this.state;
    return (
      <Scrollbar className={styles.container}>
        <View className={styles.content}>
          <Image className={styles.model} src={require('./assets/model.png')} />
          <View className={styles.title}>Now put on your headset.</View>
          <View className={styles.card}>
            {
              !isPlaying &&
              <Image className={styles.volume} src={require('./assets/volume.png')} />
            }

            {
              isPlaying &&
              <Image className={styles.volume} src={require('./assets/volume.gif')} />
            }

            {
              !isPlaying &&
              <Button
                className={styles.button}
                text="耳机测试"
                onClick={() => this.playAudio()}
              />
            }

            {
              isPlaying &&
              <Button
                className={styles.button}
                text="取消播放"
                onClick={() => this.stopPlayAudio()}
                theme="hollow"
              />
            }

            <View className={styles.p}>点击按钮进行“耳机测试”</View>
            <View className={styles.p}>您可以通过右上角的</View>
            <View className={styles.p}>Volume按钮调节音量</View>
            <View className={styles.p}>或直接调节电脑音量</View>
          </View>
        </View>

      </Scrollbar>
    );
  }
}
