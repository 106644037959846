/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';

class Practice extends Component {

  render() {
    const questions = {"practiceExercises":[{"errorType":"Tense Error","questions":[{"question":"Yesterday, she ___ (go/went) to the library.","answer":"went"},{"question":"Last night, we ___ (see/saw) a movie.","answer":"saw"},{"question":"He always ___ (play/played) football on Sundays.","answer":"plays"},{"question":"I ___ (eat/ate) breakfast before going to work.","answer":"ate"},{"question":"Will you ___ (come/came) to the party tomorrow?","answer":"come"}]},{"errorType":"Spelling Error","questions":[{"question":"The correct spelling is a) definitely or b) definately?","answer":"a)"},{"question":"Did you check the a) calander or b) calendar for dates?","answer":"b)"},{"question":"I can't bear the a) acheive or b) achieve the goal.","answer":"b)"},{"question":"Is that a) sepperate or b) separate room?","answer":"b)"},{"question":"He open the a) can or b) cann of beans.","answer":"a)"}]},{"errorType":"Invalid word formation","questions":[{"question":"Correct the verb form: Every day he ___ (goed/goes) to the park.","answer":"goes"},{"question":"Choose the correct form: Yesterday, I ___ (runed/ran) fast.","answer":"ran"},{"question":"Which is right? a) She buyed b) She bought a new phone.","answer":"b)"},{"question":"Complete the sentence: He ___ (taked/took) a picture of the sunset.","answer":"took"},{"question":"Find the correct verb: They have ___ (selled/sold) their house.","answer":"sold"}]}]}
    return (
      <View>
        <h3>Practice</h3>
        {questions.practiceExercises.map((item, index) =>
          <View key={index}>
            <h4>{item.errorType}</h4>
            {item.questions.map((q, i) => 
              <p>{`${i + 1}. ${q.question}`}</p>
            )}
          </View>
        )}
      </View>
    )
  }
}

export default Practice;
