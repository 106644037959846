import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    minWidth: '1024px',
  },
  children: {
    flex: 1,
    overflow: 'auto',
    minWidth: '1024px',
  },
});
