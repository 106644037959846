export const SUBJECT = {
  READING: 6,
  LISTENING: 5,
  SPEAKING: 8,
  WRITING: 7,
};

export const SUBJECT_NAME = {
  6: '阅读',
  5: '听力',
  8: '口语',
  7: '写作',
};
