import React, { Component } from 'react';
import ScanAnimation from 'components/machine_correct_v2/animation';

// 做题
export default class MachineCorrectV2Container extends Component {
  render() {
    return (
      <ScanAnimation {...this.props} />
    );
  }
}

