import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },

  questionWrapper: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  questionsTab: {
    position: 'absolute',
    top: '40px',
    right: '40px',
    width: '510px',
    zIndex: 10,
  },

  questions: {
    width: 550,
    marginTop: 84,

    '@media (max-width: 1024px)': {
      width: 480,
    },
  },
  questionContent: {
    width: '550px',
    paddingBottom: 40,

    '@media (max-width: 1024px)': {
      width: 'auto',
    },
  },
  question: {
    width: '550px',
    paddingTop: 40,
    paddingRight: 40,

    '@media (max-width: 1024px)': {
      width: 480,
      paddingRight: 30,
    },
  },

  articleWrapper: {
    position: 'relative',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#F6F8F9',
  },

  article: {
    width: '100%',
    marginTop: 40,
    paddingLeft: 10,
    // paddingBottom: 40,
    overflow: 'hidden',
  },
});
