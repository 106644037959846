import { StyleSheet } from 'aphrodite';

const scoreFont = {
  fontFamily: 'CoolFont',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `url(${require('./assets/FUTURAXK.ttf')}) format('truetype')`,

};

export default StyleSheet.create({
  container: {
    width: '100%',
    alignSelf: 'center',
    color: '#505050',
  },

  top: {
    position: 'absolute',
    width: '100%',
    // height: 428,
    top: 0,
    bottom: 0,
    backgroundColor: '#1f2f47',
    color: '#fff',
    textAlign: 'center',
  },

  title: {
    marginTop: 72,
    fontSize: 28,
    fontWeight: 'bold',
  },

  time: {
    marginTop: 24,
    fontSize: 14,
    opacity: '0.5',
  },

  score: {
    margin: '0 auto',
    marginTop: 12,
    paddingTop: 86,
    width: 240,
    height: 240,
    backgroundSize: '100%',
    textAlign: 'center',
    fontSize: 48,
    fontFamily: [scoreFont],
    // letterSpacing: 6,
  },

  commentBox: {
    flex: 1,
    flexDirection: 'row',
    margin: '0 auto',
    marginTop: 48,
    width: 1200,
  },

  comment: {
    padding: 12,
    width: 576,
    // height: 404,
    border: 'solid 1px #d0dce7',
    ':first-child': {
      marginRight: 48,
    },
  },

  commentTitle: {
    height: 48,
    lineHeight: '48px',
    textAlign: 'center',
    color: '#fff',
  },

  p: {
    padding: '0 12px',
    fontSize: 14,
    lineHeight: '32px',
    ':first-child': {
      marginTop: 24,
    },
  },

  footer: {
    margin: '0 auto',
    marginTop: 48,
    marginBottom: 24,
    width: 1200,
    height: 72,
    lineHeight: '72px',
    backgroundColor: '#1f2f47',
    color: '#8f94a7',
    textAlign: 'center',
  },
});
