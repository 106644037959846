import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    width: 1024,
    margin: '0 auto',
    paddingBottom: 30,
    color: '#32363a',
  },

  title: {
    height: 130,
    fontSize: 28,
    lineHeight: '130px',
    textAlign: 'center',
  },

  p: {
    display: 'block',
    marginTop: 14,
    fontSize: 18,
    lineHeight: '26px',
  },

  strong: {
    display: 'inline',
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 5,
  },

  earphoneText: {
    display: 'inline-block',
  },

  earphoneImage: {
    display: 'inline-block',
    width: 24,
    height: 24,
    marginLeft: 8,
    marginRight: 6,
  },
});
