import { StyleSheet } from 'aphrodite';
import imgCheckbox from './assets/checkbox.png';
import imgCheckboxChecked from './assets/checkbox-checked.png';
import imgCheckboxHover from './assets/checkbox-hover.png';


export default StyleSheet.create({
  container: {
    width: '100%',
    height: '64px',
    background: 'rgba(255,255,255,1)',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',

    '@media (max-width: 1024px)': {
      paddingTop: 10,
      height: 94,
    },
  },

  content: {
    position: 'relative',
    flexDirection: 'row',
    margin: '0 auto',
    width: 1250,
    height: 64,
    alignItems: 'center',

    '@media (max-width: 1024px)': {
      width: 1024,
      justifyContent: 'space-between',
      height: 94,
    },
  },

  review: {
    position: 'absolute',
    top: 22,
    left: 0,
    flexDirection: 'row',
    alignItems: 'center',

    '@media (max-width: 1024px)': {
      marginLeft: 5,
      marginTop: 7,
    },
  },

  qNum: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',

    '@media (max-width: 1024px)': {
      justifyContent: 'start',
      flexWrap: 'wrap',
      marginLeft: 90,
    },
  },

  labels: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    display: 'inline-block',
    marginRight: 8,
  },

  label: {
    display: 'block',
    position: 'relative',
    width: 16,
    height: 16,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    cursor: 'pointer',
  },

  checkbox: {
    backgroundImage: `url(${imgCheckbox})`,
    ':hover': {
      backgroundImage: `url(${imgCheckboxHover})`,
    },
  },

  checkboxChecked: {
    backgroundImage: `url(${imgCheckboxChecked})`,
  },

  square: {
    width: '32px',
    height: '32px',
    borderRadius: '3px',
    border: '1px solid rgba(195,204,209,1)',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    fontFamily: 'PingFangSC-Semibold',
    color: 'rgba(129,140,150,1)',
    lineHeight: '17px',
    cursor: 'pointer',
    marginRight: '10px',
    ':last-child': {
      marginRight: 0,
    },

    '@media (max-width: 1024px)': {
      marginBottom: 10,
    },
  },

  isReview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '12px',
    height: '12px',
  },

  circle: {
    borderRadius: '16px',
    border: '1px solid rgba(195,204,209,1)',
  },

  current: {
    border: '2px solid rgba(51,153,255,1)',
  },

  visited: {
    textDecoration: 'underline',
  },
  forbid: {
    cursor: 'not-allowed',
  },
});
