import React, { Component } from 'react';
import { View, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import styles from './styles';

// 结束指令
export default class ListeningEnding extends Component {
  // 模块加载
  componentDidMount() {
    Header.config({
      title: 'Listening',
      showButtons: ['continue'],
      isShowTime: false,
    });
    Header.cleanTimer();
  }

  // 渲染
  render() {
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={styles.p}>Your <View className={styles.strong}>Listening</View> test has finished.</View>
        <View className={styles.p}>All of your answers have been stored.</View>
        {/* eslint-enable */}
      </Scrollbar>
    );
  }
}
