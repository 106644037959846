import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    marginTop: '40px',
    width: '100%',
  },

  title: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 20,
    height: 18,
    lineHeight: '18px',
    fontSize: 14,
    fontWeight: 600,
    '::before': {
      content: '""',
      marginRight: 5,
      width: 4,
      height: 18,
      borderRadius: 100,
      backgroundColor: '#3399ff',
    },
  },

  box: {
    position: 'relative',
    width: 284,
    height: 160,
  },

  courseImage: {
    width: 284,
    height: 160,
  },

  mask: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 284,
    height: 160,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    cursor: 'pointer',
  },

  play: {
    marginTop: 54,
    marginLeft: 120,
    width: 40,
    height: 40,
  },
});
