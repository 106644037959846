import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cookie from 'cookie';
import { addCollect, cancelCollect, getCollect } from 'common/apis';
import { View, Image } from '@zhike/ti-ui';
import { redirectToLoginPage } from 'utils/action';
import styles from './styles';

export default class Collect extends Component {
  static propTypes = {
    question: PropTypes.object.isRequired,
    exerciseId: PropTypes.string.isRequired,
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.collectCacheDict = {}; // 按questionId缓存收藏状态，切换的时候不用每次都加载
    this.state = {
      collect: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.updateCollect();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      this.collectCacheDict[prevProps.question.id] = this.state.collect || {};
      this.updateCollect();
    }
  }

  getToken = () => {
    const search = global.location.search; // eslint-disable-line
    const tokenUrl = decodeURIComponent(search.match(new RegExp('[?#&]token=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    let token;

    if (tokenUrl) {
      token = tokenUrl;
    } else {
      try {
        const user = JSON.parse(cookie.parse(global.document.cookie).ss_user);
        token = user.token; // eslint-disable-line
      } catch (e) {
        console.log(e);
        redirectToLoginPage();
        return;
      }
    }

    return token;
  }

  // 更新收藏状态
  updateCollect = async () => {
    const collectCache = this.collectCacheDict[this.props.question.id];
    if (collectCache) {
      this.setState({ collect: collectCache });
      return;
    }

    try {
      await this.setState({ collect: {}, loading: true });
      const { subjectId, examId, materials } = this.props.question;

      // 雅思题目的收藏用的是题目材料 ID，跟托福不同
      const refId = materials[0].id;
      const token = this.getToken();

      const result = await axios({
        url: getCollect[1],
        method: getCollect[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        params: {
          token,
          refId,
          examId,
          subjectId,
          type: 'QuestionMaterial',
        },
      });

      if (result.data.code === 0) {
        await this.setState({
          collect: result.data.data[0] || {},
          loading: false,
        });
      } else {
        await this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  }

  addCollect = async () => {
    try {
      await this.setState({ loading: true });
      const { materials } = this.props.question;

      // 雅思题目的收藏用的是题目材料 ID，跟托福不同
      const refId = materials[0].id;
      const token = this.getToken();

      const result = await axios({
        url: addCollect[1],
        method: addCollect[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        data: {
          token,
          refId,
          type: 'QuestionMaterial',
          exerciseId: parseInt(this.props.exerciseId, 10),
        },
      });

      if (result.data.code === 0) {
        await this.setState({
          loading: false,
          collect: result.data.data,
        });
      } else {
        await this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  }

  cancelCollect = async () => {
    try {
      await this.setState({ loading: true });
      const { id } = this.state.collect;
      const token = this.getToken();

      const result = await axios({
        url: cancelCollect[1],
        method: cancelCollect[0],
        headers: {
          'Content-Type': 'application/json',
          From: 1,
        },
        data: {
          token,
          id,
          exerciseId: parseInt(this.props.exerciseId, 10),
        },
      });

      if (result.data.code === 0) {
        await this.setState({
          loading: false,
          collect: {},
        });
      } else {
        await this.setState({ loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  }

  render() {
    const { collect, loading } = this.state;
    const collected = !!collect.id;
    if (this.props.exerciseId < 0) return null;
    return (
      <View className={[styles.container, loading && styles.wait]} onClick={collected ? this.cancelCollect : this.addCollect}>
        <Image width={12} height={12} src={collected ? require('components/report/assets/collected.png') : require('components/report/assets/uncollect.png')} />
        <View className={[styles.text, collected ? styles.collected : styles.uncollect]}>收藏</View>
      </View>
    );
  }
}
