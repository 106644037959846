/* eslint-disable */
import React, { Component } from 'react';
import { View } from '@zhike/ti-ui';
import styles from './styles';

class SuggestionItem extends Component {
  render() {
    const { type, index, weak_type_chinese, isSelected, onClick, styles, suggestion_chinese, appreciation_chinese } = this.props;
    return (
      <View
        key={index}
        className={isSelected === `${type}_${index}` ?
          (type === 'good' ? styles.resultItemSelected : styles.resultItemAdviceSelected) :
          styles.resultItem
        }
        onClick={() => onClick(`${type}_${index}`)}
      >
        <View>
          {type === 'bad' && <View className={styles.title}>{`${'💬'} ${weak_type_chinese}`}</View>}
          <View>{type === 'good' ? `${'👍'} ${appreciation_chinese}` : suggestion_chinese || 'None'}</View>
        </View>
      </View>
    );
  }
}

class SuggestionList extends Component {
  render() {
    const { list, isSelected, onClickSentence } = this.props;

    return (
      <View className={styles.result}>
        <h3 style={{ marginBottom: '48px' }}>批改建议 <View className={styles.num}>{ list.length }</View></h3>
        <div
          style={{
            height: '540px',
            overflow: 'scroll',
          }}
        >
          {Object.entries(list).map(([key, item]) => (
            <SuggestionItem
              key={key}
              id={`suggestion-${item.type}-${item.index}`}
              index={key}
              isSelected={isSelected}
              onClick={() => onClickSentence(item.type, key)}
              styles={styles}
              {...item}
            />
          ))}
        </div>
      </View>
    );
  }
}

export default SuggestionList;
