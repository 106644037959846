import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, omit } from 'lodash';
import { View } from '@zhike/ti-ui';
import Pigai from '../pigai';
import styles from './styles';

const writingDimensionMap = {
  grammaticalRangeAndAccuracy: 'Grammatical range and accuracy',
  lexicalResources: 'Lexical resources',
  coherenceCohesion: 'Coherence cohesion',
  taskResponse: 'Task response',
}

// 报告
export default class WritingV2 extends Component {
  // 参数
  static propTypes = {
    correctData: PropTypes.object.isRequired,
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleScore(score, base) {
    const newScore = base ? parseInt(score * base, 10) : score;
    return newScore;
  }

  generateTabContent = () => {
    const aiPigaiResult = get(this, 'props.correctData.aiPigaiResult', {})
    const commonAIPigaiResult = omit(aiPigaiResult, ['all'])
    const answerText = get(this, 'props.correctData.answerText', '');
    const aiPigaiCompositonAnalysis = get(this, 'props.correctData.aiPigaiCompositonAnalysis', [])

    return (
      <View>
        {(aiPigaiCompositonAnalysis && aiPigaiCompositonAnalysis.length > 0) &&
          <Pigai
            answerText={answerText}
            commonAIPigaiResult={aiPigaiCompositonAnalysis}
            practice={commonAIPigaiResult}
          />
        }
        {Object.entries(commonAIPigaiResult).map(([key, item]) => (
          <View key={key} className={styles.content}>
            <h4>{writingDimensionMap[key]}</h4>
            <View>{`comment：${item.comment || 'None'}`}</View>
            {item.chinese_comment &&
              <View style={{ marginTop: '10px' }}>{`评语：${item.chinese_comment}`}</View>
            }
          </View>
        ))}
      </View>
    );
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        {this.generateTabContent()}
      </View>
    );
  }
}
