import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Image } from '@zhike/ti-ui';
import { zhikeUrl, smartUrl } from 'common/config';
import styles from './styles';

function isFromSmart(source) {
  return source === 'smart' || source === 'smartInside'
}

// 阅读题目
export default class VideoParsing extends Component {
  static propTypes = {
    practiceId: PropTypes.number.isRequired,
    startTip: PropTypes.number,
    conciseId: PropTypes.number,
  };
  // 参数
  static defaultProps = {
    startTip: 0,
    conciseId: 0,
  };

  play = () => {
    const search = global.location.search; // eslint-disable-line
    const online = decodeURIComponent(search.match(new RegExp('[?#&]online=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const source = decodeURIComponent(search.match(new RegExp('[?#&]source=([^?#&]+)', 'i')) ? RegExp.$1 : '');
    const { practiceId, startTip, conciseId, userGroup, userGroupName } = this.props;
    let url = `${zhikeUrl}/play/${practiceId}.html?ctqaType=practice${startTip ? `&startTip=${startTip}` : ''}`;
    let domain = smartUrl;
    if (isFromSmart(source) && online !== '1') {
      if (userGroup !== 1 && userGroupName) {
        if (process.env.NODE_ENV === 'production') {
          domain = smartUrl.replace('//sp.smartstudy', `//${userGroupName}.smartxue`);
        } else {
          domain = smartUrl.replace('//sp', `//${userGroupName}.yun`);
        }
      }
      url = `${domain}/play?sliceId=${conciseId}&type=ctqa${startTip ? `&tipId=${startTip}` : ''}`;
    }

    global.open(url);
  }

  // 渲染
  render() {
    return (
      <View className={styles.container}>
        <View className={styles.title}>视频解析</View>
        <View className={styles.box}>
          <Image className={styles.courseImage} src={require('./assets/video.jpg')} />
          <View className={styles.mask} onClick={this.play}>
            <Image className={styles.play} src={require('./assets/play.png')} />
          </View>
        </View>
      </View>
    );
  }
}
