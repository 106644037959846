import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Image, Scrollbar } from '@zhike/ti-ui';
import Article from 'components/article';
import { Audio, SearchWords } from '@zhike/ti-component';
import { history } from 'routers';
import { get } from 'lodash';
import { countWords } from 'utils';
import { smoothScroll } from 'utils/smoothScroll';
import styles from './styles';

// 阅读题目
export default class ReportArticle extends Component {
  // 参数
  static propTypes = {
    subjectId: PropTypes.number.isRequired,
    material: PropTypes.object,
    match: PropTypes.object,
    questions: PropTypes.array,
  };
  static defaultProps = {
    material: {},
    match: {},
    questions: [],
  };

  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      selectTab: 0,
      selectList: [],
      isListening: this.props.subjectId === 5,
      tabMessages: this.props.subjectId === 5 ? this.getTabMessages(this.props) : [],
      currentTabIndex: this.props.subjectId === 5 ? this.getCurrentIndex(this.props) : 0,
    };
  }

  // 模块加载
  componentDidMount() {
    this.handleSelectList(this.props);
  }

  // 模块接受参数
  componentWillReceiveProps(nextProps) {
    this.handleSelectList(nextProps);
    if (this.props.subjectId === 5 &&
      this.props.match.params.questionMaterialId !== nextProps.match.params.questionMaterialId) {
      if (this.getCurrentIndex(nextProps) !== this.state.currentTabIndex) {
        this.setState({
          currentTabIndex: this.getCurrentIndex(nextProps),
        });
        this.switch(this.getCurrentIndex(nextProps));
      }
    }
  }

  // 生成Tab切换信息
  getTabMessages(props) {
    const { questions } = props;
    const tabMessages = [];
    questions.map((item, index) => {
      const questionId = item.id;
      const { audioText, audioMaterial, headingNumber } = item.extra;
      tabMessages.push({ questionId, audioText, audioMaterial, headingNumber, index });
      return false;
    });
    return tabMessages;
  }

  // 获取当前的 currentTabIndex值
  getCurrentIndex(props) {
    const { questions, match } = props;
    const { questionMaterialId } = match.params;
    let index;
    if (questionMaterialId) {
      questions.map((question, index1) => {
        question.materials.map(material => {
          if (material.id.toString() === questionMaterialId) {
            index = index1;
          }
          return false;
        });
        return false;
      });
    }
    if (index) return index;
    return 0;
  }

  // tab点击切换
  switch(index, isTab = false) {
    const element = global.document.querySelector(`#item-${index}`);
    element && smoothScroll({
      element,
      position: 'center',
      duration: 300,
      horizon: true,
      callback: () => {
        if (isTab) {
          const { questions } = this.props;
          const { exerciseId, mode } = this.props.match.params;
          const { tabMessages } = this.state;
          // 左侧滚动到 对应的习题组
          const { questionId } = tabMessages[index];
          const questionMaterialId = questions[index].materials[0].id;
          this.setState({ currentTabIndex: index });
          history.push(`/report/${mode}/${exerciseId}/${questionMaterialId}`);
          const anchorElement = global.document.getElementById(questionId);
          // 如果对应id的锚点存在，就跳转到锚点
          if (anchorElement) {
            smoothScroll({
              element: anchorElement,
              position: 'start',
              duration: 300,
            });
          }
        }
      },
    });
  }

  // 处理select数据
  handleSelectList = props => {
    const selectList = [];
    const { material, question, subjectId } = props;
    if (subjectId === 6) {
      // 判断阅读原文是否为空。
      let isEmpty1 = true;
      const origin = get(material, 'origin.paragraphs');
      origin && origin.forEach(item => {
        const str = item.text.replace(/^\s+|\s+$/g, '');
        if (str) { isEmpty1 = false; return false; }
      });
      // 判断参考译文是否为空
      let isEmpty2 = true;
      const translation = get(material, 'translation.paragraphs');
      translation && translation.forEach(item => {
        const str = item.text.replace(/^\s+|\s+$/g, '');
        if (str) { isEmpty2 = false; return false; }
      });
      selectList.push({
        id: 0,
        name: '阅读原文',
        article: material,
        empty: '暂无原文哦~',
        isEmpty: isEmpty1,
      });
      material.translation && selectList.push({
        id: 1,
        name: '参考译文',
        article: { origin: material.translation },
        empty: '暂无参考译文哦~',
        isEmpty: isEmpty2,
      });
    } else if (subjectId === 7) {
      const questionMaterial = question.materials[0];
      const userTextList = questionMaterial.userAnswer && questionMaterial.userAnswer.answer &&
        questionMaterial.userAnswer.answer.text &&
        questionMaterial.userAnswer.answer.text.split(/\n/g) || [];
      const paragraphs = [];
      userTextList.forEach((item, index) => {
        paragraphs.push({
          text: item,
          type: 'Text',
          id: index,
        });
      });
      selectList.push({
        id: 0,
        name: '我的作文',
        article: userTextList.length && { origin: { paragraphs } },
        wordCount: questionMaterial.userAnswer && questionMaterial.userAnswer.answer
          && questionMaterial.userAnswer.answer.wordCount,
        empty: '没有作答',
        src: require('components/assets/default.png'),
        isEmpty: get(questionMaterial, 'userAnswer.answer.wordCount') ? false : true, // eslint-disable-line
      });

      if (Array.isArray(questionMaterial.examples) && questionMaterial.examples.length > 0) {
        for (let i = 0; i < questionMaterial.examples.length; i += 1) {
          const name = questionMaterial.examples.length === 1 ? '参考范文' : `参考范文${i + 1}`;
          // 计算参考范文的字数。
          let wordCount = 0;
          questionMaterial.examples.forEach(item => {
            item.text.paragraphs && item.text.paragraphs.forEach(item => {
              wordCount += countWords(item.text);
            });
          });
          selectList.push({
            id: i + 1,
            name,
            article: { origin: questionMaterial.examples[i].text },
            wordCount,
            empty: '暂无参考范文哦~',
            src: require('components/assets/fail.png'),
            isEmpty: !wordCount,
          });
        }
      } else {
        selectList.push({
          id: 1,
          name: '参考范文',
          article: '',
          wordCount: 0,
          empty: '暂无参考范文哦~',
          isEmpty: true,
        });
      }
    }
    this.setState({ selectList });
  }


  // 生成tab
  renderTab = () => {
    const { selectTab, selectList } = this.state;
    return selectList.map(item => {
      const classNames = [styles.tab];
      if (selectTab === item.id) {
        classNames.push(styles.selectTab);
      }
      return (
        <View
          className={classNames}
          key={item.id}
          onClick={() => this.setState({ selectTab: item.id })}
        >
          {item.name}
        </View>);
    });
  }

  // 渲染
  render() {
    const { selectTab, selectList, tabMessages, currentTabIndex, isListening } = this.state;
    const { subjectId } = this.props;
    const article = selectList[selectTab];
    const src = require('components/assets/fail.png');
    return (
      <View className={styles.container}>
        {
          subjectId === 5 &&
          <View className={styles.listeingContainer}>
            <View className={styles.heading}>
              <View className={styles.scrollBar}>
                <View
                  className={styles.listeningTab}
                >
                  {
                    tabMessages &&
                    tabMessages.map((item, index) => (
                      <View
                        className={
                          [
                            styles.listeningTitle,
                            currentTabIndex === index && styles.currentTitle,
                          ]
                        }
                        onClick={() => this.switch(index, true)}
                        key={index}
                        id={`item-${index}`}
                      >
                        {item.headingNumber}
                      </View>),
                    )
                  }
                </View>
              </View>
            </View>
            <View className={styles.listeningMaterial}>
              {
                tabMessages[currentTabIndex] &&
                tabMessages[currentTabIndex].audioMaterial &&
                <Audio
                  src={tabMessages[currentTabIndex].audioMaterial.src}
                  text={tabMessages[currentTabIndex].audioText}
                  materialType={isListening ? 'listenTranslation' : 'exampleOriginal'}
                />
              }
            </View>
          </View>
        }
        {
          subjectId !== 5 && article &&
            <View className={styles.otherContainer}>
              <View className={[styles.titleBox, subjectId !== 1 && styles.other]}>
                <View className={styles.titleContainer}>
                  <View className={styles.title}>
                    {this.renderTab()}
                  </View>
                  {subjectId === 6 && <View className={styles.text}>Tips：双击/圈选可查询单词</View>}
                </View>
                {subjectId === 7 &&
                  article.wordCount !== -1 && <View>Word Count : {article.wordCount || 0}</View>}
              </View>
              {
                (subjectId === 6 || subjectId === 8) &&
                <View className={[styles.article, styles.writingArticle]}>
                  {!article.isEmpty ?
                    <Scrollbar
                      className={styles.articleContent}
                      onScrollStart={() => { SearchWords.hide(); }}
                    >
                      {
                        article.article.title &&
                        <View className={styles.articleTitle}>{article.article.title}</View>
                      }
                      <Article material={article.article.origin} />
                    </Scrollbar> :
                    <Scrollbar
                      className={styles.articleEmpty}
                      onScrollStart={() => { SearchWords.hide(); }}
                    >
                      <Image
                        className={styles.empty}
                        src={src}
                      />
                      {article.empty || '暂无文章哦~'}
                    </Scrollbar>
                  }
                </View>
              }
              {
                subjectId === 7 &&
                <View className={styles.article}>
                  {!article.isEmpty ?
                    <View className={styles.articleContent}>
                      {
                        article.article.title &&
                        <View className={styles.articleTitle}>{article.article.title}</View>
                      }
                      <Article material={article.article.origin} />
                    </View> :
                    <View className={styles.articleEmpty}>
                      <Image
                        className={styles.empty}
                        src={article.src}
                      />
                      {article.empty || '暂无文章哦~'}
                    </View>
                  }
                </View>
              }
            </View>
        }
      </View>
    );
  }
}
