import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Button, Scrollbar } from '@zhike/ti-ui';
import Header from 'components/header';
import styles from './styles';

// 阅读指令
export default class WritingDirections extends Component {
  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
    params: PropTypes.object,
  };

  static defaultProps = {
    params: {},
  }

  // 模块加载
  componentDidMount() {
    Header.config({
      isShowTime: false,
    });
    Header.cleanTimer();
  }

  normalContent = () => {
    const { practiceTypeId } = this.props.step.practice;
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={[styles.p, styles.strong]}>IELTS ACADEMIC WRITING</View>
        <View className={styles.p}>Time : {practiceTypeId === 10 ? 20 : 40} minutes</View>
        <View className={[styles.p, styles.strong]}>INSTRUCTIONS TO CANDIDATES</View>
        <View className={styles.p}> • Finish this part.<br /> • You can change your answer at any time during the test.</View>
        <View className={[styles.p, styles.strong]}>INFORMATION FOR CANDIDATES</View>
        <View className={styles.p}> • The test clock will show you how long have you taken.</View>
        <View className={[styles.p, styles.strong, styles.textAlign]}>Click 'Start test' when you are ready</View>
        <Button
          className={styles.button}
          text="Start test"
          textClassName={styles.text}
          onClick={() => Header.next()}
        />
        {/* eslint-enable */}
      </Scrollbar>
    );
  }

  mockContent = () => (
    <Scrollbar className={styles.container}>
      {/* eslint-disable */}
      <View className={[styles.p, styles.strong]}>IELTS WRITING</View>
      <View className={styles.p}>Time : 60 minutes</View>
      <View className={[styles.p, styles.strong]}>INSTRUCTIONS TO CANDIDATES</View>
      <View className={styles.p}> • Highlight or underline key words in the tasks to make sure that you focus on what you have to do.</View>
      <View className={styles.p}> • Answer both tasks.</View>
      <View className={styles.p}> • Spend approximately 20 minutes on Task 1 and approximately 40 minutes on Task 2.</View>
      <View className={[styles.p, styles.strong]}>INFORMATION FOR CANDIDATES</View>
      <View className={styles.p}> • There are two parts to the tests.</View>
      <View className={styles.p}> • Manage your time; remember, Task 2 is worth twice as much as Task 1.</View>
      <View className={styles.p}> • Pay attention to the number of words required for each task; you will lose marks if you do not write at least 150 words for Task 1 and at least 250 words for Task 2.</View>
      <View className={styles.p}> • Use paragraph clearly; put one idea in each paragraph.</View>
      <View className={styles.p}> •  Keep to the topic; do not write about unrelated subjects.</View>
      <View className={[styles.p, styles.strong, styles.textAlign, styles.mt10]}>Click 'Start test' when you are ready</View>
      <Button
        className={[styles.button, styles.mt8]}
        text="Start test"
        textClassName={styles.text}
        onClick={() => Header.next()}
      />
      {/* eslint-enable */}
    </Scrollbar>
  )


  // 渲染
  render() {
    const { mode } = this.props.params;
    const Content = mode === 'mock' ? this.mockContent() : this.normalContent();
    return Content;
  }
}
