import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
  },

  articleWrapper: {
    flex: 1,
    alignItems: 'flex-end',
    paddingTop: 40,
    paddingBottom: 40,
  },

  article: {
    flex: 1,
    paddingLeft: 20,
    overflowY: 'auto',

    '@media (max-width: 1024px)': {
      width: 470,
    },
  },

  material: {
    marginTop: 40,
    ':first-child': {
      marginTop: 0,
    },
  },

  imageForWriting: {
    position: 'relative',
    lineHeight: 0,
    marginTop: 12,
    alignSelf: 'start',
  },

  pic: {
    width: 500,

    '@media (max-width: 1024px)': {
      width: 430,
    },
  },

  scope: {
    position: 'absolute',
    width: '32px',
    bottom: 0,
    left: 468,
    cursor: 'pointer',
  },

  title: {
    marginBottom: 8,
    fontWeight: 'bold',
    lineHeight: '22px',
  },

  editorWrapper: {
    flex: 1,
    backgroundColor: '#F6F8F9',
    paddingLeft: 40,
    paddingTop: 40,
    paddingRight: 40,
  },

  editorToolbar: {
    flexDirection: 'row',
    // width: 550,
    marginLeft: -10,
  },

  editorToolbarBtn: {
    marginLeft: 10,
  },

  editorToolbarCount: {
    flex: 1,
    fontSize: 12,
    color: '#878F98',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },

  editorTextarea: {
    flex: 1,
    // width: 550,
    marginTop: 10,
    marginBottom: 40,
    padding: 20,
    fontSize: 16,
    border: '1px solid #C3CCD1',
    borderRadius: 3,
  },
});
