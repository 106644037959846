import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    margin: '0 auto',
    width: 1200,
    alignSelf: 'center',
    color: '#505050',
  },

  textCenter: {
    textAlign: 'center',
  },

  marginTop24: {
    marginTop: 24,
  },

  reportTitle: {
    // flex: 1,
    // flexDirection: 'row',
    display: 'block',
    marginTop: 36,
    height: 46,
    borderBottom: '1px solid #d0dce7',
  },

  reportName: {
    float: 'left',
    marginBottom: -1,
    padding: '0 32px',
    height: 46,
    lineHeight: '46px',
    color: '#3399ff',
    borderBottom: '1px solid #3399ff',
  },

  reportBox: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 24,
  },

  reportLeft: {
    width: 160,
    zIndex: 9,
  },

  mark: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 10,
    padding: '0 14px',
    width: 160,
    height: 30,
    lineHeight: '30px',
    fontSize: 14,
    borderRadius: 36,
    color: '#fff',
    backgroundColor: '#fd9827',
  },

  greenBackGroundColor: {
    backgroundColor: '#86ce2f',
  },

  relative: {
    position: 'relative',
  },

  markFloat: {
    position: 'absolute',
    top: 0,
    right: -118,
    width: 94,
    border: '1px solid #feb25e',
    '::before': {
      content: "''",
      position: 'absolute',
      top: 7,
      left: -12,
      height: 0,
      width: 0,
      borderTop: '7px solid transparent',
      borderRight: '12px solid #feb25e',
      borderBottom: '7px solid transparent',
    },
  },

  greenMarkFloat: {
    border: '1px solid #86ce2f',
    '::before': {
      borderRight: '12px solid #86ce2f',
    },
  },

  floatItem: {
    height: 30,
    lineHeight: '30px',
    textAlign: 'center',
    color: '#feb25e',
    backgroundColor: '#fff',
    borderBottom: '1px solid #feb25e',
    ':last-child': {
      borderBottom: 'none',
    },
    ':first-child': {
      color: '#fff',
      backgroundColor: '#feb25e',
      ':hover': {
        backgroundColor: '#feb25e',
      },
    },
    ':hover': {
      backgroundColor: '#FFF3E6',
    },
  },

  greenFloatItem: {
    color: '#86ce2f',
    borderBottom: '1px solid #86ce2f',
    ':first-child': {
      backgroundColor: '#86ce2f',
      ':hover': {
        backgroundColor: '#86ce2f',
      },
    },
    ':hover': {
      backgroundColor: '#EEFFEE',
    },
  },

  reportRight: {
    display: 'block',
    marginLeft: 20,
    width: 1020,
    height: '100%',
  },

  paragraph: {
    display: 'block',
    width: '100%',
    lineHeight: '26px',
    fontSize: 14,
    textAlign: 'justify',
  },

  paragraphItem: {
    display: 'inline',
  },

  paragraphNum: {
    display: 'inline-block',
    marginLeft: 6,
    marginRight: 6,
    width: 20,
    height: 20,
    lineHeight: '20px',
    borderRadius: '50%',
    textAlign: 'center',
    backgroundColor: '#feb25e',
    color: '#fff',
    cursor: 'pointer',
  },

  backgroundColorGreen: {
    backgroundColor: '#86ce2f',
  },

  paragraphText: {
    display: 'inline',
    marginRight: 6,
    color: '#feb25e',
  },

  colorGreen: {
    color: '#86ce2f',
  },

  borderGreen: {
    border: '1px solid #86ce2f',
  },

  markCardFloat: {
    position: 'absolute',
    paddingBottom: 10,
    top: 22,
    width: 240,
    fontSize: 12,
    border: '1px solid #fd9827',
    backgroundColor: '#fff',
    boxShadow: '0 5px 10px 0 rgba(53, 61, 72, 0.1)',
    zIndex: 8,
  },

  markCardTitle: {
    paddingLeft: 10,
    height: 30,
    lineHeight: '30px',
    color: '#fff',
    backgroundColor: '#fd9827',
  },

  markCardContent: {
    padding: '0 10px',
  },

  markCardP: {
    marginTop: 10,
    marginBottom: 8,
    color: '#787878',
  },

  detailBox: {
    marginTop: 24,
  },

  detail: {
    flex: 1,
    flexDirection: 'row',
    padding: 12,
    width: '100%',
    fontSize: 14,
    color: '#787878',
    backgroundColor: '#f3f6fb',
  },

  detailLeft: {
    paddingRight: 24,
    width: 1100,
    borderRight: '1px solid #dfe4ec',
  },

  detailName: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 12,
    fontSize: 16,
    color: '#505050',
  },

  colorfd9827: {
    color: '#fd9827',
    fontWeight: 600,
    marginRight: 6,
    ':last-child': {
      marginLeft: 6,
    },
  },

  color86ce2f: {
    color: '#86ce2f',
  },

  detailRight: {
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    cursor: 'pointer',
  },

  detailRightName: {
    marginLeft: 22,
    marginRight: 4,
  },

  triangleBottom: {
    marginTop: 6,
    height: 0,
    width: 0,
    borderTop: '7px solid #acb4bf',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
  },

  triangleTop: {
    marginTop: 6,
    height: 0,
    width: 0,
    borderBottom: '7px solid #acb4bf',
    borderRight: '5px solid transparent',
    borderLeft: '5px solid transparent',
  },

  detailContent: {
    padding: '12px 12px 0 12px',
    marginTop: 12,
    fontSize: 14,
    border: '1px solid #d0dce7',
  },

  thBox: {
    flex: 1,
    flexDirection: 'row',
  },

  th: {
    marginLeft: 12,
    width: 432,
    height: 36,
    lineHeight: '36px',
    backgroundColor: '#f3f6fb',
    textAlign: 'center',
    ':first-child': {
      marginLeft: 0,
      width: 120,
    },
    ':last-child': {
      width: 600,
    },
  },

  tdBox: {
    flex: 1,
    flexDirection: 'row',
    borderBottom: '1px solid #d0dce7',
    ':last-child': {
      borderBottom: 'none',
    },
  },

  td: {
    padding: '20px 30px',
    width: 432,
    // height: 66,
    lineHeight: '1.5em',
    textAlign: 'center',
    ':first-child': {
      marginLeft: 0,
      width: 120,
    },
    ':last-child': {
      width: 600,
    },
  },

  markSelected: {
    opacity: '0.75',
  },

  markNum: {
    flex: 1,
    alignItems: 'flex-end',
  },

  tabs: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 36,
    borderBottom: '1px solid #d0dce7',
  },

  tab: {
    marginBottom: -1,
    padding: '0 32px',
    height: 46,
    lineHeight: '46px',
    borderBottom: '1px solid #d0dce7',
    cursor: 'pointer',
  },

  tabSelected: {
    color: '#3399ff',
    borderBottom: '1px solid #3399ff',
  },

  wordName: {
    marginTop: 24,
    marginBottom: 10,
    color: '#3399ff',
  },

  wordDetail: {
    lineHeight: '32px',
  },
});
