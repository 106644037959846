import React, { Component } from 'react';
import { View, Button, Scrollbar } from '@zhike/ti-ui';
import PropTypes from 'prop-types';
import Header from 'components/header';
import { AudioPlayer } from '@zhike/ti-component';
import styles from './styles';

function MockDirection(props) {
  // eslint-disable-next-line react/prop-types
  const { time, questionCount, Header } = props;
  return (
    <Scrollbar className={styles.container}>
      <View className={styles.container1}>
        <View className={styles.title}>IELTS LISTENING</View>
        <View className={styles.text1}>Time : Approximately {time} minutes</View>
        <View className={styles.title}>INSTRUCTIONS TO CANDIDATES</View>
        <View className={styles.text}>• Answer all the questions.</View>
        <View className={styles.text1}>• You can change your answers at
          any time during the test.
        </View>
        <View className={styles.title}>INFORMATION FOR CANDIDATES</View>
        <View className={styles.text}>• There are {questionCount} questions in this test.</View>
        <View className={styles.text}>• Each question carries one mark.</View>
        <View className={styles.text}>• There are four parts to the test.</View>
        <View className={styles.text}>• You will hear each part once.</View>
        <View className={styles.text}>• For each part of the test, there will be time for you to look through the questions and check your answers.</View>
        <View className={[styles.start, styles.mt25]}>
          <View className={styles.title}>Click &apos;Start test&apos; when you are ready</View>
          <Button
            className={styles.button}
            text="Start test"
            textClassName={styles.buttonText}
            onClick={() => Header.next()}
          />
        </View>
      </View>
    </Scrollbar>);
}

// 阅读指令
export default class ListeningDirections extends Component {
  // 参数
  static defaultProps = {
  };
  static propTypes = {
    params: PropTypes.object.isRequired,
  };
  // 模块加载完毕
  componentDidMount() {
    Header.config({
      isShowTime: false,
    });
    // 清除定时器
    Header.cleanTimer();
  }

  // 模块卸载
  componentWillUnmount() {
    AudioPlayer.unload();
  }

  // 渲染
  render() {
    const { mode } = this.props.params;
    const isMock = mode === 'mock';
    if (isMock) {
      return <MockDirection time={30} questionCount={40} Header={Header} />;
    }
    return (
      <Scrollbar className={styles.container}>
        <View className={styles.container1}>
          <View className={styles.title}>IELTS LISTENING</View>
          <View className={styles.text1}>Time : Approximately 8 minutes</View>
          <View className={styles.title}>INSTRUCTIONS TO CANDIDATES</View>
          <View className={styles.text}>• Answer all the questions.</View>
          <View className={styles.text1}>• You can change your answers at
          any time during the test.
          </View>
          <View className={styles.title}>INFORMATION FOR CANDIDATES</View>
          <View className={styles.text}>• There are 10 questions in this test.</View>
          <View className={styles.text}>• You will hear each material once only.</View>
          <View className={styles.text}>• For each part of the test there will be time for
          you to look through the questions and time for you to check your answers.
          </View>
          <View className={styles.start}>
            <View className={styles.title}>Click &apos;Start test&apos; when you are ready</View>
            <Button
              className={styles.button}
              text="Start test"
              textClassName={styles.buttonText}
              onClick={() => Header.next()}
            />
          </View>
        </View>
      </Scrollbar>
    );
  }
}
