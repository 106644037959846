import React, { Component } from 'react';
import { View, Scrollbar } from '@zhike/ti-ui';
import PropTypes from 'prop-types';
import Header from 'components/header';
import styles from './styles';

// 阅读指令
export default class GeneralDirections extends Component {
  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
  };
  // 模块加载
  componentDidMount() {
    Header.config({
      title: '',
      showButtons: ['continue'],
    });
  }

  // 渲染
  render() {
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={styles.title}>General Test Information</View>
        <View className={styles.p}>This test measures your ability to use English in an academic context. There are 4 sections.</View>
        <View className={styles.p}>In the <View className={styles.strong}>Reading</View> section, you will read several passages and answer questions about them.</View>
        <View className={styles.p}>In the <View className={styles.strong}>Listening</View> section, you will hear several conversations and lectures and answer questions about them.</View>
        <View className={styles.p}>In the <View className={styles.strong}>Speaking</View> section, you will answer {this.props.step.textbookId === 193 ? '4' : '6'} questions. Some of the questions ask you to speak about your own experience. Other questions ask you to speak about lectures and reading passages.</View>
        <View className={styles.p}>In the <View className={styles.strong}>Writing</View> section you will answer 2 questions. The first question asks you to write about the relationship between a lecture you will hear and a passage you will read. The second question asks you to write an essay about a topic of general interest based on your experience.</View>
        <View className={styles.p}>There will be directions for each section which explain how to answer the questions in that section.</View>
        <View className={styles.p}>You should work quickly and carefully on the Reading and Listening questions. Some questions are more difficult than others, but try to answer everyone to the best of your ability. If you are not sure to the answer to a question, make the best guess that you can. The questions that you answer by speaking and by writing are each separately timed. Try to answer every one of these questions as completely as possible in the time allowed.</View>
        <View className={styles.p}>Click on <View className={styles.strong}>Continue</View> to go on</View>
        {/* eslint-enable */}
      </Scrollbar>
    );
  }
}
