import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, Scrollbar } from '@zhike/ti-ui';
import { Modal } from '@zhike/ti-component';
import Header from 'components/header';
import Article from 'components/article';
import Question from 'components/question';
import styles from './styles';

// 阅读题目
export default class ReadingQuestion extends Component {
  // 参数
  static propTypes = {
    step: PropTypes.object.isRequired,
    newSetStepRecord: PropTypes.func.isRequired,
    newSetRecord: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  // 初始参数
  static defaultProps = {
  };

  // 模块加载
  componentDidMount() {
    this.setHeader(this.props);
  }

  // 模块接受参数
  componentWillReceiveProps(nextProps) {
    this.setHeader(nextProps);
  }

  // 设置头部
  setHeader() {
    const { mode } = this.props.params;
    const unavailableButtons = [];
    let showButtons = ['correct', 'submit'];
    let onClickNext = () => Header.next();
    if (mode === 'mock') {
      showButtons = ['submit'];
      onClickNext = () => {
        Modal.show('ModalAlert', {
          title: '提示',
          buttons: [
            {
              title: 'Submit',
              onClick: () => {
                Header.next();
              },
            },
            {
              title: 'Cancel',
              class: 'gray',
            },
          ],
          width: 400,
          component: (
            <View className={styles.modalCenter}>
              <View className={styles.p}>Do you determine to submit all your answers?</View>
              <View className={styles.p}>You WILL NOT be able to go back to this test once you click Submit button.</View>
            </View>
          ),
        });
      };
    }
    Header.config({
      showButtons,
      unavailableButtons,
      isShowTime: true,
      onClickNext,
    });
  }

  // 渲染
  render() {
    const { step, newSetRecord, newSetStepRecord, params } = this.props;
    const { material, questions } = step.practice || {};
    const renderProps = {
      newSetStepRecord,
      newSetRecord,
      step,
      params,
    };

    return (
      <View className={styles.container}>
        <View className={styles.articleWrapper}>
          <View className={styles.article}>
            <Scrollbar
              className={styles.articleContent}
            >
              <View className={styles.articleTitle}>{material && material.title}</View>
              {
                material && <Article material={step.practice.material.origin} />
              }
            </Scrollbar>
          </View>
        </View>
        <View className={styles.questionWrapper}>
          <View className={styles.questions}>
            <Scrollbar>
              {
                Array.isArray(questions) &&
                questions.map((item, index) => (
                  <View key={index} className={styles.question}>
                    <Question question={item} questionIndex={index} {...renderProps} />
                  </View>))
              }
            </Scrollbar>
          </View>
        </View>
      </View>
    );
  }
}
