import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
  },

  loadingBox: {
    flex: 1,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },


  loading: {
    width: 100,
    height: 100,
    margin: '0 auto',
  },

  content: {
    width: 1180,
    margin: '0 auto',
  },

  text: {
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
  },

  top: {
    marginTop: 40,
    paddingTop: 50,
    width: '100%',
    height: 234,
    backgroundImage: `url(${require('./assets/top.jpg')})`,
    backgroundSize: '100%',
  },

  subTitle: {
    marginTop: 10,
    color: '#ffffff',
    textAlign: 'center',
    lineHeight: '24px',
  },

  button: {
    margin: '0 auto',
    marginTop: 20,
    width: 120,
    height: 40,
    lineHeight: '40px',
    fontSize: 14,
    fontWeight: 600,
    borderRadius: '3px',
    backgroundColor: '#3399ff',
    color: '#fff',
    textAlign: 'center',
    cursor: 'pointer',
  },

  title: {
    marginTop: 60,
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
  },

  image: {
    width: 1180,
    marginTop: 40,
  },

  tip: {
    margin: '0 auto',
    marginTop: 10,
    width: 32,
    height: 4,
    borderRadius: '2px',
    backgroundColor: '#3399ff',
  },

  advantage: {
    paddingBottom: 60,
  },

  advantageImges: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 60,
  },

  advantageBox: {
    marginTop: 40,
    marginLeft: 70,
    width: 300,
  },

  advantageImge: {
    width: 130,
    height: 130,
    margin: '0 auto',
  },

  advantageText: {
    marginTop: 20,
    textAlign: 'center',
  },

  modalAlert: {
    alignItems: 'center',
  },

  modalAlertImage: {
    width: 90,
    height: 90,
    marginTop: 20,
  },

  modalAlertText: {
    fontSize: 14,
    marginTop: 20,
    marginBottom: 40,
    textAlign: 'center',
  },

  modalAlertTextMain: {
    display: 'block',
    marginBottom: 12,
  },

  modalAlertTextTips: {
    fontSize: 12,
    display: 'block',
    color: '#878F98',
    lineHeight: '17px',
  },

  close: {
    marginRight: 30,
    fontSize: 14,
    width: 120,
    height: 40,
    lineHeight: '40px',
    borderRadius: '3px',
    border: 'solid 1px #878f98',
    textAlign: 'center',
    cursor: 'pointer',
  },

  pay: {
    width: 120,
    height: 40,
    fontSize: 14,
    lineHeight: '40px',
    borderRadius: '3px',
    backgroundColor: '#3399ff',
    textAlign: 'center',
    color: '#fff',
    cursor: 'pointer',
  },
});
