import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Helvetica Neue,sans-serif,Arial',
  },
  answerText: {
    marginRight: '48px',
    width: '48%',
    fontSize: '16px',
    lineHeight: '48px',
  },

  answerTextContent: {
    padding: '12px',
    // color: 'rgb(14, 16, 26)',
    color: '#0e101a',
    // color: '#6b7280',
    fontWeight: 300,
    lineHeight: '36px',
  },

  num: {
    display: 'inline-block',
    padding: '0 10px',
    backgroundColor: '#6d758d',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#fff',
    verticalAlign: 'baseline',
  },

  resultItem: {
    width: '359px',
    padding: '12px 12px 12px 16px',
    marginBottom: '12px',
    borderRadius: '8px',
    // fontColor: '#6b7280',
    color: '#0e101a',
    fontSize: '14px',
    // fontWeight: 300,
    backgroundColor: '#f0f1f2',
    borderLeft: '4px solid #374151',
    cursor: 'pointer',
    // height: '42px',
    overflow: 'hidden',
  },
  resultItemSelected: {
    width: '359px',
    padding: '12px 12px 12px 16px',
    marginBottom: '12px',
    borderRadius: '8px',
    fontColor: '#6b7280',
    // fontWeight: 400,
    cursor: 'pointer',
    backgroundColor: '#edfaef',
    borderLeft: '4px solid #15c39a',
    fontSize: '14.875px',
    transition: 'fontSize 0.3s ease',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },

  resultItemAdviceSelected: {
    width: '359px',
    padding: '12px 12px 12px 16px',
    marginBottom: '12px',
    borderRadius: '8px',
    fontColor: '#6b7280',
    cursor: 'pointer',
    // fontWeight: 400,
    backgroundColor: '#fdf7ea',
    borderLeft: '4px solid #a16306',
    fontSize: '14.875px',
    transition: 'fontSize 0.3s ease',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },

  title: {
    fontWeight: 500,
    fontSize: '14.875px',
  },

  sidebar: {
    marginLeft: '30px',
  },

  sidebarContent: {
    marginTop: '24px',
  },

  progressbar: {
    margin: '8px 0 16px 0',
    width: '100%',
    height: '3px',
    backgroundColor: '#ff99ab',
  },

  activebar: {
    height: '3px',
    backgroundColor: '#ea1537',
  },

  types: {
    fontWeight: 500,
    fontSize: '14.875px',
    color: '#0e101a',
  },

});
