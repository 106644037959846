import React, { Component } from 'react';
import { connect } from 'react-redux';
import Exercise from 'components/exercise';
import { setRecord, getUploadSignature, postAnswer, postMachinePigai, getAnswer, setAudioRecord } from 'actions/record';

// 做题
class ExerciseContainer extends Component {
  render() {
    return (
      <Exercise {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  practice: state.practice,
  stepList: state.stepList,
  record: state.record || {},
});

export default connect(mapStateToProps, {
  setRecord,
  getUploadSignature,
  postMachinePigai,
  postAnswer,
  getAnswer,
  setAudioRecord,
})(ExerciseContainer);
