// 具体字段文档 http://confluence.smartstudy.com/pages/viewpage.action?pageId=94044633
const defaultProperties = {
  isLogin: true,
  practiceType: 'practice',
  examId: 2,
  examName: 'IELTS',
  subjectId: '',
  subjectIds: '',
  practiceId: '',
  practiceName: '',
  fromPlatform: '',
  exerciseId: '',
  lastUr: '',
  pid: '',
  learnType: '',
  buttonName: '',
  textbookId: '',
  textbookName: '',
  questionTagId: '',
  questionTagName: '',
  practiceTypeId: '',
  practiceTypeName: '',
  practicePackageId: '',
  practicePackageName: '',
  practiceWay: '',
  questionTypeName: '',
  topicId: '',
  topicName: '',
  questionId: '',
  questionName: '',
  questionTypeId: '',
};
export {
  defaultProperties,
};
