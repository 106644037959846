import React, { Component } from 'react';
import { View, Scrollbar } from '@zhike/ti-ui';
import { formatDuration } from 'utils';
import Header from 'components/header';
import styles from './styles';

// 阅读指令
export default class Rest extends Component {
  // 构造函数
  constructor(props) {
    super(props);
    this.state = {
      time: 10 * 60 * 1000,
      percent: 0,
    };
    this.timeInterval = null;
  }

  // 模块加载
  componentDidMount() {
    Header.config({
      title: 'Break',
      showButtons: ['continue'],
    });
    Header.cleanTimer();
    this.timeInterval = setInterval(() => {
      const { time } = this.state;

      if (time === 0) {
        clearInterval(this.timeInterval);
        Header.next();
        return;
      }

      this.setState({
        time: time - 10,
        percent: (time - 10) / (10 * 60 * 1000),
      });
    }, 10);
  }

  // 模块卸载
  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
      this.timeInterval = null;
    }
  }

  // 渲染
  render() {
    const { time, percent } = this.state;
    return (
      <Scrollbar className={styles.container}>
        {/* eslint-disable */}
        <View className={styles.p}>You may now take a break. In an actual test there is a ten-minute break at this point.</View>
        <View className={styles.p}>Click on <View className={styles.strong}>Continue</View> when you are ready to go on to the next section.</View>
        <View className={styles.p}>If you do not wish to take a break, click on <View className={styles.strong}>Continue</View> now.</View>
        <View className={styles.time}>
          <View className={styles.timeText}>
            BREAK TIME
          </View>
          <View className={styles.progress}>
            <View className={styles.progressText}>
              {formatDuration(time, true)}
            </View>
            <View
              className={styles.progressBar}
              style={{ width: percent * 400 }}
            />
          </View>
        </View>
        {/* eslint-enable */}
      </Scrollbar>
    );
  }
}
